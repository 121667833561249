import React, { useEffect } from 'react';
import CommonSection from '../Components/Common/CommonSection';
import { useTranslation } from 'react-i18next';
import AllQuestions from '../Components/FAQ/AllQuestions';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const FAQPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="FAQs"
        description="Find answers to your questions and get detailed information about our services, features, and support to help you make the most of our solutions."
      />
      <div className="max-w-[1920px] m-auto bg-PrimaryT">
        <CommonSection>
          <h1 className="text-center headingText text-white">
            {t('FAQTitle')}
          </h1>
        </CommonSection>

        <AllQuestions />
      </div>
    </>
  );
};

export default FAQPage;
