import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../utils/Hooks/useWindowSize';
import CommonSection from '../Components/Common/CommonSection';
import ToggleMethod from '../Components/Common/ToggleMethod';
import HeadingComp from '../Components/Common/HeadingComp';
import ConnectedApps from '../Components/Integrations/ConnectedApps';
import PaymentMethod from '../Components/Integrations/PaymentMethod';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const IntegrationAndPaymentPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isTabletView = windowWidth <= 720;
  const isMobileView = windowWidth <= 500;

  const [produtsType, setProductType] = useState([]);
  const [selectedValue, setSelectedValue] = useState(produtsType[0]);

  useEffect(() => {
    setProductType(getTranslatedList(t));
  }, [t]);
  // useEffect(() => {
  //   if (produtsType) {
  //     setSelectedValue(produtsType[0]);
  //   }
  // }, [produtsType]);

  useEffect(() => {
    if (produtsType) {
      const selected = localStorage.getItem('appsCount');
      if (selected == 1) {
        setSelectedValue(produtsType[1]);
      } else {
        setSelectedValue(produtsType[0]);
      }
    }
  }, [produtsType]);

  return (
    <>
      <TitleAndDesc
        title="Integrations & Payment Gateways | Avnovo"
        description="Discover a wide range of third-party services and payment gateways that seamlessly integrate with our platform, ensuring smooth transactions and expanded functionality."
      />
      <CommonSection>
        <ToggleMethod
          selected={selectedValue}
          setSelected={setSelectedValue}
          options={produtsType}
          width={isMobileView ? '150px' : isTabletView ? '220px' : '260px'}
          // handleClickScroll={handleClickScroll}
        />
        <HeadingComp
          title={
            selectedValue?.name === t('SoftwareIntegrations')
              ? t('IntegrationsApps')
              : t('PaymentGateways')
          }
          text={
            selectedValue?.name === t('SoftwareIntegrations')
              ? t('IntegrationsAppsText')
              : t('PaymentGatewaysText')
          }
          isFullWidth
        />

        {selectedValue && (
          <>
            {selectedValue?.name === t('SoftwareIntegrations') ? (
              <>
                <ConnectedApps />
              </>
            ) : (
              <>
                <PaymentMethod />
              </>
            )}
          </>
        )}
      </CommonSection>
    </>
  );
};

const getTranslatedList = (t) => {
  const lists = [
    {
      id: 'SoftwareIntegrations',
      name: t('SoftwareIntegrations'),
    },
    {
      id: 'PaymentGateways',
      name: t('PaymentGateways'),
    },
  ];

  return lists;
};

export default IntegrationAndPaymentPage;
