import React from 'react';
import { Helmet } from 'react-helmet';

const TitleDescWithKeyword = ({
  title,
  description,
  keywords,
  isImageURL,
  isPageURL,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {isImageURL && <meta property="og:image" content={isImageURL} />}

      {isPageURL && <meta property="og:url" content={isPageURL}></meta>}
    </Helmet>
  );
};

export default TitleDescWithKeyword;
