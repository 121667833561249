import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseCircle, TrueCircle } from '../../assets/Icons/securityIcons';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';



const HandleCustomerData = () => {
  const { t } = useTranslation();
    const { width: windowWidth } = useWindowSize();
    const isMobileView = windowWidth <= 500;
  const [dataProtect, setDataProtect] = useState([]);
  const [dataNotProtect, setDataNotProtect] = useState([]);
  useEffect(() => {
    setDataProtect(getTranslatedList(t, 'protect'));
    setDataNotProtect(getTranslatedList(t, 'not_protect'));
  }, [t]);
  return (
    <div className="clip-path-class bg-white py-[200px] max_xl:py-[140px] max_sm:py-[100px] mt-[-110px] max_md:mt-[-50px]">
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5">
        <div className="flex flex-col gap-4">
          <h2 className="Text50">{t('HandleCustomerdata')}</h2>
          <p className="paragraphText text-TertiaryT">{t('CusDataText1')}</p>
          <p className="paragraphText text-TertiaryT">{t('CusDataText1')}</p>
        </div>
        <div className="mt-[60px] max_sm:mt-10 flex justify-between max_md:flex-col max_md:gap-10 max_sm:gap-5">
          <div className="bg-WebRootColor rounded-[20px] p-[30px] max_sm:p-5 max-w-[540px] max_xl:max-w-[460px] max_md:max-w-full">
            <h3 className="paragraphText font-medium text-PrimaryT">
              {t('DataProtect')}
            </h3>
            <div className="mt-5 flex flex-col gap-4">
              {dataProtect.map((point, index1) => (
                <div className="flex gap-2" key={index1}>
                  <div className="mt-[2px]">
                    <TrueCircle style={{
                            width: isMobileView ? '20' : '24',
                            height: isMobileView ? '20' : '24',
                          }} />
                  </div>
                  <p className="Text20Normal text-TertiaryT">
                    {point}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-WebRootColor rounded-[20px] p-[30px] max_sm:p-5 max-w-[540px] max_xl:max-w-[460px] max_md:max-w-full">
            <h3 className="paragraphText font-medium text-PrimaryT">
              {t('DataNotProtect')}
            </h3>
            <div className="mt-5 flex flex-col gap-4">
              {dataNotProtect.map((point, index2) => (
                <div className="flex gap-2" key={index2}>
                  <div className="mt-[2px]">
                    <CloseCircle style={{
                            width: isMobileView ? '20' : '24',
                            height: isMobileView ? '20' : '24',
                          }} />
                  </div>
                  <p className="Text20Normal text-TertiaryT">
                    {point}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    protect: [
      t('DataProtect1'),
      t('DataProtect2'),
      t('DataProtect3'),
      t('DataProtect4'),
    ],
    not_protect: [
      t('DataNotProtect1'),
      t('DataNotProtect2'),
      t('DataNotProtect3'),
    ],
  };

  return lists[listKey];
};

export default HandleCustomerData;
