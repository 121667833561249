import React, { useEffect, useRef, useState } from 'react';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import QuestionAnswer from './QuestionAnswer';
import { BottomArrow, RightArrowIcon } from '../../assets/Icons/icon';
import { SelectBox } from '../Common/InputFields';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const AllQuestions = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [activeSection, setActiveSection] = useState('');
  const sectionRefs = useRef({
    general: React.createRef(),
    started: React.createRef(),
    payments: React.createRef(),
    communication: React.createRef(),
    prices: React.createRef(),
    support: React.createRef(),
    security: React.createRef(),
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            const foundObject = quesAnsType.find(
              (item) => item.id === entry.target.id
            );
            setSelectedValue(foundObject);
          }
        });
      },
      { threshold: 0.3 }
    ); // Adjust threshold to when you consider the section "active"

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs.current).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  const [quesAnsType] = useState([
    {
      id: 'general',
      name: t('General'),
    },
    {
      id: 'started',
      name: t('GettingStarted'),
    },
    {
      id: 'payments',
      name: t('Payments'),
    },
    {
      id: 'communication',
      name: t('Communication'),
    },
    {
      id: 'prices',
      name: t('PricesBilling'),
    },
    {
      id: 'support',
      name: t('Support'),
    },
    {
      id: 'security',
      name: t('SecurityCompliance'),
    },
  ]);
  const [selectedValue, setSelectedValue] = useState(quesAnsType[0]);

  const [AllQuestionsList] = useState([
    {
      name: t('General'),
      id: 'general',
      questionList: [
        {
          que: t('GeneralQue1'),
          ans: t('GeneralAns1'),
        },
        {
          que: t('GeneralQue2'),
          ans: t('GeneralAns3'),
        },
        {
          que: t('GeneralQue3'),
          ans: t('GeneralAns3'),
        },
        {
          que: t('GeneralQue4'),
          ans: t('GeneralAns4'),
        },
        {
          que: t('GeneralQue5'),
          ans: t('GeneralAns5'),
        },
        {
          que: t('GeneralQue6'),
          ans: t('GeneralAns6'),
        },
        {
          que: t('GeneralQue7'),
          ans: t('GeneralAns7'),
        },
        {
          que: t('GeneralQue8'),
          ans: t('GeneralAns8'),
        },
      ],
    },
    {
      name: t('GettingStarted'),
      id: 'started',
      questionList: [
        {
          que: t('StartQue1'),
          ans: t('StartAns1'),
        },
        {
          que: t('StartQue2'),
          ans: t('StartAns2'),
        },
        {
          que: t('StartQue3'),
          ans: t('StartAns3'),
        },
        {
          que: t('StartQue4'),
          ans: t('StartAns4'),
        },
      ],
    },
    {
      name: t('Payments'),
      id: 'payments',
      questionList: [
        {
          que: t('PayQue1'),
          ans: t('PayAns1'),
        },
        {
          que: t('PayQue2'),
          ans: t('PayAns2'),
        },
        {
          que: t('PayQue3'),
          ans: t('PayAns3'),
        },
        {
          que: t('PayQue4'),
          ans: t('PayAns4'),
        },
        {
          que: t('PayQue5'),
          ans: t('PayAns5'),
        },
        {
          que: t('PayQue6'),
          ans: t('PayAns6'),
        },
        {
          que: t('PayQue7'),
          ans: t('PayAns7'),
        },
        {
          que: t('PayQue8'),
          ans: t('PayAns8'),
        },
        {
          que: t('PayQue9'),
          ans: t('PayAns9'),
        },
        {
          que: t('PayQue10'),
          ans: t('PayAns10'),
        },
        {
          que: t('PayQue11'),
          ans: t('PayAns11'),
        },
      ],
    },
    {
      name: t('Communication'),
      id: 'communication',
      questionList: [
        {
          que: t('ComQues1'),
          ans: t('ComAns1'),
        },
        {
          que: t('ComQues2'),
          ans: t('ComAns2'),
        },
        {
          que: t('ComQues3'),
          ans: t('ComAns3'),
        },
        {
          que: t('ComQues4'),
          ans: t('ComAns4'),
        },
        {
          que: t('ComQues5'),
          ans: t('ComAns5'),
        },
      ],
    },
    {
      id: 'prices',
      name: t('PricesBilling'),
      isSubTitle: true,
      questionList: [
        {
          title: t('Payments'),
          list: [
            {
              que: t('PriceQue1'),
              ans: t('PriceAns1'),
            },
            {
              que: t('PriceQue2'),
              ans: t('PriceAns2'),
            },
            {
              que: t('PriceQue3'),
              ans: t('PriceAns3'),
            },
            {
              que: t('PriceQue4'),
              ans: t('PriceAns4'),
            },
            {
              que: t('PriceQue5'),
              ans: t('PriceAns5'),
            },
          ],
        },
        {
          title: t('Communication'),
          list: [
            {
              que: t('PriceQue6'),
              ans: t('PriceAns6'),
            },
            {
              que: t('PriceQue7'),
              ans: t('PriceAns7'),
            },
            {
              que: t('PriceQue8'),
              ans: t('PriceAns8'),
            },
            {
              que: t('PriceQue9'),
              ans: t('PriceAns9'),
            },
          ],
        },
      ],
    },
    {
      id: 'support',
      name: t('Support'),
      questionList: [
        {
          que: t('SupportQue1'),
          ans: t('SupportAns1'),
        },
        {
          que: t('SupportQue2'),
          ans: t('SupportAns2'),
        },
      ],
    },
    {
      id: 'security',
      name: t('SecurityCompliance'),
      questionList: [
        {
          que: t('SecurityQue1'),
          ans: t('SecurityAns1'),
        },
        {
          que: t('SecurityQue2'),
          ans: t('SecurityAns2'),
        },
        {
          que: t('SecurityQue3'),
          ans: t('SecurityAns3'),
        },
        {
          que: t('SecurityQue4'),
          ans: t('SecurityAns4'),
        },
        {
          que: t('SecurityQue5'),
          ans: t('SecurityAns5'),
        },
        {
          que: t('SecurityQue6'),
          ans: t('SecurityAns6'),
        },
        {
          que: t('SecurityQue7'),
          ans: t('SecurityAns7'),
        },
        {
          que: t('SecurityQue8'),
          ans: t('SecurityAns8'),
        },
        {
          que: t('SecurityQue9'),
          ans: t('SecurityAns9'),
        },
        {
          que: t('SecurityQue10'),
          ans: t('SecurityAns10'),
        },
        {
          que: t('SecurityQue11'),
          ans: t('SecurityAns11'),
        },
        {
          que: t('SecurityQue12'),
          ans: t('SecurityAns12'),
        },
        {
          que: t('SecurityQue13'),
          ans: t('SecurityAns13'),
        },
      ],
    },
  ]);

  const handleClickScroll = (id) => {
    if (id === 'topId') {
      window.scrollTo(0,0)
    } else {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }

    const foundObject = quesAnsType.find((item) => item.id === id);
    setSelectedValue(foundObject);
  };
  const handleOnChange = (id) => {
    const foundObject = quesAnsType.find((item) => item.name === id);

    if (foundObject) {
      const element = document.getElementById(foundObject.id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
    setSelectedValue(foundObject);
  };
  return (
    <>
      <div
        className="relative bg-WebRootColor max-w-[1920px] m-auto"
        id="topId"
      >
        <div className="max-w-[1920px] bg-white max_800:bg-WebRootColor">
          <CommonSizeContainer>
            <div className="flex justify-between max_800:hidden">
              {quesAnsType.map((queType, index) => (
                <p
                  className={`smallText16Normal uppercase py-5 cursor-pointer max_md:text-[12px] ${
                    queType.name === selectedValue.name
                      ? 'text-PrimaryT font-medium border-b-[3px] border-PrimaryT'
                      : ''
                  }`}
                  key={index}
                  onClick={() => handleClickScroll(queType.id)}
                >
                  {queType.name}
                </p>
              ))}
            </div>
            <div className="hidden max_800:block pt-[60px]">
              <SelectBox
                placeholder="Select question type"
                data={quesAnsType}
                name="inquiry"
                isUpperCase
                handleOnChange={handleOnChange}
                value={selectedValue.name}
                // error={errors.inquiry}
              />
            </div>
          </CommonSizeContainer>
        </div>
        <CommonSizeContainer isBottomMargin>
          <div className="pt-[100px] max_sm:pt-[60px] max-w-[850px] max_xl:max-w-[730px] max_md:max-w-[100%] m-auto">
            {/* GENERAL QUESTIONS */}
            {AllQuestionsList.map((questions, index1) => (
              <div
                id={questions.id}
                ref={sectionRefs.current.general}
                key={index1}
                className={`${
                  index1 === 0
                    ? ''
                    : 'mt-[150px] max_md:mt-[100px] max_sm:mt-[60px]'
                } `}
              >
                <p className="Text20Normal font-semibold tracking-[2px] pl-5 border-l-[3px] border-ButtonP text-PrimaryT py-[2px] uppercase">
                  {questions.name}
                </p>
                {questions.isSubTitle ? (
                  <>
                    {questions.questionList?.map((data1, index2) => (
                      <React.Fragment key={index2}>
                        <div className="mt-[60px] max_sm:mt-[30px] flex gap-2 items-center">
                          <div>
                            <RightArrowIcon color="#484540" />
                          </div>
                          <p className="Text20Normal uppercase font-semibold text-PrimaryT">
                            {data1.title}
                          </p>
                        </div>
                        <div>
                          {data1.list.map((questions, index3) => (
                            <React.Fragment key={index3}>
                              <QuestionAnswer
                                data={questions}
                                isLast={
                                  data1.list.length - 1 === index3
                                    ? true
                                    : false
                                }
                              />
                            </React.Fragment>
                          ))}
                        </div>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {questions?.questionList?.map((data, index2) => (
                      <React.Fragment key={index2}>
                        <QuestionAnswer
                          data={data}
                          isLast={
                            questions?.questionList.length - 1 === index2
                              ? true
                              : false
                          }
                        />
                      </React.Fragment>
                    ))}
                  </>
                )}
              </div>
            ))}

            {/* GEETING STARTED QUESTIONS */}
            {/* <div
              id="started"
              ref={sectionRefs.current.started}
              className="mt-[150px] max_md:mt-[100px] max_sm:mt-[60px]"
            >
              <p className="Text20Normal font-semibold pl-5 border-l-[3px] border-ButtonP text-PrimaryT py-[2px]">
                {t('GettingStarted')}
              </p>

              {getStartedQues.map((data, index) => (
                <React.Fragment key={index}>
                  <QuestionAnswer
                    data={data}
                    isLast={getStartedQues.length - 1 === index ? true : false}
                  />
                </React.Fragment>
              ))}
            </div> */}
          </div>
        </CommonSizeContainer>

        <div
          className="absolute right-[50px] max_sm:right-5 bottom-[-60px] cursor-pointer"
          onClick={() => handleClickScroll('topId')}
        >
          <BottomArrow
            style={{
              width: isMobileView ? 50 : 68,
              height: isMobileView ? 50 : 68,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AllQuestions;
