import React from 'react';
import { useTranslation } from 'react-i18next';

const SubProcessorComp = ({ title, count, data, id }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">
        {count}. <span className="pl-1">{title}</span>
      </p>
      <div className="flex flex-col gap-4 mt-4">
        <p className="smallText16Normal">{t('ProcessorT')}</p>

        {data[0]?.list.map((listd, index) => (
          <div className="flex gap-2 pl-4" key={index}>
            <p className="smallText16Normal">{index + 1}.</p>
            <p className="smallText16Normal cursor-pointer">{listd}</p>
          </div>
        ))}
        {data[0]?.text.map((listd, index1) => (
          <p className="smallText16Normal cursor-pointer" key={index1}>
            {listd}
          </p>
        ))}
      </div>
    </div>
  );
};

export default SubProcessorComp;
