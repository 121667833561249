import React, { useState } from 'react';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../utils/Hooks/useWindowSize';
import { CheckIcon } from '../../../assets/Icons/icon';

const RevenueOptimize = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth <= 980;
  const isMobileView = windowWidth <= 500;
  const AllPlansData = [
    {
      column: [
        'Card validation',
        'Dynamic acceptance',
        'Automatic card updater',
        'Payment reminders',
      ],
    },
    {
      column: [
        'Fraud prevention',
        'Liability shift with 3DS',
        'Digital wallets',
        'Buy now, pay later',
      ],
    },
    {
      column: [
        'Wire & crypto payments',
        'Customer portal',
        'Signature capture',
        'Payment retries',
      ],
    },
  ];
  const AllPlansDataTab = [
    {
      column: [
        'Card validation',
        'Dynamic acceptance',
        'Automatic card updater',
        'Payment reminders',
        'Fraud prevention',
        'Liability shift with 3DS',
      ],
    },
    {
      column: [
        'Digital wallets',
        'Buy now, pay later',
        'Wire & crypto payments',
        'Customer portal',
        'Signature capture',
        'Payment retries',
      ],
    },
  ];
  const [allPlanDetails] = useState(AllPlansData);
  const [allPlanDetailsTab] = useState(AllPlansDataTab);
  return (
    <CommonSizeContainer isTopMargin isBottomMargin>
      <h2 className="text-center Text50" id="RevenueOpt">
        {t('RevenueOpt')}
      </h2>
      <p className="text-center Text24 mt-6">{t('RevenueOptT')}</p>
      <div className="w-full mt-[60px] max_sm:mt-10 flex justify-between max_720:flex-col max_600:items-center max_720:gap-[14px] max_sm:gap-3 max_md:justify-center max_720:items-center">
        {(isTabView ? allPlanDetailsTab : allPlanDetails)?.map(
          (plans, index) => {
            return (
              <div
                className="flex flex-col gap-[30px] p-5 max_xl:p-2 max_xl:gap-5 max_720:gap-7"
                key={index}
              >
                {plans.column.map((items, index1) => {
                  return (
                    <div className="flex items-center gap-3" key={index1}>
                      <div>
                        <CheckIcon
                          style={{
                            width: isMobileView ? 20 : 24,
                            height: isMobileView ? 20 : 24,
                          }}
                        />
                      </div>
                      <p
                        key={index1}
                        className="Text20Normal font-medium text-PrimaryT"
                      >
                        {items}
                      </p>
                    </div>
                  );
                })}
              </div>
            );
          }
        )}
      </div>
    </CommonSizeContainer>
  );
};

export default RevenueOptimize;
