import React, { useEffect } from 'react';
import BannerWithTitleDesc from '../Components/Common/BannerWithTitleDesc';
import { useTranslation } from 'react-i18next';
import AboutAnimation from '../Components/AboutUs/AboutAnimation';
import EmpoweringBusComp from '../Components/AboutUs/EmpoweringBusComp';
import MissionAndVission from '../Components/AboutUs/MissionAndVission';
import ContinueWithAV from '../Components/Common/ContinueWithAv';
import { useNavigate } from 'react-router-dom';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const AboutUsPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const navigate = useNavigate()
  return (
    <>
      <TitleAndDesc
        title="About Avnovo"
        description="Discover our mission, values, and the innovative solutions we provide that empower businesses to thrive."
        isPageURL="https://avnovo.com/about-us"
      />
      <BannerWithTitleDesc
        title={t('AvnovoJourney')}
        text={t('AvnovoJourneyText')}
      />
      <AboutAnimation />
      <EmpoweringBusComp />
      <MissionAndVission />
      <ContinueWithAV
        title={t('LetsGo')}
        subTitle1={t('LetsGoText')}
        isTopMargin
        buttonText={t('GetADemo')}
      />
    </>
  );
};

export default AboutUsPage;
