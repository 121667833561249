import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonAsDC from '../../Common/CommonAsDC';
import { useWindowSize } from '../../../utils/Hooks/useWindowSize';
import { CloseCircle, TrueCircle } from '../../../assets/Icons/securityIcons';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import CardContainer from '../../Common/CardContainer';
import ChatbotImg from '../../../assets/images/Products/AI/AIChart2.svg';
import {
  Availability,
  DataInsightS,
  IncreasedRevenue,
  InstantResponses,
  Scalability,
  SentimentAnalysis,
} from '../../../assets/Icons/productsIcons';

const AITransformAndChatbot = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [withoutAI, setWithoutAI] = useState([]);
  const [withAI, setWithAI] = useState([]);
  const [services, setServices] = useState([]);
  const [predictiveData, setPredictiveData] = useState([]);
  useEffect(() => {
    setWithoutAI(getTranslatedList(t, 'Without_AI'));
    setWithAI(getTranslatedList(t, 'With_AI'));
    setServices(getTranslatedList(t, 'services'));
    setPredictiveData(getTranslatedList(t, 'predictive'));
  }, [t]);
  return (
    <>
      <div className="">
        <CommonAsDC>
          <div
            className="flex flex-col items-center py-[150px] max_md:py-[100px] max_sm:py-[60px]"
            id="WorkAutomationId"
          >
            <p className="Text20Normal font-[450] text-DisableText">
              {t('WorkAuto')}
            </p>
            <h2 className="mt-4 Text50 text-center">{t('WorkAutoTitle')}</h2>

            <div className="mt-[60px] max_sm:mt-10 flex justify-between gap-[60px] max_md:flex-col max_md:gap-10 max_sm:gap-5">
              <div className="bg-white rounded-[20px] p-[30px] max_sm:p-5 max-w-[540px] max_md:max-w-[420px]">
                <p className="paragraphText font-semibold text-PrimaryT">
                  {t('WithoutAI')}
                </p>
                <div className="mt-5 flex flex-col gap-4">
                  {withoutAI.map((point, index1) => (
                    <div className="flex gap-2" key={index1}>
                      <div className="mt-[2px]">
                        <CloseCircle />
                      </div>
                      <p className="Text20Normal font-normal">{point}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white rounded-[20px] p-[30px] max_sm:p-5 max-w-[540px] max_md:max-w-[420px]">
                <p className="paragraphText font-semibold text-PrimaryT">
                  {t('WithAI')}
                </p>
                <div className="mt-5 flex flex-col gap-4">
                  {withAI.map((point, index2) => (
                    <div className="flex gap-2" key={index2}>
                      <div className="mt-[2px]">
                        <TrueCircle />
                      </div>
                      <p className="Text20Normal font-normal">{point}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </CommonAsDC>
      </div>
      <div className="max-w-[1920px] m-auto bg-white py-[100px] max_md:py-[60px] max_sm:py-10">
        <CommonSizeContainer>
          <div id="ChatbotId">
            <h2 className="Text50">{t('Chatbots')}</h2>
            <p className="paragraphText font-normal mt-4">{t('ChatbotsT')}</p>
            <div className="mt-10 flex justify-between items-center max_xl:gap-[30px] max_md:flex-col max_md:gap-[60px] max_sm:gap-10 max_md:items-start max_md:mt-5">
              <div className="relative max_xl:max-w-[420px] max_md:m-auto mt-5">
                <img src={ChatbotImg} alt="chatbot" />
                <p className="absolute top-[60px] max_xl:top-[50px] text-end left-24 font-OutfitFont font-normal text-textNormal max_sm:text-[12px] max_sm:top-6">
                  Up to 40% <br />
                  productivity boost with AI
                </p>
              </div>
              <div className="flex flex-col gap-10 max-w-[500px] max_md:max-w-full">
                {services.map((details, index) => (
                  <div className="flex gap-3" key={index}>
                    <div>
                      {React.cloneElement(details.icon, {
                        style: {
                          width: isMobileView ? 32 : 40,
                          height: isMobileView ? 32 : 40,
                        },
                      })}
                    </div>
                    <div>
                      <p className="paragraphText font-semibold text-PrimaryT max_sm:text-textMedium">
                        {details.name}
                      </p>
                      <p className="smallText18L text-TertiaryT font-normal mt-3 leading-[23px] max_sm:text-textSmall">
                        {details.text}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CommonSizeContainer>

        <CommonSizeContainer isTopMargin>
          <h2 className="Text50 text-center" id="PredictiveEngId">
            {t('PredictiveEng')}
          </h2>
          <p className="paragraphText text-TertiaryT mt-4 text-center">
            {t('PredictiveEngT')}
          </p>
          <div className="max_md:hidden max_720:block">
            <div className="grid grid-cols-3 gap-6 max_xl:gap-4 mt-[60px] max_600:mt-10 max_720:grid-cols-1">
              {predictiveData?.map((service, index1) => (
                <CardContainer key={index1} service={service} NoMinHeight />
              ))}
            </div>
          </div>
          <div className="hidden max_md:block max_720:hidden mt-[60px]">
            <div className="flex justify-center">
              <CardContainer service={predictiveData[0]} isFixWidth />
            </div>
            <div className="flex justify-center gap-[30px] mt-[30px]">
              <CardContainer service={predictiveData[1]} isFixWidth />
              <CardContainer service={predictiveData[2]} isFixWidth />
            </div>
          </div>
        </CommonSizeContainer>
      </div>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    Without_AI: [t('WithoutAI1'), t('WithoutAI2'), t('WithoutAI3')],
    With_AI: [t('WithAI1'), t('WithAI2'), t('WithAI3')],
    services: [
      {
        name: t('Availability24'),
        text: t('Availability24T'),
        icon: <Availability />,
      },
      {
        name: t('InstantResponses'),
        text: t('InstantResponsesT'),
        icon: <InstantResponses />,
      },
      {
        name: t('CustScalability'),
        text: t('ScalabilityT'),
        icon: <Scalability />,
      },
    ],
    predictive: [
      {
        title: t('IncreasedRevenue'),
        text: t('IncreasedRevT'),
        icon: <IncreasedRevenue />,
      },
      {
        title: t('SentimentAnalysis'),
        text: t('SentimentAnalysisT'),
        icon: <SentimentAnalysis />,
      },
      {
        title: t('DataInsightS'),
        text: t('DataInsightT'),
        icon: <DataInsightS />,
      },
    ],
  };

  return lists[listKey];
};

export default AITransformAndChatbot;
