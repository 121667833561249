import React from 'react';

const PasswordValidator = ({ require }) => {
  return (
    <div className="bg-BgSecondaryLight p-3 rounded-[7px]">
      <div className="mb-1 text-textSmall font-OutfitFont text-PrimaryT font-medium">
        Password must contain at least:
      </div>
      <div className="text-TertiaryT">
        <div className="flex items-center gap-2 px-1">
          {require.minLength ? <TickIcon /> : <CloseIcon />}
          <p className="text-textExtraSmall font-OutfitFont font-normal leading-6">
            Minimum 8 characters.
          </p>
        </div>
        <div className="flex items-center gap-2 px-1">
          {require.uppercase ? <TickIcon /> : <CloseIcon />}
          <p className="text-textExtraSmall font-OutfitFont font-normal leading-6">
            At least one uppercase letter (A-Z).
          </p>
        </div>
        <div className="flex items-center gap-2 px-1">
          {require.lowercase ? <TickIcon /> : <CloseIcon />}
          <p className="text-textExtraSmall font-OutfitFont font-normal leading-6">
            At least one lowercase letter (a-z).
          </p>
        </div>
        <div className="flex items-center gap-2 px-1">
          {require.number ? <TickIcon /> : <CloseIcon />}
          <p className="text-textExtraSmall font-OutfitFont font-normal leading-6">
            At least one numerical digit (0-9).
          </p>
        </div>
        <div className="flex items-center gap-2 px-1">
          {require.specialChar ? <TickIcon /> : <CloseIcon />}
          <p className="text-textExtraSmall font-OutfitFont font-normal lead leading-6">
            At least one special character (e.g., !, @, #, $, etc.)
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordValidator;

const CloseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00001 15.1663C4.04668 15.1663 0.833344 11.953 0.833344 7.99967C0.833344 4.04634 4.04668 0.833008 8.00001 0.833008C11.9533 0.833008 15.1667 4.04634 15.1667 7.99967C15.1667 11.953 11.9533 15.1663 8.00001 15.1663ZM8.00001 1.83301C4.60001 1.83301 1.83334 4.59967 1.83334 7.99967C1.83334 11.3997 4.60001 14.1663 8.00001 14.1663C11.4 14.1663 14.1667 11.3997 14.1667 7.99967C14.1667 4.59967 11.4 1.83301 8.00001 1.83301Z"
      fill="#484540"
    />
    <path
      d="M6.11332 10.3869C5.98666 10.3869 5.85999 10.3402 5.75999 10.2402C5.56666 10.0469 5.56666 9.7269 5.75999 9.53357L9.53332 5.76023C9.72666 5.5669 10.0467 5.5669 10.24 5.76023C10.4333 5.95357 10.4333 6.27357 10.24 6.4669L6.46666 10.2402C6.37332 10.3402 6.23999 10.3869 6.11332 10.3869Z"
      fill="#484540"
    />
    <path
      d="M9.88666 10.3869C9.75999 10.3869 9.63332 10.3402 9.53332 10.2402L5.75999 6.4669C5.56666 6.27357 5.56666 5.95357 5.75999 5.76023C5.95332 5.5669 6.27332 5.5669 6.46666 5.76023L10.24 9.53357C10.4333 9.7269 10.4333 10.0469 10.24 10.2402C10.14 10.3402 10.0133 10.3869 9.88666 10.3869Z"
      fill="#484540"
    />
  </svg>
);

const TickIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99992 0.333008C3.32659 0.333008 0.333252 3.32634 0.333252 6.99967C0.333252 10.673 3.32659 13.6663 6.99992 13.6663C10.6733 13.6663 13.6666 10.673 13.6666 6.99967C13.6666 3.32634 10.6733 0.333008 6.99992 0.333008ZM10.1866 5.46634L6.40659 9.24634C6.31325 9.33967 6.18659 9.39301 6.05325 9.39301C5.91992 9.39301 5.79325 9.33967 5.69992 9.24634L3.81325 7.35967C3.61992 7.16634 3.61992 6.84634 3.81325 6.65301C4.00659 6.45967 4.32659 6.45967 4.51992 6.65301L6.05325 8.18634L9.47992 4.75968C9.67325 4.56634 9.99325 4.56634 10.1866 4.75968C10.3799 4.95301 10.3799 5.26634 10.1866 5.46634Z"
      fill="#23B339"
    />
  </svg>
);
