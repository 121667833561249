import React from 'react';
// import InvoiceImg from '../../../assets/images/Products/Payments/Invoice.svg';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../utils/Hooks/useWindowSize';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import { TickCircle } from '../../../assets/Icons/securityIcons';

const InvoiceAndQuotes = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <CommonSizeContainer isTopPadding>
      <div
        className="flex justify-between max_md:flex-col-reverse gap-5 max_sm_380:pt-6 max_350:pt-12"
        id="QuotesInvoices"
      >
        <div className="max_md:hidden">
          <img
            src="https://images.dcdial.com/avnovo.com/Invoice.svg"
            alt="Invoice"
          />
        </div>
        <div className="max-w-[500px] max_md:max-w-full">
          {/* <p className="Text20Normal font-medium text-DisableText">
            {t('ComingSoon')}
          </p> */}
          <h2 className="Text50 mt-6 max_md:mt-[30px] max_sm:mt-4">
            {t('InvoicesQuo')}
          </h2>
          <p className="Text24 mt-6 max_md:mt-4 max_600:mt-6">
            {t('InvoiceText')}
          </p>
          <div className="hidden max_md:block mt-[60px] max_600:mt-10">
            <img
              src="https://images.dcdial.com/avnovo.com/Invoice.svg"
              alt="Invoice"
            />
          </div>
          <div className="flex flex-col max_md:flex-row max_600:flex-col max_md:mt-[30px] max_600:mt-10 max_md:gap-5 max_600:gap-4">
            <div className="mt-[60px] max_md:mt-0 flex gap-2">
              <div>
                <TickCircle
                  width={isMobileView ? '20' : '26'}
                  height={isMobileView ? '20' : '26'}
                />
              </div>
              <p className="smallText18L max_sm:text-textSmall font-normal text-TertiaryT">
                {t('InvoiceText1')}
              </p>
            </div>
            <div className="mt-4 max_md:mt-0 flex gap-2">
              <div>
                <TickCircle
                  width={isMobileView ? '20' : '26'}
                  height={isMobileView ? '20' : '26'}
                />
              </div>
              <p className="smallText18L max_sm:text-textSmall font-normal text-TertiaryT">
                {t('InvoiceText2')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </CommonSizeContainer>
  );
};

export default InvoiceAndQuotes;
