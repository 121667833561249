import React from 'react';

const NumberWithHeadingComp = ({ data, handleOnClick }) => {
  return (
    <div className="mt-5 flex flex-col">
      <p className="smallText18L text-SecondaryT font-semibold leading-5">
        {data.name}
      </p>
      <div className="mt-4 flex flex-col gap-4">
        {data.text.map((list, index4) => (
          <p
            className="smallText16Normal"
            key={index4}
            dangerouslySetInnerHTML={{ __html: list }}
            onClick={handleOnClick}
          ></p>
        ))}
      </div>
    </div>
  );
};

export default NumberWithHeadingComp;
