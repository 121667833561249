import React from 'react';

const CommonAsDC = ({ children,isTopMargin }) => {
  return (
    <div className={`max-w-[1240px] max_xl:max-w-[980px] m-auto  max_md:px-10 max_sm:px-5 ${isTopMargin ? 'mt-[100px] max_md:mt-10 max_sm:mt-[50px]' : ''}`}>
      {children}
    </div>
  );
};

export default CommonAsDC;
