import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  ACHLogo,
  AmazonPayLogo,
  AmericanExp,
  AppleLogo,
  AuthorizLogo,
  BankOfAmerica,
  CapitalIcon,
  ChaseIcon,
  CityIcon,
  DiscoverIcon,
  GpayLogo,
  JCBIcon,
  MasterCardIcon,
  PayPAlLogo,
  PayPalLogo,
  RepayLogo,
  StripeLogo,
  UnionExp,
  USAPayLogo,
  VenmoLogo,
  VisaIcon,
  WellsFargoIcon,
} from '../../assets/Icons/connectedAppsIcon';
import DigitalWallets from './DigitalWallets';

const PaymentMethod = () => {
  const { t } = useTranslation();

  const [paymentMethodLogo] = useState({
    list1: [
      {
        icon: <RepayLogo />,
      },
      {
        icon: <StripeLogo />,
      },
      {
        icon: <PayPalLogo />,
      },
    ],
    list2: [
      {
        icon: <ACHLogo />,
      },
      {
        icon: <USAPayLogo />,
      },
      {
        icon: <AuthorizLogo />,
      },
    ],
  });

  const [paymentMethod,] = useState([
    {
      id: 1,
      name: 'Card',
      title: t('Card'),
    },
    {
      id: 2,
      name: 'Bank',
      title: t('Bank'),
    },
    {
      id: 3,
      name: 'Wire',
      title: t('Wire'),
    },
    {
      id: 4,
      name: 'wallets',
      title: t('DigitalWallets'),
    },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedMethod,setSelectedMethod] = useState('Card')
  const [digitalFeature, ] = useState({
    wallets: {
      services: [
        {
          title: t('Convenience'),
          text: t('ConvenienceText'),
        },
        {
          title: t('Innovation'),
          text: t('InnovationTextWallet'),
        },
        {
          title: t('SecurityT'),
          text: t('SecurityTextWallet'),
        },
        {
          title: t('Efficiency'),
          text: t('EfficiencyText1'),
        },
      ],
      title: t('WalletMethod'),
      logos: [
        <AppleLogo />,
        <GpayLogo />,
        <VenmoLogo />,
        <PayPAlLogo />,
        <AmazonPayLogo />,
      ],
    },
    Card: {
      services: [
        {
          title: t('GlobalReach'),
          text: t('GlobalReachT'),
        },
        {
          title: t('DynamicAcceptance'),
          text: t('DynamicAcceptanceT'),
        },
        {
          title: t('CardValidation'),
          text: t('CardValidationT'),
        },
        {
          title: t('CardUpdater'),
          text: t('CardUpdaterT'),
        },
      ],
      title: t('CardMethod'),
      logos: [
        <VisaIcon />,
        <MasterCardIcon />,
        <AmericanExp />,
        <UnionExp />,
        <DiscoverIcon />,
        <JCBIcon />,
      ],
    },
    Bank: {
      services: [
        {
          title: t('AccountAuth'),
          text: t('AccountAuthT'),
        },
        {
          title: t('BalanceChecking'),
          text: t('BalanceCheckingT'),
        },
        {
          title: t('IdentityVerification'),
          text: t('IdentityVerificationT'),
        },
        {
          title: t('TransactionHis'),
          text: t('TransactionHisT'),
        },
      ],
      title: t('BankMethod'),
      logos: [
        <ChaseIcon />,
        <CapitalIcon />,
        <WellsFargoIcon />,
        <BankOfAmerica />,
        <CityIcon />,
      ],
    },
    Wire: {
      services: [
        {
          title: t('Speed'),
          text: t('SpeedT'),
        },
        {
          title: t('Irreversibility'),
          text: t('IrreversibilityT'),
        },
        {
          title: t('SecurityT'),
          text: t('SecurityTe'),
        },
        {
          title: t('LargeTransactions'),
          text: t('LargeTransactionsT'),
        },
      ],
      title: t('WireMethod'),
      logos: [],
    },
  });
 
  return (
    <>
      <div className="mt-[100px] max_md:mt-[60px] max_sm:mt-10">
        {/* LOGOS */}
        <div className="flex justify-between w-full max_md:hidden">
          {paymentMethodLogo.list1.map((logo, index1) => (
            <div className={`w-[220px]`} key={index1}>
              {logo.icon}
            </div>
          ))}
        </div>
        <div className="mt-20 flex justify-between w-full max_md:hidden">
          {paymentMethodLogo?.list2.map((logo, index1) => (
            <div className={`w-max`} key={index1}>
              {logo.icon}
            </div>
          ))}
        </div>
        {/* TABLATE DESIGN */}
        <div className="hidden max_md:block">
          <div className="mt-20 max_600:mt-10 flex w-[530px] max_600:w-[260px] max_600:gap-10 max_600:justify-center m-auto gap-20 flex-wrap">
            {paymentMethodLogo?.list1
              .concat(paymentMethodLogo?.list2)
              .map((logo, index1) => (
                <div className={`flex`} key={index1}>
                  {logo.icon}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mt-[150px] max_md:mt-[100px] max_sm:mt-[60px]">
        <p className="Text20Normal font-medium text-DisableText ">
          {t('Paymentmethods')}
        </p>
        <h2 className="Text50 mt-6">{t('FlexibleOptions')}</h2>

        <div className="flex justify-center gap-5 max_sm:gap-2 mt-[50px] max_md:mt-10 max_md:flex-wrap max_800:max-w-[550px]">
          {paymentMethod.map((method, index) => (
            <React.Fragment key={index}>
              <div
                className={`cursor-pointer FLEX_CENTER rounded-[30px] border-[1px] border-ButtonP w-[210px] max_md:w-[176px] max_sm:w-[140px] py-3 max_sm:py-2 ${
                  activeIndex === index ? 'bg-ButtonP' : ''
                }`}
                onClick={() => {
                  setSelectedMethod(method.name)
                  setActiveIndex(index)
                }}
                key={index}
              >
                <p
                  className={`Text20Normal max_sm:text-textExtraSmall font-medium ${
                    activeIndex === index ? 'text-white' : 'text-ButtonP'
                  }`}
                >
                  {method.title}
                </p>
              </div>
            </React.Fragment>
          ))}
        </div>

        <DigitalWallets data={digitalFeature[selectedMethod]} />
      </div>
    </>
  );
};


export default PaymentMethod;
