import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleMethod from '../../Common/ToggleMethod';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import InboundImg from '../../../assets/images/Products/Communication/inboundImage.svg';
import outboundImg from '../../../assets/images/Products/Communication/OutboundImg.svg'
import InboundTImg from '../../../assets/images/Products/Communication/InboundT.svg';
import outboundTImg from '../../../assets/images/Products/Communication/OutboundT.svg';
import { useWindowSize } from '../../../utils/Hooks/useWindowSize';

const IVRComp = () => {
  const { t } = useTranslation();
  const [produtsType, setProductType] = useState([]);
  const [selectedValue, setSelectedValue] = useState(produtsType[0]);
  const { width: windowWidth } = useWindowSize();
  const isTabletView = windowWidth <= 720;
  const isMobileView = windowWidth <= 500;

  useEffect(() => {
    setProductType(getTranslatedList(t));
  }, [t]);
  useEffect(() => {
    if (produtsType) {
      setSelectedValue(produtsType[0]);
    }
  }, [produtsType]);
  return (
    <CommonSizeContainer>
      <div id="IVRId">
        <h2 className="Text50">{t('InteractiveVoiceC')}</h2>
        <p className="Text24 mt-6 max_md:mt-4">{t('InteractiveText')}</p>
        <div className="mt-10 max_md:mt-[60px] max_sm:mt-10 bg-LightBgSecondary rounded-[20px] py-10 max_sm:py-5">
          <ToggleMethod
            selected={selectedValue}
            setSelected={setSelectedValue}
            options={produtsType}
            width={isMobileView ? '110px' : isTabletView ? '150px' : '200px'}
            // handleClickScroll={handleClickScroll}
          />

          <div className="max_md:hidden">
            <div className="mt-14 flex justify-center min-h-[335px]">
              <img
                src={
                  selectedValue?.name === t('Inbound')
                    ? InboundImg
                    : outboundImg
                }
                alt="inbound"
              />
            </div>
          </div>
          {/* TABLATE VIEW */}
          <div className="hidden max_md:block max_sm:hidden">
            <div className="mt-14 flex justify-center min-h-[365px]">
              <img
                src={
                  selectedValue?.name === t('Inbound')
                    ? InboundTImg
                    : outboundTImg
                }
                alt="inbound"
              />
            </div>
          </div>
          {/* MOBILE DESIGN */}
          <div className="hidden max_sm:block">
            <div className="mt-14 flex justify-center min-h-[365px]">
              <img
                src={
                  selectedValue?.name === t('Inbound')
                    ? InboundTImg
                    : outboundTImg
                }
                alt="inbound"
              />
            </div>
          </div>
        </div>
      </div>
    </CommonSizeContainer>
  );
};
const getTranslatedList = (t) => {
  const lists = [
    {
      id: 'Inbound',
      name: t('Inbound'),
    },
    {
      id: 'Outbound',
      name: t('Outbound'),
    },
  ];

  return lists;
};

export default IVRComp;
