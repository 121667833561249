import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Plus from '../../assets/images/Footer/add.svg';
import Minus from '../../assets/images/Footer/minus.svg';
import { SeeMoreArrow } from '../../assets/Icons/icon';
import { useNavigate } from 'react-router-dom';

const FAQComponent = ({ data, isMargin, viewAll }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('boldfont')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('http')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url);
      }
    }
  };
  return (
    <>
      <div
        style={{
          zIndex: 20,
        }}
        className={``}
      >
        <div
          className={`z-40 m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] ${
            isMargin ? 'mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]' : ''
          }`}
        >
          <div className="flex flex-col">
            <div className="relative w-max m-auto">
              <h2 className="headingText text-center">{t('FAQs')}</h2>
              {viewAll && (
                <div
                  className="absolute right-[-116px] top-9 max_md:top-5 max_sm:right-[-94px] max_sm:top-3 flex gap-1 cursor-pointer"
                  onClick={() => navigate('/faq')}
                >
                  <p className="Text20Normal font-medium">View all</p>
                  <SeeMoreArrow color="#484540" />
                </div>
              )}
            </div>
            <div className="flex flex-col mt-5">
              {data?.map((list, index) => {
                return (
                  <React.Fragment key={index + 1}>
                    <div
                      className="py-10 max_md:py-5 border-b-[1px] border-StrokePrimary cursor-pointer"
                      onClick={() => {
                        if (openQuestionIndex === index) {
                          setOpenQuestionIndex(-1);
                        } else {
                          setOpenQuestionIndex(index);
                        }
                      }}
                    >
                      <div className="flex justify-between">
                        <div
                          className={`Text24 text-PrimaryT font-semibold w-[95%]`}
                        >
                          {list.question}
                        </div>
                        <div className="w-[30px]">
                          <img
                            src={openQuestionIndex === index ? Minus : Plus}
                            alt="Downarrow"
                            className={`${
                              openQuestionIndex !== index
                                ? 'rotate-[360deg]'
                                : 'rotate-180'
                            } transition-all`}
                          />
                        </div>
                      </div>
                      <div
                        className={`answerSection ${
                          openQuestionIndex === index ? 'open' : ''
                        }`}
                      >
                        <div
                          className={`Text20Normal leading-[30px] mt-2 w-[90%] max_sm:w-[97%] `}
                          dangerouslySetInnerHTML={{ __html: list.answer }}
                          onClick={handleOnClick}
                        >
                          {/* {list.answer} */}
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQComponent;
