import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image3Bg,
  Image4Bg,
  Image5Bg,
  Image6Bg,
  Image7Bg,
  LargeLeftBg,
  LargeRightBg,
  SmallLeftBg,
  SmallRightBg,
} from '../../assets/Icons/featureIcon';
// import BillingAutomation from '../../assets/images/Feature/BillingAuto.svg';
// import RecurringPayImg from '../../assets/images/Feature/RecurringPayments.svg';
// import MasterYourContact from '../../assets/images/Feature/MasterContact.svg';
// import InsightImg from '../../assets/images/Feature/InsightFingerprint.svg';
// import AdvancedAIImg from '../../assets/images/Feature/AdvancedAI.svg';
// import CommunicationSuiteImg from '../../assets/images/Solutions/Insurance.svg';
import FeatureComponent from './FeatureComponent';

const AllFeatures = () => {
  const { t } = useTranslation();
  const [features, setFeatures] = useState({});
  useEffect(() => {
    setFeatures({
      feature1: getTranslatedList(t, 'feature1'),
      feature2: getTranslatedList(t, 'feature2'),
      feature3: getTranslatedList(t, 'feature3'),
      feature4: getTranslatedList(t, 'feature4'),
      feature5: getTranslatedList(t, 'feature5'),
      feature6: getTranslatedList(t, 'feature6'),
      feature7: getTranslatedList(t, 'feature7'),
    });
  }, [t]);
  return (
    <div className="my-[150px] max_xl:my-[100px] max_sm:my-[60px] max_md:px-10 max_sm:px-5 flex flex-col gap-[150px] max_md:gap-[100px] max_sm:gap-[60px] overflow-hidden">
      {/* FEATURE 1 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col max_720:flex-col-reverse max_md:gap-10 items-center">
          <FeatureComponent
            id="01"
            title={t('BillingAutomation')}
            data={features?.feature1}
          />
          {/* Image */}
          <div className="relative z-20 max_xl:w-[60%] max_md:w-[80%] max_md:FLEX_CENTER max_720:w-[100%]">
            <img
              src="https://images.dcdial.com/avnovo.com/BillingAuto.svg"
              alt="BillingAutomation"
              className="w-full z-10"
            />
            <div className="absolute right-[-150px] max_xl:right-[-128px] w-[55%] top-0 -z-10 max_xl:w-[50%] max_md:right-[-80px]">
              <LargeRightBg style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute bottom-[142px] max_xl:bottom-[100px] left-[28px] -z-10 max_sm:w-[38%] max_sm:bottom-[6px]">
              <SmallRightBg style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 2 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex flex-row-reverse justify-between max_md:flex-col max_720:flex-col-reverse max_md:gap-5 items-center">
          <FeatureComponent
            id="02"
            title={t('RecurringPayments')}
            data={features?.feature2}
          />
          <div className="relative z-20 max_xl:w-[60%] max_md:w-[80%] max_md:FLEX_CENTER max_720:w-[100%]">
            <img
              src="https://images.dcdial.com/avnovo.com/RecurringPayments.svg"
              alt="RecurringPayment"
              className="w-full"
            />
            <div className="absolute left-[-150px] max_md:left-[-92px] max_sm:left-[-66px] w-[47%] top-0 -z-10 max_xl:w-[42%]">
              <LargeLeftBg style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute w-[42%] right-[-34px] max_xl:right-[22px] bottom-[34px] -z-10">
              <SmallLeftBg style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 3 */}

      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col max_720:flex-col-reverse max_md:gap-10 items-center">
          <FeatureComponent
            id="03"
            title={t('MasterContacts')}
            data={features?.feature3}
          />
          {/* Image */}
          <div className="relative z-20 max_xl:w-[58%] max_md:w-[80%] max_md:FLEX_CENTER max_720:w-[100%]">
            <img
              src="https://images.dcdial.com/avnovo.com/MasterContact.svg"
              alt="MasterContacts"
              className="w-full z-10"
            />
            <div className="absolute right-[-150px] max_sm:right-[-22px] w-[50%] max_sm:w-[40%] top-[-20px] -z-10 ">
              <LargeRightBg style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute w-[30%] bottom-[10px] left-[-106px] max_xl:bottom-[-100px] max_sm:bottom-[-22px] max_xl:left-[-10px] -z-10">
              <Image3Bg style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 4 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex flex-row-reverse justify-between max_md:flex-col max_720:flex-col-reverse max_md:gap-10 items-center">
          <FeatureComponent
            id="04"
            title={t('CommunicationSuite')}
            data={features?.feature4}
          />
          <div className="relative z-20 max_xl:w-[60%] max_md:w-[80%] max_md:FLEX_CENTER max_720:w-[100%]">
            <img
              src="https://images.dcdial.com/avnovo.com/Insurance.svg"
              alt="RecurringPayment"
              className="w-full"
            />
            <div className="absolute left-[-150px] w-[45%] top-[-20px] -z-10 ">
              <LargeLeftBg style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute w-[30%] right-[120px] max_sm:right-[90px] bottom-0 -z-10">
              <Image4Bg style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 5 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col max_720:flex-col-reverse max_md:gap-10 items-center">
          <FeatureComponent
            id="05"
            title={t('AdvancedTools')}
            data={features?.feature5}
          />
          {/* Image */}
          <div className="relative z-20 max_xl:w-[60%] max_md:w-[80%] max_md:FLEX_CENTER max_720:w-[100%]">
            <img
              src="https://images.dcdial.com/avnovo.com/AdvanceAI.svg"
              alt="AdvancedTools"
              className="w-full z-10"
            />
            <div className="absolute right-[-150px] max_sm:right-[-90px] w-[44%] top-0 -z-10 ">
              <LargeRightBg style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute w-[42%] bottom-[-26px] left-[6px] -z-10">
              <Image5Bg style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 6 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex flex-row-reverse justify-between max_md:flex-col max_720:flex-col-reverse max_md:gap-10 items-center">
          <FeatureComponent
            id="06"
            title={t('InsightsFingertips')}
            data={features?.feature6}
          />
          <div className="relative z-20 max_xl:w-[60%] max_md:w-[80%] max_md:FLEX_CENTER max_720:w-[100%]">
            <img
              src="https://images.dcdial.com/avnovo.com/InsightFingerprint.svg"
              alt="InsightsFingertips"
              className="w-full"
            />
            <div className="absolute left-[-150px] w-[47%] top-0 -z-10">
              <LargeLeftBg style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute w-[25%] right-[110px] max_sm:right-[60px] max_sm:bottom-5 bottom-[45px] -z-10">
              <Image6Bg style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURE 7 */}
      <div className="w-full">
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] flex justify-between max_md:flex-col max_720:flex-col-reverse max_md:gap-10 items-center">
          <FeatureComponent
            id="07"
            title={t('StayCompliant')}
            data={features?.feature7}
          />
          {/* Image */}
          <div className="relative z-20 w-[50%] max_xl:w-[60%] max_md:w-[80%] max_md:FLEX_CENTER max_720:w-[100%]">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/Compliant.svg"
              alt="CuttingEdgeDialer"
              className="w-full z-10"
            />
            <div className="absolute right-[-150px] w-[50%] top-0 -z-10 ">
              <LargeRightBg style={{ width: '100%', height: '100%' }} />
            </div>
            <div className="absolute bottom-[50px] left-[192px] max_sm:left-[102px] max_sm:bottom-2 -z-10 max_sm:w-[30%]">
              <Image7Bg style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getTranslatedList = (t, listKey) => {
  // Define your lists here
  const lists = {
    feature1: [
      t('DunningManag'),
      t('PaymentLinks'),
      t('MultipleGateways'),
      t('MultipleMethods'),
      t('VoidPayments'),
      t('CustomFees'),
      t('MobileOpt'),
      t('CustomBrandingL'),
      t('SelfPortal'),
      t('PaymentInt'),
      t('AutomatedReminders'),
      t('DeveloperAPIs'),
    ],
    feature2: [
      t('RecurringBilling'),
      t('VirtualCollector'),
      t('PaymentsDash'),
      t('BudgetCalculator'),
      t('Payouts'),
      t('SmartRetries'),
      t('SubscriptionManag'),
      t('CardAcceptance'),
      t('InvoiceQuote'),
      t('ManuallyPosted'),
      t('PaymentManage'),
    ],
    feature3: [
      t('ContactManagement'),
      t('CRMSyncing'),
      t('UserLead'),
      t('APIIntegration'),
      t('IntegrationCRM'),
      t('ContactTrack'),
      t('MultiPermissions'),
      t('RealTimePosting'),
    ],
    feature4: [
      t('SMSCampaigns'),
      t('IVR'),
      t('CustomizableEmail'),
      t('PaymentCampaigns'),
      t('PredictiveDialer'),
      t('ClickToDial'),
      t('PowerDialer'),
      t('CampaignManage'),
      t('TwoWayText'),
      t('PreviewDialer'),
    ],
    feature5: [
      t('RealAgent'),
      t('PredictiveEng'),
      t('WorkflowAutomationL'),
      t('Voicebots'),
      t('VirtualAgents'),
      t('KnowledgeTools'),
      t('CustomerSelfService'),
    ],
    feature6: [
      t('CustomizableReport'),
      t('PerformanceAnalysis'),
      t('CampaignReports'),
      t('CustomerReports'),
      t('RealDashboards'),
      t('BillingReports'),
      t('RevenueKPIs'),
      t('UserTracking'),
    ],
    feature7: [
      t('PaymentState'),
      t('OptMechanisms'),
      t('BankVerification'),
      t('CustomDisclosure'),
      t('DataSecurity'),
      t('CustomData'),
      t('FraudDispute'),
      t('RoleAccess'),
    ],
  };

  return lists[listKey] || [];
};

export default AllFeatures;
