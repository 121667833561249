import React from 'react';


const DigitalWallets = ({ data }) => {
  
  return (
    <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-10 flex justify-between max_md:flex-col max_md:gap-[60px] max_sm:gap-10">
      <div className="flex flex-col gap-6 max-w-[460px] max_xl:max-w-[400px] max_md:max-w-full">
        <h2 className="Text50 max_md:mt-[6px]">{data.title}</h2>
        <div className="flex flex-wrap gap-10 max_sm:gap-[30px] items-center mt-4">
          {data.logos.map((logo, index) => (
            <React.Fragment key={index}>
              <div>{logo}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap gap-[60px] justify-end max_xl:gap-8 max_md:justify-between max_md:gap-y-5 max_md:gap-x-2 max_sm:gap-10">
        {data.services.map((feature, index) => (
          <div
            className="w-[300px] max_xl:w-[250px] max_md:w-[45%] max_sm:w-full"
            key={index}
          >
            <div className="flex items-center gap-2">
              <div className="w-[4px] h-[46px] max_sm:h-[28px] max_md:h-[34px] rounded bg-PrimaryT"></div>
              <h3 className="Text30 max_xl:text-[28px] max_sm:text-textMedium">{feature.title}</h3>
            </div>

            <p className="Text20Normal mt-3">{feature.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
// const getTranslatedList = (t) => {
//   // Define your lists here
//   const featureList = [
//     {
//       first: {
//         services: [
//           {
//             title: t('Convenience'),
//             text: t('ConvenienceText'),
//           },
//           {
//             title: t('Innovation'),
//             text: t('InnovationTextWallet'),
//           },
//           {
//             title: t('SecurityT'),
//             text: t('SecurityTextWallet'),
//           },
//           {
//             title: t('Efficiency'),
//             text: t('EfficiencyText'),
//           },
//         ],
//         title: t('CardMethod'),
//         logos: [
//           <AppleLogo />,
//           <GpayLogo />,
//           <VenmoLogo />,
//           <PayPAlLogo />,
//           <AmazonPayLogo />,
//         ],
//       },
//     },
//   ];

//   return featureList;
// };

export default DigitalWallets;
