import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { DropDownArrow, RightArrowIcon } from '../../assets/Icons/icon';
import { useOnClickOutside } from '../Common/useOnClickOutside';

const LegalSideLayout = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [legalType] = useState([
    {
      name: t('ForCustomer'),
      isOpen: true,
      legalOptions: [
        {
          id: 1,
          name: t('CustomerTerms'),
          link: '/legal/terms-of-service',
        },
        {
          id: 2,
          name: t('ProductTerms'),
          link: '/legal/product-specific-terms',
        },
        {
          id: 3,
          name: t('DataProcessing'),
          link: '/legal/dpa',
        },
        {
          id: 4,
          name: t('AcceptablePolicy'),
          link: '/legal/acceptable-use',
        },
      ],
    },
    {
      name: t('ForDevelopers'),
      isOpen: false,
      legalOptions: [
        {
          id: 1,
          name: t('DeveloperTerms'),
          link: '/legal/developer-terms',
        },
        {
          id: 2,
          name: t('DeveloperPolicy'),
          link: '/legal/developer-policy',
        },
        {
          id: 3,
          name: t('AcceptablePolicy'),
          link: '/legal/acceptable-use',
        },
      ],
    },
    {
      name: t('ForEveryone'),
      isOpen: false,
      legalOptions: [
        {
          id: 1,
          name: t('WebsiteTerms'),
          link: '/legal/website-terms-of-use',
        },
        {
          id: 2,
          name: t('WebsiteAccess'),
          link: '/legal/website-accessibility',
        },
        {
          id: 3,
          name: t('PrivacyPolicy'),
          link: '/legal/privacy-policy',
        },
        {
          id: 4,
          name: t('CookiePolicy'),
          link: '/legal/cookie-policy',
        },
        {
          id: 5,
          name: t('EuropeanServices'),
          link: '/legal/digital-services-act',
        },
      ],
    },
  ]);
  const [isOpen, setIsOpen] = useState(true);
  const [openLegal, setOpenLegal] = useState(false);
  const [selectedLegalType, setSelectedLegalType] = useState(
    location.pathname === '/legal/terms-of-service'
      ? legalType[0].legalOptions[0].name
      : location.pathname === '/legal/product-specific-terms'
      ? legalType[0].legalOptions[1].name
      : location.pathname === '/legal/dpa'
      ? legalType[0].legalOptions[2].name
      : location.pathname === '/legal/acceptable-use'
      ? legalType[0].legalOptions[3].name
      : location.pathname === '/legal/developer-terms'
      ? legalType[1].legalOptions[0].name
      : location.pathname === '/legal/developer-policy'
      ? legalType[1].legalOptions[1].name
      : location.pathname === '/legal/website-terms-of-use'
      ? legalType[2].legalOptions[0].name
      : location.pathname === '/legal/website-accessibility'
      ? legalType[2].legalOptions[1].name
      : location.pathname === '/legal/privacy-policy'
      ? legalType[2].legalOptions[2].name
      : location.pathname === '/legal/cookie-policy'
      ? legalType[2].legalOptions[3].name
      : location.pathname === '/legal/digital-services-act'
      ? legalType[2].legalOptions[4].name
      : ''
  );
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    if (openLegal) {
      setOpenLegal(false);
    }
  });

  const [selectedValue, setSelectedValue] = useState(
    location.pathname === '/legal/terms-of-service' ||
      location.pathname === '/legal/product-specific-terms' ||
      location.pathname === '/legal/dpa' ||
      location.pathname === '/legal/acceptable-use'
      ? legalType[0]
      : location.pathname === '/legal/developer-terms' ||
        location.pathname === '/legal/developer-policy' ||
        location.pathname === '/legal/acceptable-use'
      ? legalType[1]
      : legalType[2]
  );
  const handleClick = (name, link) => {
    setSelectedLegalType(name);
    navigate(link);
  };

  const handleStorage = () => {
    localStorage.removeItem('scrollPositionT');
    localStorage.removeItem('scrollPositionS');
    localStorage.removeItem('scrollPositionDPA');
    localStorage.removeItem('scrollPositionA');
    localStorage.removeItem('scrollPositionD');
    localStorage.removeItem('scrollPositionDT');
    localStorage.removeItem('scrollPositionWT');
    localStorage.removeItem('scrollPositionWA');
    localStorage.removeItem('scrollPositionPP');
    localStorage.removeItem('scrollPositionCP');
    localStorage.removeItem('scrollPositionSD');
    localStorage.removeItem('appsCount');
  };
  return (
    <div className="bg-white">
      <div className="bg-WebRootColor">
        <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto py-[100px] max_md:py-[40px] max_md:px-10 max_sm:px-5">
          <h1 className="headingText text-center">{t('LegalRepository')}</h1>
        </div>
      </div>
      <CommonSizeContainer isTopMargin isBottomPadding>
        <div className="flex justify-between max_md:flex-col max_md:gap-[60px] max_sm:gap-10">
          {/* DESKTOP DESIGN */}
          <div className="w-[220px] flex flex-col gap-2 h-fit border-l-[1px] border-StrokePrimary max_md:hidden sticky top-16">
            {legalType.map((legal, index1) => (
              <React.Fragment key={index1}>
                <div
                  className={`px-5 py-3 Text16Normal cursor-pointer transition-colors duration-400 flex justify-between ${
                    legal.name === selectedValue.name
                      ? 'bg-ButtonP text-white'
                      : 'text-TertiaryT'
                  }`}
                  onClick={() => {
                    if (legal.name === selectedValue.name) {
                      setIsOpen(!isOpen);
                    } else {
                      setIsOpen(true);
                      setSelectedValue(legal);
                    }
                  }}
                >
                  <p>{legal.name}</p>
                  <div
                    className={`transform transition-transform duration-200 ${
                      isOpen && legal.name === selectedValue.name
                        ? 'rotate-90'
                        : ''
                    }`}
                  >
                    <RightArrowIcon
                      color={
                        legal.name === selectedValue.name
                          ? '#ffffff'
                          : '#666666'
                      }
                    />
                  </div>
                </div>
                {isOpen && legal.name === selectedValue.name && (
                  <>
                    <div className="mt-2 flex flex-col gap-[14px] pl-5 pr-[10px]">
                      {legal.legalOptions.map((option, index) => (
                        <div className="flex gap-3" key={index}>
                          <p
                            className={`mt-[10px] w-[6px] h-[6px] rounded-full ${
                              location.pathname === option.link
                                ? 'bg-PrimaryT'
                                : 'bg-TertiaryT'
                            }`}
                          ></p>
                          <p
                            className={`cursor-pointer w-[calc(100%-18px)] Text16Normal hover:text-PrimaryT  ${
                              location.pathname === option.link
                                ? 'text-PrimaryT'
                                : 'text-TertiaryT'
                            }`}
                            onClick={() => {
                              navigate(option.link);
                              handleStorage();
                              setTimeout(() => {
                                window.scrollTo(0, 0);
                              }, 100);
                            }}
                          >
                            {option.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
          {/* TABLET AND MOBILE VIEW */}
          <div className="hidden max_md:block w-full">
            <div
              className={`relative border-[1px] border-StrokePrimary py-3 px-5 rounded-[10px] font-OutfitFont text-textSmall font-normal  ${
                selectedLegalType
                  ? 'font-semibold text-PrimaryT'
                  : 'font-medium text-SecondaryT'
              }`}
              onClick={() => setOpenLegal(!openLegal)}
              ref={dropdownRef}
            >
              {selectedLegalType ? selectedLegalType : 'Legal repository'}
              <div
                className={`absolute right-5 top-3 cursor-pointer transition-transform duration-200 ${
                  openLegal ? 'rotate-180' : ''
                }`}
              >
                <DropDownArrow />
              </div>

              {openLegal && (
                <>
                  <div className="legal-shadow absolute top-14 left-0 w-full rounded-[10px] py-2 flex flex-col gap-[6px] bg-white z-30 max-h-[500px] overflow-y-auto">
                    {legalType?.map((inquiry, index) => (
                      <>
                        <p
                          className={`py-2 text-textExtraSmall font-semibold px-3 text-PrimaryT ${
                            index !== 0 ? 'mt-2' : ''
                          }`}
                          key={index + 1}
                        >
                          {inquiry.name}
                        </p>
                        {inquiry.legalOptions.map((list, index1) => (
                          <p
                            className={`py-2 text-textExtraSmall font-medium px-3 text-LightSecondary cursor-pointer hover:text-PrimaryT hover:bg-BgSecondary`}
                            key={index1}
                            onClick={() => handleClick(list.name, list.link)}
                          >
                            {list.name}
                          </p>
                        ))}
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full max-w-[850px] max_xl:max-w-[730px] max_md:max-w-[100%]">
            {children}
          </div>
        </div>
      </CommonSizeContainer>
    </div>
  );
};

export default LegalSideLayout;
