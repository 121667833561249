import React, { useEffect, useState } from 'react';
import { ButtonFilled } from '../../utils/Button';
import { SeeMoreArrow } from '../../assets/Icons/icon';
import { useTranslation } from 'react-i18next';
// import Payments from '../../assets/images/Products/Payments.svg';
// import AIAssistant from '../../assets/images/Products/AIAssistant.svg';
// import Communication from '../../assets/images/Products/Communication.svg';
// import CRM from '../../assets/images/Products/CRMProduct.svg';
import CommonSectionContent from '../Common/CommonSectionContent';
import { useNavigate } from 'react-router-dom';

const AllProducts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [products, setProducts] = useState([
    {
      id: 'payments',
      heading: t('OnlinePayments'),
      name: t('Payments'),
      text: t('PaymentsT'),
      image: 'https://images.dcdial.com/avnovo.com/Payments.svg',
      link: '/products/payments',
      product: [
        {
          id: 'SubscriptionId',
          title: t('Subscriptions'),
        },
        {
          id: 'PaymentLinksId',
          title: t('PaymentLinks'),
        },
        {
          id: 'QuotesInvoices',
          title: t('QuotesInvoices'),
        },
        {
          id: 'RecurringBilling',
          title: t('RecurringBilling'),
        },
        {
          id: 'CustomerPortal',
          title: t('CustomerPortal'),
        },
        {
          id: 'BudgetCalculatorId',
          title: t('BudgetCalculator'),
        },
        {
          id: 'VirtualCollectorId',
          title: t('VirtualCollector'),
        },
        {
          id: 'RevenueOpt',
          title: t('RevenueOpt'),
        },
      ],
    },
    {
      id: 'crm',
      heading: t('ContactManagement'),
      name: t('CRM'),
      text: t('CRMText'),
      image: 'https://images.dcdial.com/avnovo.com/CRMProduct.svg',
      link: '/products/crm',
      product: [
        {
          id: 'ContactManagementId',
          title: t('ContactManagement'),
        },
        {
          id: 'ListAutomationId',
          title: t('ListAutomation'),
        },
        {
          id: 'CRMSyncingId',
          title: t('CRMSyncing'),
        },
        {
          id: 'CustomizableFieldId',
          title: t('CustomizableFields'),
        },
        {
          id: 'ThirdPartyIntId',
          title: t('ThirdPartyIntegration'),
        },
        {
          id: 'UserManagementId',
          title: t('UserManagement'),
        },
      ],
    },
    {
      id: 'communication',
      name: t('Communication'),
      heading: t('CollaborationTools'),
      text: t('CommunicationText'),
      image: 'https://images.dcdial.com/avnovo.com/Communication.svg',
      link: '/products/communication',
      product: [
        {
          id: 'InboundCallId',
          title: t('InboundCallCenter'),
        },
        {
          id: 'IVRId',
          title: t('IVR'),
        },
        {
          id: 'UnifiedComId',
          title: t('UnifiedComm'),
        },
        {
          id: 'OutboundCallId',
          title: t('OutboundCallCenter'),
        },
        {
          id: 'SmartRoutingId',
          title: t('SmartRouting'),
        },
      ],
    },

    {
      id: 'ai-assistant',
      name: t('AIAssistant'),
      heading: t('AIDrivensupport'),
      text: t('AIText'),
      image: 'https://images.dcdial.com/avnovo.com/AIAssistant.svg',
      link: '/products/ai-assistant',
      product: [
        {
          id: 'AgentAssistanceId',
          title: t('AgentAssistance'),
        },
        {
          id: 'KnowledgeToolId',
          title: t('KnowledgeTools'),
        },
        {
          id: 'WorkAutomationId',
          title: t('WorkAutomation'),
        },
        {
          id: 'ChatbotId',
          title: t('Chatbots'),
        },
        {
          id: 'PredictiveEngId',
          title: t('PredictiveEng'),
        },
        {
          id: 'VirtualAssistantId',
          title: t('VirtualAssistants'),
        },
        {
          id: 'VoicebotsId',
          title: t('Voicebots'),
        },
      ],
    },
  ]);
  // useEffect(() => {
  //   setProducts(getTranslatedList(t, 'productsList'));
  // }, [t]);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 80;

      if (id === 'BudgetCalculatorId' || id === 'VirtualCollectorId') {
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  };
  return (
    <>
      {products.map((productDetails, index) => {
        return (
          <div
            className={`w-full ${
              index % 2 === 0 ? 'bg-white' : 'bg-WebRootColor'
            }`}
            key={index}
          >
            <CommonSectionContent>
              <div
                className={`flex max_md:flex-col justify-between items-center ${
                  index % 2 !== 0 && 'flex-row-reverse'
                }`}
              >
                <div className={`flex flex-col gap-[150px] max_xl:gap-[100px]`}>
                  <div className="max-w-[390px] max_xl:max-w-[380px] max_md:max-w-full">
                    <div className="max_md:flex max_md:justify-between max_md:gap-5 max_sm:flex-col">
                      <div>
                        <p className="text-DisableText font-OutfitFont font-medium text-textMedium max_md:text-textNormal max_sm:text-textExtraSmall max_sm:mt-3">
                          {productDetails.heading}
                        </p>
                        <h2 className="subHeading mt-4 max_sm:mt-3">
                          {productDetails.name}
                        </h2>
                        <p className="mt-4 max_sm:mt-3 Text24 max_md:max-w-[380px]">
                          {productDetails.text}
                        </p>
                        <div className="mt-[30px]">
                          <ButtonFilled
                            rounded="60px"
                            title={`Start with ${productDetails.name}`}
                            fontWeight="500"
                            fontSize="16px"
                            width="max-content"
                            onClick={() => navigate(productDetails.link)}
                          />
                        </div>
                      </div>
                      <div className="mt-[30px] max_sm:hidden">
                        <div className="flex gap-2 items-center">
                          <p className="text-textMedium font-OutfitFont font-medium text-PrimaryT">
                            Also view
                          </p>
                          <div className="max_sm_380:mt-[2px] flex items-center">
                            <SeeMoreArrow />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2 mt-3">
                          {productDetails.product.map((list, index2) => (
                            <p
                              className={`Text20Normal text-ButtonP cursor-pointer w-max border-b-[1.5px]  hover:border-ButtonP ${
                                index % 2 === 0
                                  ? 'border-white'
                                  : 'border-WebRootColor'
                              }`}
                              key={index2}
                              onClick={() => {
                                navigate(productDetails.link);
                                setTimeout(() => {
                                  handleClickScroll(list.id);
                                }, 400);
                              }}
                            >
                              {list.title}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    index % 2 !== 0 ? 'min-h-[570px] max_xl:min-h-[475px]' : 'min-h-[706px] max_xl:min-h-[585px]'
                  }`}
                >
                  <img src={productDetails.image} alt={productDetails.name} />
                </div>
                <div className="hidden mt-4 max_sm:block">
                  <div className="flex gap-2 items-center">
                    <p className="text-textMedium font-OutfitFont font-medium text-PrimaryT">
                      Also view
                    </p>
                    <div className="max_sm_380:mt-[2px] flex items-center">
                      <SeeMoreArrow />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mt-3">
                    {productDetails.product.map((list, index2) => (
                      <p
                        className="Text20Normal text-ButtonP cursor-pointer w-max"
                        key={index2}
                        onClick={() => {
                          navigate(productDetails.link);
                          setTimeout(() => {
                            handleClickScroll(list.id);
                          }, 400);
                        }}
                      >
                        {list.title}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </CommonSectionContent>
          </div>
        );
      })}
    </>
  );
};

// const getTranslatedList = (t, listKey) => {
//   const lists = {
//     productsList: [
//       {
//         id: 'payments',
//         heading: t('OnlinePayments'),
//         name: t('Payments'),
//         text: t('PaymentsT'),
//         image: 'https://images.dcdial.com/avnovo.com/Payments.svg',
//         link: '/products/payments',
//         product: [
//           {
//             id: 'SubscriptionId',
//             title: t('Subscriptions'),
//           },
//           {
//             id: 'PaymentLinksId',
//             title: t('PaymentLinks'),
//           },
//           {
//             id: 'QuotesInvoices',
//             title: t('QuotesInvoices'),
//           },
//           {
//             id: 'RecurringBilling',
//             title: t('RecurringBilling'),
//           },
//           {
//             id: 'CustomerPortal',
//             title: t('CustomerPortal'),
//           },
//           {
//             id: 'BudgetCalculatorId',
//             title: t('BudgetCalculator'),
//           },
//           {
//             id: 'VirtualCollectorId',
//             title: t('VirtualCollector'),
//           },
//           {
//             id: 'RevenueOpt',
//             title: t('RevenueOpt'),
//           },
//         ],
//       },
//       {
//         id: 'crm',
//         heading: t('ContactManagement'),
//         name: t('CRM'),
//         text: t('CRMText'),
//         image: 'https://images.dcdial.com/avnovo.com/CRMProduct.svg',
//         link: '/products/crm',
//         product: [
//           {
//             id: 'ContactManagementId',
//             title: t('ContactManagement'),
//           },
//           {
//             id: 'ListAutomationId',
//             title: t('ListAutomation'),
//           },
//           {
//             id: 'CRMSyncingId',
//             title: t('CRMSyncing'),
//           },
//           {
//             id: 'CustomizableFieldId',
//             title: t('CustomizableFields'),
//           },
//           {
//             id: 'ThirdPartyIntId',
//             title: t('ThirdPartyIntegration'),
//           },
//           {
//             id: 'UserManagementId',
//             title: t('UserManagement'),
//           },
//         ],
//       },
//       {
//         id: 'communication',
//         name: t('Communication'),
//         heading: t('CollaborationTools'),
//         text: t('CommunicationText'),
//         image: 'https://images.dcdial.com/avnovo.com/Communication.svg',
//         link: '/products/communication',
//         product: [
//           {
//             id: 'InboundCallId',
//             title: t('InboundCallCenter'),
//           },
//           {
//             id: 'IVRId',
//             title: t('IVR'),
//           },
//           {
//             id: 'UnifiedComId',
//             title: t('UnifiedComm'),
//           },
//           {
//             id: 'OutboundCallId',
//             title: t('OutboundCallCenter'),
//           },
//           {
//             id: 'SmartRoutingId',
//             title: t('SmartRouting'),
//           },
//         ],
//       },

//       {
//         id: 'ai-assistant',
//         name: t('AIAssistant'),
//         heading: t('AIDrivensupport'),
//         text: t('AIText'),
//         image: 'https://images.dcdial.com/avnovo.com/AIAssistant.svg',
//         link: '/products/ai-assistant',
//         product: [
//           {
//             id: 'AgentAssistanceId',
//             title: t('AgentAssistance'),
//           },
//           {
//             id: 'KnowledgeToolId',
//             title: t('KnowledgeTools'),
//           },
//           {
//             id: 'WorkAutomationId',
//             title: t('WorkAutomation'),
//           },
//           {
//             id: 'ChatbotId',
//             title: t('Chatbots'),
//           },
//           {
//             id: 'PredictiveEngId',
//             title: t('PredictiveEng'),
//           },
//           {
//             id: 'VirtualAssistantId',
//             title: t('VirtualAssistants'),
//           },
//           {
//             id: 'VoicebotsId',
//             title: t('Voicebots'),
//           },
//         ],
//       },
//     ],
//   };

//   return lists[listKey];
// };

export default AllProducts;
