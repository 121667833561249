import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';
import { Safe1Icon, Safe2Icon, Safe3Icon, Safe4Icon, Safe5Icon } from '../../assets/Icons/securityIcons';


const SecurityStandard = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 600;

  return (
    <div className="clip-path-class bg-white py-[180px] max_xl:py-[140px] max_md:py-[100px]">
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5">
        <p className="Text20Normal font-medium text-DisableText">
          {t('SecurityStandards')}
        </p>
        <div className="flex max_md:flex-col justify-between mt-6 max_xl:gap-8 max_md:gap-[60px] max_600:gap-10">
          <div className="max-w-[450px] max_md:max-w-[100%] flex flex-col gap-6 max_xl:max-w-[420px]">
            <h2 className="Text50">{t('SafeConfidentialInfo')}</h2>
            <p className="paragraphText text-TertiaryT">
              {t('SafeConfidentialInfoText')}
            </p>
          </div>
          <div className="flex gap-6 max_sm_380:flex-col max_600:gap-2">
            <div className="flex flex-col gap-6 max_600:gap-3 h-max">
              <div className="bg-BgLight p-4 max_sm:p-[10px] rounded-xl flex gap-[14px] max_sm:gap-3 max-w-[286px] items-center ">
                <div>
                  <Safe3Icon
                    style={{
                      width: isMobileView ? '30' : '',
                      height: isMobileView ? '30' : '',
                    }}
                  />
                </div>
                <p className="Text20Normal text-PrimaryT max_sm:text-textExtraSmall">
                  {t('SafeText1')}
                </p>
              </div>
              <div className="bg-BgLight p-4 max_sm:p-[10px] rounded-xl flex gap-[14px] max_sm:gap-3 max-w-[286px] items-center">
                <div>
                  <Safe1Icon
                    style={{
                      width: isMobileView ? '30' : '',
                      height: isMobileView ? '30' : '',
                    }}
                  />
                </div>
                <p className="Text20Normal text-PrimaryT max_sm:text-textExtraSmall">
                  {t('SafeText2')}
                </p>
              </div>
              <div className="bg-BgLight p-4 max_sm:p-[10px] rounded-xl flex gap-[14px] max_sm:gap-3 max-w-[286px] items-center">
                <div>
                  <Safe2Icon
                    style={{
                      width: isMobileView ? '30' : '',
                      height: isMobileView ? '30' : '',
                    }}
                  />
                </div>
                <p className="Text20Normal text-PrimaryT max_sm:text-textExtraSmall">
                  {t('SafeText3')}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6 max_md:justify-center max_600:gap-3 h-max">
              <div className="bg-BgLight py-[21px] px-4 max_xl:py-[18px] max_md:py-[21px] max_sm:p-[10px] rounded-xl flex gap-[14px] max_sm:gap-3 max-w-[286px]  items-center">
                <div>
                  <Safe4Icon
                    style={{
                      width: isMobileView ? '30' : '',
                      height: isMobileView ? '30' : '',
                    }}
                  />
                </div>
                <p className="Text20Normal text-PrimaryT max_sm:text-textExtraSmall">
                  {t('SafeText4')}
                </p>
              </div>
              <div className="bg-BgLight py-[21px] px-4 max_xl:py-[18px] max_md:py-[21px] max_sm:p-[10px] rounded-xl flex gap-[14px] max_sm:gap-3 max-w-[286px] items-center">
                <div>
                  <Safe5Icon
                    style={{
                      width: isMobileView ? '30' : '',
                      height: isMobileView ? '30' : '',
                    }}
                  />
                </div>
                <p className="Text20Normal text-PrimaryT max_sm:text-textExtraSmall">
                  {t('SafeText5')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityStandard;
