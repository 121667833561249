import React from 'react';

const QuestionAnswer = ({ data, isLast }) => {
  return (
    <div className="flex flex-col mt-[60px] max_sm:mt-[30px]">
      <p className="Text24 font-semibold text-PrimaryT max_sm:text-textMedium">{data.que}</p>
      <p className="Text20Normal leading-[30px] max_sm:leading-[25px] mt-3" dangerouslySetInnerHTML={{__html : data.ans}}></p>
      {!isLast && (
        <div className="w-[300px] h-[1px] bg-[#ACACAC] mt-[60px] max_sm:mt-[30px]"></div>
      )}
    </div>
  );
};

export default QuestionAnswer;
