import React from 'react';
import { useTranslation } from 'react-i18next';
import BannerWithTitleDesc from '../Common/BannerWithTitleDesc';
import BannerImg from '../../assets/images/Security/SecurityBanner.svg';
import CommonSizeContainer from '../Common/CommonSizeContainer';

const SecurityBanner = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerWithTitleDesc
        title={t('SecurityTitle')}
        text={t('SecurityTextD')}
      />
      <CommonSizeContainer>
        <div className='pb-[100px]'>
          <img src={BannerImg} alt="BannerImg" />
        </div>
      </CommonSizeContainer>
    </>
  );
};

export default SecurityBanner;
