// import React, { useEffect, useRef, useState } from 'react';

// // Phone number input related imports
// import countryNames from 'react-phone-number-input/locale/en';
// import PhoneInput from 'react-phone-number-input';
// import { getCountryCallingCode, getCountries } from 'react-phone-number-input';

// // Icons
// import searchIcon from '../../assets/images/Footer/searchIcon.svg';
// import arrowIcon from '../../assets/images/Footer/arrow-down.svg';

// import 'react-phone-number-input/style.css';

// const SelectMobileNumber = ({
//   inputMobileData,
//   setInputValue,
//   error,
//   setErrors,
//   isAuth,
//   countryLib,
//   setCountryLib,
//   defaultCountry,
// }) => {
//   const countryRef = useRef(null);
//   const [value, setValue] = useState(
//     inputMobileData.phone_number ? inputMobileData.phone_number : ''
//   );
//   useEffect(() => {
//     setValue(inputMobileData.phone_number);
//   }, [inputMobileData]);
//   const [isOpen, setIsOpen] = useState(false);
//   // const [countryLib, setCountryLib] = useState('US');
//   const [searchInput, setSearchInput] = useState('');
//   const [countries, setCountries] = useState(getCountries());

//   useEffect(() => {
//     if (defaultCountry) {
//       setCountryLib(defaultCountry);
//     }
//   }, [defaultCountry]);

//   const handleCountrySelect = (countryName) => {
//     setCountryLib(countryName);
//     setValue(`+${getCountryCallingCode(countryName)}`);
//   };

//   useEffect(() => {
//     const names = getCountries();
//     let filteredData = names;

//     if (searchInput) {
//       filteredData = names.filter((el) => {
//         const lowerCaseEl = el.toLowerCase();
//         const lowerCaseSearchInput = searchInput.toLowerCase();
//         return (
//           lowerCaseEl.includes(lowerCaseSearchInput) ||
//           countryNames[el].toLowerCase().includes(lowerCaseSearchInput)
//         );
//       });
//     }

//     setCountries(filteredData);
//   }, [searchInput]);

//   useEffect(() => {
//     setCountries(getCountries());
//   }, []);

//   const handleOnChangeSearch = (e) => {
//     setSearchInput(e.target.value);
//   };

//   const handleNumberChange = (data) => {
//     setValue(data);
//     setInputValue((prev) => {
//       return {
//         ...prev,
//         phone_number: data,
//       };
//     });
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       phone_number: false,
//     }));
//   };
//   return (
//     <div className="relative w-full">
//       <PhoneInput
//         onFocus={(e) => {
//           e.stopPropagation();
//           e.preventDefault();
//         }}
//         onBlur={(e) => {
//           e.stopPropagation();
//           e.preventDefault();
//         }}
//         isValid={error}
//         international
//         value={value}
//         onChange={(number) => {
//           handleNumberChange(number);
//         }}
//         defaultCountry={defaultCountry || 'US'}
//         placeholder="Enter phone number"
//         countryCallingCodeEditable={false}
//         limitMaxLength={true}
//         className={`flex ${
//           isAuth
//             ? error
//               ? 'error-class-isAuth'
//               : 'not-error-isAuth'
//             : error
//             ? 'error-class'
//             : 'not-error'
//         }`}
//         countryLib={countryLib}
//         inputComponent={CustomInput}
//         countrySelectComponent={(props) => {
//           return (
//             <div
//               onClick={() => {
//                 setIsOpen(!isOpen);
//               }}
//               className={`flex cursor-pointer font-normal text-PrimaryT gap-[6px] pl-4 pr-3 border-t-[1px] border-b-[1px] border-l-[1px] items-center text-textSmall ${
//                 error ? 'border-[#FF0000]' : 'border-StrokePrimary'
//               } ${
//                 isAuth
//                   ? 'rounded-tl-[7px] rounded-bl-[7px] py-[8.5px]'
//                   : 'rounded-tl-[30px] rounded-bl-[30px] py-[10px]'
//               }`}
//             >
//               <img
//                 className="w-[26px] h-[28px]"
//                 src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryLib}.svg`}
//                 alt="flags"
//               />
//               <img
//                 className={`transform w-[14px] h-[14px] transition-transform duration-200 ${
//                   isOpen ? 'rotate-180' : ''
//                 }`}
//                 src={arrowIcon}
//                 alt="arrow icon"
//               />
//             </div>
//           );
//         }}
//         // error={
//         //   errMsg || value
//         //     ? isValidPhoneNumber(value)
//         //       ? undefined
//         //       : 'Invalid phone number'
//         //     : 'Phone number required'
//         // }
//       />
//       {/* COUNTRY SELECT COMPONENT */}
//       <div
//         style={{
//           display: !isOpen ? 'none' : 'block',
//           boxShadow: '0px 8px 40px 0px #19224C1A',
//         }}
//         ref={countryRef}
//         className="absolute z-30 w-full top-[55px] rounded-[10px] left-0 bg-white"
//       >
//         <div className="Phone relative rounded-tl-[10px] rounded-tr-[10px]">
//           <input
//             placeholder="Search for country"
//             className="border-b-StrokePrimary border-b-[1px] h-[40px] w-full pl-[40px] focus:outline-none placeholder:text-LightTextParagraph rounded-tl-[10px] rounded-tr-[10px] text-textSmall font-OutfitFont font-normal"
//             name="searchInput"
//             value={searchInput}
//             onChange={handleOnChangeSearch}
//             onKeyUp={(e) => e.stopPropagation()}
//             onKeyDown={(e) => e.stopPropagation()}
//           />
//           <div className="absolute top-[11px] left-4">
//             <img src={searchIcon} alt="search" className="" />
//           </div>
//         </div>
//         <div className="bg-white py-[8px] h-fit max-h-[288px] overflow-y-auto rounded-bl-[10px] rounded-br-[10px]">
//           {countries &&
//             countries.map((itm, index) => (
//               <div
//                 key={`country-${index}`}
//                 className={`relative flex cursor-pointer justify-between px-3 py-[6px] text-LightTextParagraph h-[32px] mb-2 hover:bg-LightBgSecondary text-[14px] font-OutfitFont font-medium hover:!text-LightPrimary`}
//                 onClick={() => {
//                   setIsOpen(false);
//                   handleCountrySelect(itm || undefined);
//                 }}
//               >
//                 <div className="flex gap-2 items-center hover:!text-LightPrimary">
//                   <img
//                     className="w-[28px]"
//                     src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${itm}.svg`}
//                     alt={index}
//                   />
//                   <div className="text-[12px] font-medium">
//                     {countryNames[itm]}
//                   </div>
//                 </div>
//                 <div className="text-[14px]">
//                   {'+'}
//                   {getCountryCallingCode(itm)}
//                 </div>
//               </div>
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SelectMobileNumber;

// export const CustomInput = React.forwardRef(
//   ({ value, countryLib, onChange, ...rest }, ref) => {
//     const newValue =
//       value === `+${getCountryCallingCode(countryLib)}` ? `${value} ` : value;

//     return (
//       <input
//         ref={ref}
//         value={newValue}
//         onChange={onChange}
//         {...rest}
//         style={{ paddingRight: '8px' }} // Add space here
//       />
//     );
//   }
// );
