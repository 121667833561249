export const ForCustomer = (props) => {
  return (
    <>
      <svg
        {...props}
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.5335 36.2334C40.2002 36.2 39.8002 36.2 39.4335 36.2334C31.5002 35.9667 25.2002 29.4667 25.2002 21.4667C25.2002 13.3 31.8002 6.66669 40.0002 6.66669C48.1669 6.66669 54.8002 13.3 54.8002 21.4667C54.7669 29.4667 48.4669 35.9667 40.5335 36.2334Z"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8664 48.5333C15.7997 53.9333 15.7997 62.7333 23.8664 68.1C33.0331 74.2333 48.0664 74.2333 57.2331 68.1C65.2997 62.7 65.2997 53.9 57.2331 48.5333C48.0997 42.4333 33.0664 42.4333 23.8664 48.5333Z"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const ForDevelopers = (props) => {
  return (
    <>
      <svg
        {...props}
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.999 36.0834C48.0824 36.0834 54.749 29.5 54.749 21.3334C54.749 13.25 48.0824 6.66669 39.999 6.66669C31.9157 6.66669 25.249 13.25 25.249 21.4167C25.249 29.5 31.9157 36.0834 39.999 36.0834ZM39.999 10C46.249 10 51.4157 15.0834 51.4157 21.4167C51.4157 27.6667 46.3324 32.8334 39.999 32.8334C33.749 32.8334 28.5824 27.75 28.5824 21.4167C28.5824 15.0834 33.749 10 39.999 10Z"
          fill="#484540"
        />
        <path
          d="M52.8327 36.9167C52.3327 36.9167 51.916 37.0834 51.5827 37.4167C48.4994 40.4167 44.3327 42.0834 39.9994 42.0834C35.666 42.0834 31.5827 40.4167 28.416 37.4167C28.0827 37.0834 27.5827 36.9167 27.166 36.9167C18.0827 37.5834 10.916 45.25 10.916 54.4167V62.5C10.916 68.5 15.7493 73.3334 21.7493 73.3334H58.166C64.166 73.3334 68.9994 68.5 68.9994 62.5V54.4167C69.0827 45.25 61.916 37.5834 52.8327 36.9167ZM65.7494 62.5C65.7494 66.6667 62.416 70 58.2494 70H21.7493C17.5827 70 14.2493 66.6667 14.2493 62.5V54.4167C14.2493 47.25 19.666 41.25 26.666 40.3334C30.3327 43.6667 34.9994 45.4167 39.9994 45.4167C44.9994 45.4167 49.666 43.5834 53.3327 40.3334C60.3327 41.1667 65.7494 47.25 65.7494 54.4167V62.5Z"
          fill="#484540"
        />
        <path
          d="M49.2493 52C48.5827 51.3333 47.5827 51.3333 46.916 52C46.2493 52.6667 46.2493 53.6667 46.916 54.3333L49.9993 57.5L46.916 60.5833C46.2493 61.25 46.2493 62.25 46.916 62.9167C47.2493 63.25 47.666 63.4167 48.0827 63.4167C48.4994 63.4167 48.916 63.25 49.2493 62.9167L53.4993 58.6667C54.166 58 54.166 57 53.4993 56.3333L49.2493 52Z"
          fill="#484540"
        />
        <path
          d="M43.1667 47.5833C42.25 47.3333 41.3333 47.8333 41.0833 48.75L36.4167 65.3333C36.1667 66.25 36.6667 67.1666 37.5833 67.4166C37.75 67.5 37.9167 67.5 38 67.5C38.75 67.5 39.4167 67 39.5833 66.25L44.25 49.6666C44.5 48.75 44 47.8333 43.1667 47.5833Z"
          fill="#484540"
        />
        <path
          d="M33.0824 52C32.4157 51.3333 31.4157 51.3333 30.749 52L26.499 56.25C25.8324 56.9167 25.8324 57.9167 26.499 58.5833L30.749 62.8333C31.0824 63.1667 31.499 63.3333 31.9157 63.3333C32.3324 63.3333 32.749 63.1667 33.0824 62.8333C33.749 62.1667 33.749 61.1667 33.0824 60.5L29.999 57.5L33.0824 54.4167C33.749 53.75 33.749 52.6667 33.0824 52Z"
          fill="#484540"
        />
      </svg>
    </>
  );
};
export const ForEveryone = (props) => {
  return (
    <>
      <svg
        {...props}
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M59.9996 23.8667C59.7996 23.8334 59.5663 23.8334 59.3663 23.8667C54.7663 23.7 51.0996 19.9334 51.0996 15.2667C51.0996 10.5 54.9329 6.66669 59.6996 6.66669C64.4663 6.66669 68.2996 10.5334 68.2996 15.2667C68.2663 19.9334 64.5996 23.7 59.9996 23.8667Z"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.5658 48.1334C61.1325 48.9001 66.1658 48.1001 69.6992 45.7334C74.3992 42.6001 74.3992 37.4667 69.6992 34.3334C66.1325 31.9667 61.0325 31.1667 56.4658 31.9667"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8996 23.8667C20.0996 23.8334 20.3329 23.8334 20.5329 23.8667C25.1329 23.7 28.7996 19.9334 28.7996 15.2667C28.7996 10.5 24.9663 6.66669 20.1996 6.66669C15.4329 6.66669 11.5996 10.5334 11.5996 15.2667C11.6329 19.9334 15.2996 23.7 19.8996 23.8667Z"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.3331 48.1334C18.7665 48.9001 13.7331 48.1001 10.1998 45.7334C5.4998 42.6001 5.4998 37.4667 10.1998 34.3334C13.7665 31.9667 18.8665 31.1667 23.4331 31.9667"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.0006 48.7667C39.8006 48.7333 39.5673 48.7333 39.3673 48.7667C34.7673 48.6 31.1006 44.8333 31.1006 40.1667C31.1006 35.4 34.9339 31.5667 39.7006 31.5667C44.4672 31.5667 48.3006 35.4333 48.3006 40.1667C48.2673 44.8333 44.6006 48.6333 40.0006 48.7667Z"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.3004 59.2666C25.6004 62.4 25.6004 67.5333 30.3004 70.6666C35.6337 74.2333 44.3671 74.2333 49.7004 70.6666C54.4004 67.5333 54.4004 62.4 49.7004 59.2666C44.4004 55.7333 35.6337 55.7333 30.3004 59.2666Z"
          stroke="#484540"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const InfoIcon = ({ color = '#484540' }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0003 1.66663C14.6028 1.66663 18.3337 5.39746 18.3337 9.99996C18.3337 14.6025 14.6028 18.3333 10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 9.99996C1.66699 5.39746 5.39783 1.66663 10.0003 1.66663ZM10.0003 13.3333C9.77931 13.3333 9.56735 13.4211 9.41107 13.5774C9.25479 13.7336 9.16699 13.9456 9.16699 14.1666C9.16699 14.3876 9.25479 14.5996 9.41107 14.7559C9.56735 14.9122 9.77931 15 10.0003 15C10.2213 15 10.4333 14.9122 10.5896 14.7559C10.7459 14.5996 10.8337 14.3876 10.8337 14.1666C10.8337 13.9456 10.7459 13.7336 10.5896 13.5774C10.4333 13.4211 10.2213 13.3333 10.0003 13.3333ZM10.0003 5.41663C9.19915 5.41663 8.43079 5.73489 7.86427 6.30141C7.29776 6.86792 6.97949 7.63628 6.97949 8.43746C6.97949 8.65847 7.06729 8.87043 7.22357 9.02671C7.37985 9.18299 7.59181 9.27079 7.81283 9.27079C8.03384 9.27079 8.2458 9.18299 8.40208 9.02671C8.55836 8.87043 8.64616 8.65847 8.64616 8.43746C8.64643 8.19166 8.71361 7.95056 8.84048 7.74003C8.96735 7.5295 9.14913 7.35747 9.36633 7.24239C9.58353 7.12731 9.82796 7.07352 10.0734 7.08678C10.3188 7.10005 10.5561 7.17987 10.7596 7.31769C10.9631 7.45551 11.1253 7.64612 11.2287 7.8691C11.3322 8.09208 11.373 8.33901 11.3468 8.58341C11.3206 8.82781 11.2283 9.06046 11.0799 9.25643C10.9315 9.4524 10.7326 9.60428 10.5045 9.69579C9.94116 9.92079 9.16699 10.4975 9.16699 11.4583V11.6666C9.16699 11.8876 9.25479 12.0996 9.41107 12.2559C9.56735 12.4122 9.77931 12.5 10.0003 12.5C10.2213 12.5 10.4333 12.4122 10.5896 12.2559C10.7459 12.0996 10.8337 11.8876 10.8337 11.6666C10.8337 11.4633 10.8753 11.3616 11.0512 11.275L11.1237 11.2416C11.7743 10.9799 12.3137 10.4999 12.6493 9.88406C12.9848 9.26819 13.0957 8.55478 12.9629 7.86611C12.8301 7.17745 12.4619 6.55643 11.9214 6.10952C11.3808 5.66261 10.7017 5.41765 10.0003 5.41663Z"
          fill={color}
        />
      </svg>
    </>
  );
};
