import React from 'react'
import { TickCirclePrimary } from '../../assets/Icons/securityIcons';

const FeatureComponent = ({id,title,data}) => {
  return (
    <div className="w-[508px] max_md:w-[594px] max_720:w-[100%] max_sm:w-[100%] flex flex-col gap-10 max_600:gap-5 ">
      <p className="Text50 font-normal text-[#ACACAC] max_600:text-text2Xl">
        {id}.
      </p>
      <h2 className="text40class font-semibold text-PrimaryT max_sm:max-w-[300px]">
        {title}
      </h2>

      <div className="grid grid-cols-2 max_600:grid-cols-1 gap-x-10 max_xl:gap-x-5 gap-y-6 max_md:gap-x-10 max_600:gap-y-5">
        {data?.map((features, index1) => (
          <div key={index1} className="flex gap-2">
            <div className="mt-[2px] max_sm:mt-0">
              <TickCirclePrimary style={{ width: 20, height: 20 }} />
            </div>
            <p className="Text20Normal">{features}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureComponent