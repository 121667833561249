import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  B2BServices,
  Collections,
  Ecommerce,
  Insurance,
  Nonprofits,
  OnDemand,
  SubscriptionServices,
  TelecomUtilities,
  TravelHosp,
} from '../../assets/Icons/solutionsIcon';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';
import { TickCirclePrimary } from '../../assets/Icons/securityIcons';
// import EcommerceImg from '../../assets/images/Solutions/Ecommerce.svg';
// import InsuranceImg from '../../assets/images/Solutions/Insurance.svg';
// import B2BServicesImg from '../../assets/images/Solutions/B2bService.svg';
// import CollectionsImg from '../../assets/images/Solutions/Collections.svg';
// import TelecomUtilitiesImg from '../../assets/images/Solutions/UtilitiesTele.svg';
// import TravelHospImg from '../../assets/images/Solutions/Travel&Hospitality.svg';
// import OnDemandImg from '../../assets/images/Solutions/Ondemand.svg';
// import SubscriptionServicesImg from '../../assets/images/Solutions/Subscription.svg';
// import NonprofitsImg from '../../assets/images/Solutions/NoneProfits.svg';

const SelectIndustry = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth <= 800;
  const isMobileView = windowWidth <= 500;
  const [selectIndustry, setSelectIndustry] = useState({
    industryList: [],
    allIndustry: [],
  });
  useEffect(() => {
    setSelectIndustry((prevData) => ({
      ...prevData,
      industryList: getTranslatedList(t, 'industry_list'),
      allIndustry: getTranslatedList(t, 'all_industry'),
    }));
  }, [t]);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 80;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <CommonSizeContainer>
        <h2 className="text40class text-center font-semibold text-PrimaryT">
          {t('SelectIndustry')}
        </h2>

        <div className="mt-[60px] max_md:mt-10 flex flex-wrap justify-between max_md:justify-start gap-10 max_xl:gap-x-5 max_md:gap-[30px] max_xl:gap-y-10 max_md:max-w-[700px] max_md:m-auto max_800:justify-center">
          {selectIndustry.industryList.map((solutions, index) => (
            <div
              className="flex items-center w-[350px] max_xl:w-[308px] max_md:w-[330px] max_800:w-[350px] gap-5 max_sm:w-[100%]"
              key={index}
            >
              <div>
                {React.cloneElement(solutions.icon, {
                  style: {
                    width: isTabView ? 50 : 60,
                    height: isTabView ? 50 : 60,
                  },
                })}
              </div>
              <div>
                <h3
                  className="Text24 font-semibold text-PrimaryT max_sm:text-textMedium border-b-[1.5px] w-max border-WebRootColor hover:border-PrimaryT cursor-pointer"
                  onClick={() => handleClickScroll(solutions.id)}
                >
                  {solutions.title}
                </h3>
                {/* <p className="Text20Normal mt-3">{solutions.text}</p> */}
              </div>
            </div>
          ))}
        </div>
      </CommonSizeContainer>

      {selectIndustry.allIndustry.map((list, index1) => (
        <React.Fragment key={index1}>
          <CommonSizeContainer
            isTopMargin
            isBottomMargin={index1 === 8 ? true : false}
            id={list.id}
          >
            <div
              className={`flex justify-between items-center gap-8 max_md:flex-col max_md:gap-[60px] max_sm:gap-10 ${
                index1 % 2 !== 0 && 'flex-row-reverse'
              }`}
            >
              <div className="max-w-[550px] max_xl:max-w-[480px] max_md:max-w-full  flex flex-col gap-10">
                <h2 className="Text50">{list.title}</h2>
                {list.data.map((item, index) => (
                  <div className="flex gap-2" key={index}>
                    <div className="mt-[3px]">
                      <TickCirclePrimary
                        style={{
                          width: isMobileView ? 20 : 28,
                          height: isMobileView ? 20 : 28,
                        }}
                      />
                    </div>
                    <p
                      className="Text24"
                      dangerouslySetInnerHTML={{ __html: item }}
                    ></p>
                  </div>
                ))}
              </div>
              <div>
                <img src={list.image} alt={list.title} />
              </div>
            </div>
          </CommonSizeContainer>
        </React.Fragment>
      ))}
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    industry_list: [
      {
        id: 'EcommerceId',
        title: t('Ecommerce'),
        text: t('EcommerceT'),
        icon: <Ecommerce />,
      },
      {
        id: 'InsuranceId',
        title: t('Insurance'),
        text: t('InsuranceTS'),
        icon: <Insurance />,
      },
      {
        id: 'B2BServicesId',
        title: t('B2BServices'),
        text: t('B2BServicesT'),
        icon: <B2BServices />,
      },
      {
        id: 'CollectionsId',
        title: t('Collections'),
        text: t('CollectionsT'),
        icon: <Collections />,
      },
      {
        id: 'TelecomUtilitiesId',
        title: t('TelecomUtilities'),
        text: t('TelecomUtilitiesT'),
        icon: <TelecomUtilities />,
      },
      {
        id: 'TravelHospId',
        title: t('TravelHosp'),
        text: t('TravelHospT'),
        icon: <TravelHosp />,
      },
      {
        id: 'OnDemandId',
        title: t('OnDemand'),
        text: t('OnDemandT'),
        icon: <OnDemand />,
      },
      {
        id: 'SubscriptionServicesId',
        title: t('SubscriptionServices'),
        text: t('SubscriptionServicesT'),
        icon: <SubscriptionServices />,
      },
      {
        id: 'NonprofitsId',
        title: t('Nonprofits'),
        text: t('NonprofitsT'),
        icon: <Nonprofits />,
      },
    ],
    all_industry: [
      {
        id: 'EcommerceId',
        title: t('Ecommerce'),
        data: [t('Ecommerce1'), t('Ecommerce2'), t('Ecommerce3')],
        image: 'https://images.dcdial.com/avnovo.com/Ecommerce.svg',
      },
      {
        id: 'SubscriptionServicesId',
        title: t('SubscriptionServices'),
        data: [
          t('SubscriptionSer1'),
          t('SubscriptionSer2'),
          t('SubscriptionSer3'),
        ],
        image: 'https://images.dcdial.com/avnovo.com/Subscription.svg',
      },
      {
        id: 'OnDemandId',
        title: t('OnDemand'),
        data: [t('OnDemand1'), t('OnDemand2'), t('OnDemand3')],
        image: 'https://images.dcdial.com/avnovo.com/Ondemand.svg',
      },
      {
        id: 'TravelHospId',
        title: t('TravelHosp'),
        data: [t('TravelHosp1'), t('TravelHosp2'), t('TravelHosp3')],
        image: 'https://images.dcdial.com/avnovo.com/Travel&Hospitality.svg',
      },
      {
        id: 'InsuranceId',
        title: t('Insurance'),
        data: [t('Insurance1'), t('Insurance2'), t('Insurance3')],
        image: 'https://images.dcdial.com/avnovo.com/Insurance.svg',
      },
      {
        id: 'B2BServicesId',
        title: t('B2BServices'),
        data: [t('B2BServices1'), t('B2BServices2'), t('B2BServices3')],
        image: 'https://images.dcdial.com/avnovo.com/B2bService.svg',
      },
      {
        id: 'CollectionsId',
        title: t('Collections'),
        data: [t('Collections1'), t('Collections2'), t('Collections3')],
        image: 'https://images.dcdial.com/avnovo.com/Collections.svg',
      },
      {
        id: 'NonprofitsId',
        title: t('Nonprofits'),
        data: [t('Nonprofits1'), t('Nonprofits2'), t('Nonprofits3')],
        image: 'https://images.dcdial.com/avnovo.com/NoneProfits.svg',
      },
      {
        id: 'TelecomUtilitiesId',
        title: t('TelecomUtilities'),
        data: [
          t('TelecomUtilities1'),
          t('TelecomUtilities2'),
          t('TelecomUtilities3'),
        ],
        image: 'https://images.dcdial.com/avnovo.com/UtilitiesTele.svg',
      },
    ],
  };

  return lists[listKey];
};

export default SelectIndustry;
