import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommonAlphabeticComp from '../CommonAlphabeticComp';
import TitleWithText from '../TitleWithText';
import BulletPoints from '../BulletPoints';

const DeveloperTermsContent = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [termsData, setTermsData] = useState({
    Definitions: [],
    RegisterTool: [],
    DeveloperTools: [],
    rightsPoints: [],
    rightsList: [],
    BrandingPublicity: [],
    Disclaimers: [],
    General: [],
  });
  useEffect(() => {
    setTermsData((prevData) => ({
      ...prevData,
      Definitions: getTranslatedList(t, 'definitions'),
      RegisterTool: getTranslatedList(t, 'register_tool'),
      DeveloperTools: getTranslatedList(t, 'developer_tool'),
      rightsPoints: getTranslatedList(t, 'rights_points'),
      rightsList: getTranslatedList(t, 'rights_list'),
      BrandingPublicity: getTranslatedList(t, 'branding'),
      Disclaimers: getTranslatedList(t, 'disclaimers'),
      General: getTranslatedList(t, 'general'),
    }));
  }, [t]);
  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('https://')) {
        window.open(element.dataset.url, '_blank');
      } else {
        navigate(element.dataset.url, { state: 'send' });
        localStorage.setItem('scrollPositionDT', window.scrollY);
      }
    }
  };
  return (
    <div className="">
      <p className="text40class text-PrimaryT">{t('DeveloperTermsOnly')}</p>
      <p className="mt-5 paragraphText text-TertiaryT">{t('LastModifyDate')}</p>
      <p className="paragraphText text-SecondaryT text-[18px] font-semibold mt-10">
        {t('PleaseReadTerms')}
      </p>
      <div className="mt-[30px] flex flex-col gap-4">
        <p
          className="smallText16Normal"
          dangerouslySetInnerHTML={{ __html: t('DevTermT1') }}
          onClick={handleOnClick}
        ></p>
        <p
          className="smallText16Normal"
          dangerouslySetInnerHTML={{ __html: t('DevTermT2') }}
          onClick={handleOnClick}
        ></p>
        <p
          className="smallText16Normal"
          dangerouslySetInnerHTML={{ __html: t('DevTermT3') }}
          onClick={handleOnClick}
        ></p>
      </div>

      <CommonAlphabeticComp
        title={t('Definitions')}
        count={1}
        data={termsData.Definitions}
        isBulletText
        pl="24px"
        isBackground="#666666"
      />
      <CommonAlphabeticComp
        title={t('RegisteringDeveloperTools')}
        count={2}
        data={termsData.RegisterTool}
        isBulletText
        pl="24px"
      />
      <CommonAlphabeticComp
        title={t('UseDeveloperTools')}
        count={3}
        data={termsData.DeveloperTools}
        pl="24px"
        isBackground="#666666"
      />

      <TitleWithText title={t('AVRights')} count={4} text={[t('AVRightsText')]}>
        <div className="mt-4 flex flex-col gap-4">
          {termsData.rightsPoints.map((list, index4) => (
            <BulletPoints data={list} key={index4} pl="24px" />
          ))}
          <p className="smallText16Normal">{t('RightsText')}</p>
          {termsData.rightsList.map((list1, index5) => (
            <BulletPoints
              data={list1.name}
              key={index5}
              id={list1.id}
              isRoman
              pl="24px"
            />
          ))}
        </div>
      </TitleWithText>

      <CommonAlphabeticComp
        title={t('BrandingPublicityFeedback')}
        count={5}
        data={termsData.BrandingPublicity}
        pl="24px"
        isBackground="#666666"
      />

      <TitleWithText
        title={t('TermTermination')}
        count={6}
        text={[t('TermTerminationT1')]}
      >
        <p className="smallText16Normal mt-4">{t('TermTerminationT2')}</p>
      </TitleWithText>

      <CommonAlphabeticComp
        title={t('DisclaimersLimitationsLiability')}
        count={7}
        data={termsData.Disclaimers}
        pl="24px"
        isBackground="#666666"
      />

      <TitleWithText
        title={t('Indemnification')}
        count={8}
        text={[t('IndemnificationT1')]}
      ></TitleWithText>

      <CommonAlphabeticComp
        title={t('General')}
        count={9}
        data={termsData.General}
        pl="24px"
        isBackground="#666666"
      />
    </div>
  );
};
const getTranslatedList = (t, key) => {
  const lists = {
    definitions: [
      {
        name: t('TermDef_1'),
      },
      {
        name: t('TermDef_2'),
      },
      {
        name: t('TermDef_3'),
      },
      {
        name: t('TermDef_4'),
      },
      {
        name: t('TermDef_5'),
      },
      {
        name: t('TermDef_6'),
      },
      {
        name: t('TermDef_7'),
      },
      {
        name: t('TermDef_8'),
      },
      {
        name: t('TermDef_9'),
      },
      {
        name: t('TermDef_10'),
      },
      {
        name: t('TermDef_11'),
      },
    ],
    register_tool: [
      {
        name: t('Register_1'),
      },
      {
        name: t('Register_2'),
      },
      {
        name: t('Register_3'),
      },
      {
        name: t('Register_4'),
      },
    ],
    developer_tool: [
      {
        id: 'a',
        name: t('devTool_a'),
      },
      {
        id: 'b',
        name: t('devTool_b'),
      },
      {
        id: 'c',
        name: t('devTool_c'),
      },
      {
        id: 'd',
        name: t('devTool_d'),
      },
      {
        id: 'e',
        name: t('devTool_e'),
      },
      {
        id: 'f',
        name: t('devTool_f'),
      },
      {
        id: 'g',
        name: t('devTool_g'),
      },
      {
        id: 'h',
        name: t('devTool_h'),
      },
      {
        id: 'i',
        name: t('devTool_i'),
      },
      {
        id: 'j',
        name: t('devTool_j'),
      },
      {
        id: 'k',
        name: t('devTool_k'),
      },
      {
        id: 'l',
        name: t('devTool_l'),
      },
    ],
    rights_points: [
      t('rights_1'),
      t('rights_2'),
      t('rights_3'),
      t('rights_4'),
      t('rights_5'),
      t('rights_6'),
    ],
    rights_list: [
      {
        id: 'a',
        name: t('rights_list_1'),
      },
      {
        id: 'b',
        name: t('rights_list_2'),
      },
      {
        id: 'c',
        name: t('rights_list_3'),
      },
    ],
    branding: [
      {
        id: 'a',
        name: t('Brand_1'),
      },
      {
        id: 'b',
        name: t('Brand_2'),
      },
    ],
    disclaimers: [
      {
        id: 'a',
        name: t('Desclaimer_a'),
      },
      {
        id: 'b',
        name: t('Desclaimer_b'),
      },
      {
        id: 'c',
        name: t('Desclaimer_c'),
      },
      {
        id: 'd',
        name: t('Desclaimer_d'),
      },
    ],
    general: [
      {
        id: 'a',
        name: t('General_a'),
      },
      {
        id: 'b',
        name: t('General_b'),
      },
      {
        id: 'c',
        name: t('General_c'),
      },
      {
        id: 'd',
        name: t('General_d'),
      },
      {
        id: 'e',
        name: t('General_e'),
      },
      {
        id: 'f',
        name: t('General_f'),
      },
      {
        id: 'g',
        name: t('General_g'),
      },
      {
        id: 'h',
        name: t('General_h'),
      },
      {
        id: 'i',
        name: t('General_i'),
      },
      {
        id: 'j',
        name: t('General_j'),
      },
      {
        id: 'k',
        name: t('General_k'),
      },
      {
        id: 'l',
        name: t('General_l'),
      },
    ],
  };

  return lists[key];
};

export default DeveloperTermsContent;
