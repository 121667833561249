import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import TermsOfServiceContent from '../../Components/Legal/LegalPages/TermsOfServiceContent';
import { useLocation } from 'react-router-dom';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const TermesOfService = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionT');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Customer Terms of Service"
        description="By using Avnovo's services, you agree to our Customer Terms of Service, ensuring a fair and transparent relationship between you and Avnovo."
      />
      <LegalSideLayout>
        <TermsOfServiceContent />
      </LegalSideLayout>
    </>
  );
};

export default TermesOfService;
