import React, { useEffect, useState } from 'react';
import CompanyInfo from '../Components/Homepage/CompanyInfo';
import KeyFeature from '../Components/Homepage/KeyFeature';
import LevelUp from '../Components/Homepage/LevelUp';
import HeroComponent from '../Components/Homepage/HeroComponent';
import ProductCom from '../Components/Homepage/ProductCom';
import FAQComponent from '../Components/Common/FAQComponent';
import { useTranslation } from 'react-i18next';
import ContinueWithAV from '../Components/Pricing/ContinueWithAv';
import { useNavigate } from 'react-router-dom';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const HomePage = ({ isImageLoaded, setImageLoaded }) => {
  const { t } = useTranslation();
  const [FAQList] = useState([
    {
      question: t('Av_Ques1'),
      answer: t('Av_Ans1'),
    },
    {
      question: t('Av_Ques2'),
      answer: t('Av_Ans2'),
    },
    {
      question: t('Av_Ques3'),
      answer: t('Av_Ans3'),
    },
    {
      question: t('Av_Ques4'),
      answer: t('Av_Ans4'),
    },
    {
      question: t('Av_Ques5'),
      answer: t('Av_Ans5'),
    },
  ]);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('scrollPositionT');
    localStorage.removeItem('scrollPositionS');
    localStorage.removeItem('scrollPositionDPA');
    localStorage.removeItem('scrollPositionA');
    localStorage.removeItem('scrollPositionD');
    localStorage.removeItem('scrollPositionDT');
    localStorage.removeItem('scrollPositionWT');
    localStorage.removeItem('scrollPositionWA');
    localStorage.removeItem('scrollPositionPP');
    localStorage.removeItem('scrollPositionCP');
    localStorage.removeItem('scrollPositionSD');
    localStorage.removeItem('appsCount');
  }, []);

  return (
    <>
      {/* <div className="FLEX_CENTER text-subHeadingW text-center font-OutfitFont h-[900px]">
        {t('HomePage')}
      </div> */}
      <TitleAndDesc
        title="Avnovo | Billing Automation & Payment Processing"
        description="Avnovo is a comprehensive suite of payment processing and automation tools designed to empower businesses of all sizes. Explore our solutions to see how Avnovo can drive your business forward."
        isImageURL="https://images.dcdial.com/avnovo.com/PaymentSolutions.svg"
        isPageURL="https://avnovo.com/"
      />
      <HeroComponent
        isImageLoaded={isImageLoaded}
        setImageLoaded={setImageLoaded}
      />
      <CompanyInfo />
      <KeyFeature />
      <LevelUp />
      <ProductCom />
      <FAQComponent isMargin={true} data={FAQList} />
      <ContinueWithAV
        title={t('GetStartedWithAv')}
        subTitle1={t('GetStartedWithAvT')}
        BtnText1={t('Signup')}
        BtnClick1={() => navigate('/contact/get-in-touch')}
      />
    </>
  );
};

export default HomePage;
