import React, { useEffect, useState } from 'react';
import CommonProductComp from '../Components/Products/CommonProductComp';
import { useTranslation } from 'react-i18next';
import PaymentLink from '../Components/Products/Payments/PaymentLink';
import InvoiceAndQuotes from '../Components/Products/Payments/InvoiceAndQuotes';
import BgCenterComp from '../Components/Products/BgCenterComp';
import { useWindowSize } from '../utils/Hooks/useWindowSize';
import {
  Consistency,
  ConvenienceFees,
  Customization,
  CustomizeBrandingIcon,
  Efficiency,
  PayFlexibilityIcon,
  SelfServiceIcon,
} from '../assets/Icons/productsIcons';
import BudgetCalc from '../Components/Products/Payments/BudgetCalc';
import RevenueOptimize from '../Components/Products/Payments/RevenueOptimize';
import Img1 from '../assets/images/Products/Payments/Predictable.svg';
import Img2 from '../assets/images/Products/Payments/CashFlow.svg';
import Img3 from '../assets/images/Products/Payments/TimeSaving.svg';
import ProductCardContainer from '../Components/Products/ProductCardContainer';
import TitleDescWithKeyword from '../Components/HelmetComp/TitleDescWithKeyword';
// import Dashboard from '../assets/images/Products/Payments/Dashboard.svg';
// import DashboardRes from '../assets/images/Products/Payments/DashboardRes.svg';

const PaymentPage = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 980;
  const [customerPortalData, setCustomerPortalData] = useState([]);
  const [customizeCRMData] = useState([
    {
      image: Img1,
      title: t('PredicStreams'),
      text: t('PredicStreamsT'),
    },
    {
      image: Img2,
      title: t('CashFlow'),
      text: t('CashFlowT'),
    },
    {
      image: Img3,
      title: t('TimeSavings'),
      text: t('TimeSavingsT'),
    },
  ]);
  const [virtualColData, setVirtualColData] = useState([]);
  useEffect(() => {
    setCustomerPortalData(getTranslatedList(t, 'customer_data'));
    setVirtualColData(getTranslatedList(t, 'virtual_data'));
  }, [t]);
  const [data] = useState([
    {
      value: '9X',
      text: 'faster revenue growth rate was experienced by subscription-based companies compared to the S&P 500.',
      progress: '67%',
    },
    {
      value: '200%',
      text: 'annual growth rate was reported by subscription-based businesses over the past five years.',
      progress: '37%',
    },
    {
      value: '30-300%',
      text: 'increase in CLV was witnessed by companies implementing subscription models.',
      progress: '48%',
    },
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="Payments | Avnovo"
        description="Automate billing with Avnovo. Set up recurring payments, create subscriptions, and generate secure payment links. "
        keywords="Payment process optimization, Subscription management, Recurring payments, Predictable revenue streams, Payment links generation, Automated billing solutions, Customer payment portal, Invoice and quote creation, Debt collection automation, Virtual Collector tool, Payment collection campaigns, Enhanced cash flow, Automated payment reminders, Flexible payment methods, Secure payment portal, Branded payment interfaces, Personalized payment plans, Financial stability with recurring billing, Improved cash flow management, Self-service payment capabilities, Custom settlement offers, Debt repayment plans, Accounts receivable efficiency, Voice broadcast payment reminders, Email and SMS payment campaigns, Payment IVR systems, Customer payment flexibility, Seamless payment processing, Subscription service growth potential, Customized branding for payments"
        isPageURL="https://avnovo.com/products/payments"
      />
      <div>
        <CommonProductComp
          title={t('Payments')}
          text={t('PaymentPageT')}
          data={data}
          dataTitle={t('SubscriptionSer')}
          dataText={t('SubscriptionSerT')}
          id="SubscriptionId"
        />

        <PaymentLink />
        <InvoiceAndQuotes />
        <ProductCardContainer
          allData={customizeCRMData}
          title={t('PaymentPlans')}
          text={t('PaymentPlansT')}
          id="RecurringBilling"
        />
        <BgCenterComp
          title={t('CustomerPortalU')}
          text={t('CustomerPortalT')}
          tagText={t('Tools')}
          background="#FFFFFF"
          data={customerPortalData}
          id="CustomerPortal"
        >
          <div className="flex justify-center mt-[60px] max_md:mt-0 w-full ">
            <img
              src={
                isMobileView
                  ? 'https://images.dcdial.com/avnovo.com/DashboardRes.svg'
                  : 'https://images.dcdial.com/avnovo.com/Dashboard.svg'
              }
              alt="OutboundSec"
              className="mb-[-63px] max_xl:mb-[-50px] max_md:mb-0"
            />
          </div>
        </BgCenterComp>

        <BudgetCalc />

        <BgCenterComp
          title={t('VirtualCollectorU')}
          text={t('VirtualCollectorText')}
          tagText={t('Features')}
          background="#FFFFFF"
          data={virtualColData}
          id="VirtualCollectorId"
        >
          <div className="flex justify-center mt-0 max_md:mt-[-50px] w-full">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/VirtualCollector.svg"
              alt="OutboundSec"
              className="mb-[-48px] max_xl:mb-[-38px] max_md:mb-0"
            />
          </div>
        </BgCenterComp>

        <RevenueOptimize />
      </div>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    customer_data: [
      {
        name: t('PaymentFlexibility'),
        text: t('PaymentFlexT'),
        icon: <PayFlexibilityIcon />,
      },
      {
        name: t('SelfCapabilities'),
        text: t('SelfCapabilitiesT'),
        icon: <SelfServiceIcon />,
      },
      {
        name: t('CustomizedBranding'),
        text: t('CustomizedBrandingT'),
        icon: <CustomizeBrandingIcon />,
      },
      {
        name: t('ConvenienceF'),
        text: t('ConvenienceFT'),
        icon: <ConvenienceFees />,
      },
    ],
    virtual_data: [
      {
        name: t('Efficiency'),
        text: t('EfficiencyText'),
        icon: <Efficiency />,
      },
      {
        name: t('Consistency'),
        text: t('ConsistencyT'),
        icon: <Consistency />,
      },
      {
        name: t('Customization'),
        text: t('CustomizationText'),
        icon: <Customization />,
      },
    ],
  };

  return lists[listKey];
};

export default PaymentPage;
