import React from 'react';

const BgAnimatedLayer = () => {
  return (
    <>
      <div className="relative w-full h-full">
        <div className="absolute top-[160px] left-[160px] max_sm:left-[10%] max_sm:top-[120px]">
          <FilledTriangleMD className="animate-round-half-top" />
        </div>
        <div className="absolute top-[40px] right-[500px] max_md:right-[300px] max_sm:right-[22%] max_sm:top-[48px]">
          <FilledTriangleLG className="animate-round-half-top" />
        </div>
        <div className="absolute top-[80px] left-[350px] max_sm:left-[25%]">
          <FourCornerShap className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute top-[300px] left-[120px] max_sm:left-[11%] max_sm:top-[224px]">
          <DownArrowShape className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute bottom-[30px] left-[450px] max_md:left-[220px] max_sm:left-[20%] max_sm:bottom-[20px]">
          <TriangleLG className="animate-round-full-top" />
        </div>
        <div className="absolute bottom-[130px] right-[340px] max_md:right-[196px] max_sm:right-[18%]">
          <TriangleMD className="animate-round-full-top" />
        </div>
        <div className="absolute top-[210px] right-[460px] max_md:right-[266px] max_sm:right-[18%]">
          <StarShape className="animate-round-full-top" />
        </div>
        <div className="absolute top-[110px] right-[180px] max_sm:right-[8%]">
          <UPArrowShape className="animate-vertical-bottom-up" />
        </div>
      </div>
    </>
  );
};
export default BgAnimatedLayer;
const FilledTriangleMD = (props) => (
  <svg
    {...props}
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.15227 26.6861L25.0799 11.2919L0.774902 0L3.15227 26.6861Z"
      fill="#666666"
    />
  </svg>
);
const FilledTriangleLG = (props) => (
  <svg
    {...props}
    width="51"
    height="54"
    viewBox="0 0 51 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_270_4539)">
      <path
        d="M10.1002 35.209L50.7647 36.5771L31.605 0.668945L10.1002 35.209Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clip-path id="clip0_270_4539">
        <rect
          width="40.7"
          height="35.2328"
          fill="white"
          transform="translate(31.605 0.668945) rotate(61.9167)"
        />
      </clip-path>
    </defs>
  </svg>
);
const FourCornerShap = (props) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.70173 3.58929C2.71699 0.733464 6.27679 -0.562197 8.12417 1.61569C8.70498 2.3004 9.55594 2.69721 10.4538 2.70201C13.3096 2.71727 14.6053 6.27707 12.4274 8.12445C11.7427 8.70526 11.3459 9.55622 11.3411 10.4541C11.3258 13.3099 7.76603 14.6056 5.91865 12.4277C5.33784 11.743 4.48688 11.3462 3.58901 11.3414C0.733186 11.3261 -0.562474 7.76631 1.61541 5.91892C2.30013 5.33811 2.69694 4.48715 2.70173 3.58929Z"
      fill="#666666"
    />
  </svg>
);
const UPArrowShape = (props) => (
  <svg
    {...props}
    width="31"
    height="25"
    viewBox="0 0 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4316 11.4218L28.8222 11.6704L29.1 11.3L29.4 10.9L29.7238 10.4683L29.2686 10.1783L15.7686 1.5783L15.5 1.40716L15.2314 1.5783L1.73136 10.1783L1.35122 10.4205L1.55279 10.8236L1.75279 11.2236L1.99987 11.7178L2.467 11.4227L15.499 3.19201L28.4316 11.4218Z"
      fill="#666666"
      stroke="#666666"
    />
    <path
      d="M1.73136 15.9781L1.35122 16.2203L1.55279 16.6234L1.75279 17.0234L2.00121 17.5202L2.46926 17.2211L15.499 8.89385L28.4293 17.2202L28.8207 17.4722L29.1 17.0998L29.4 16.6998L29.7238 16.2681L29.2686 15.9781L15.7686 7.3781L15.5 7.20697L15.2314 7.3781L1.73136 15.9781Z"
      fill="#666666"
      stroke="#666666"
    />
    <path
      d="M1.72915 21.7793L1.35228 22.0222L1.55279 22.4232L1.75279 22.8232L2.00121 23.32L2.46926 23.0209L15.499 14.6937L28.4293 23.02L28.8207 23.272L29.1 22.8996L29.4 22.4996L29.7221 22.0701L29.2709 21.7793L15.7709 13.0793L15.5 12.9048L15.2291 13.0793L1.72915 21.7793Z"
      fill="#666666"
      stroke="#666666"
    />
  </svg>
);
const DownArrowShape = (props) => (
  <svg
    {...props}
    width="31"
    height="24"
    viewBox="0 0 31 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.56844 13.1778L2.17781 12.9292L1.9 13.2996L1.6 13.6996L1.27619 14.1314L1.73136 14.4213L15.2314 23.0213L15.5 23.1924L15.7686 23.0213L29.2686 14.4213L29.6488 14.1791L29.4472 13.776L29.2472 13.376L29.0001 12.8818L28.533 13.1769L15.501 21.4076L2.56844 13.1778Z"
      fill="#666666"
      stroke="#666666"
    />
    <path
      d="M29.2686 8.62151L29.6488 8.37934L29.4472 7.9762L29.2472 7.5762L28.9988 7.07936L28.5307 7.3785L15.501 15.7058L2.5707 7.37942L2.17931 7.12739L1.9 7.4998L1.6 7.8998L1.27619 8.33155L1.73136 8.62151L15.2314 17.2215L15.5 17.3926L15.7686 17.2215L29.2686 8.62151Z"
      fill="#666666"
      stroke="#666666"
    />
    <path
      d="M29.2709 2.82029L29.6477 2.57741L29.4472 2.17639L29.2472 1.77639L28.9988 1.27956L28.5307 1.57869L15.501 9.90596L2.5707 1.57962L2.17931 1.32759L1.9 1.7L1.6 2.1L1.27789 2.52948L1.72915 2.82029L15.2291 11.5203L15.5 11.6948L15.7709 11.5203L29.2709 2.82029Z"
      fill="#666666"
      stroke="#666666"
    />
  </svg>
);
const TriangleLG = (props) => (
  <svg
    {...props}
    width="80"
    height="77"
    viewBox="0 0 80 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_270_4532)">
      <path
        d="M32.9092 51.7796L31.3615 10.0769L67.748 28.9294L32.9092 51.7796ZM27.3511 62.5071L79.6847 28.3104L25.0439 0L27.3511 62.5071Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clip-path id="clip0_270_4532">
        <rect
          width="61.5394"
          height="54.4387"
          fill="white"
          transform="translate(25.0439) rotate(27.3895)"
        />
      </clip-path>
    </defs>
  </svg>
);
const TriangleMD = (props) => (
  <svg
    {...props}
    width="34"
    height="33"
    viewBox="0 0 34 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_270_4528)">
      <path
        d="M14.0706 22.1852L13.4167 4.56601L28.7898 12.5311L14.0706 22.1852ZM11.7223 26.7174L33.8329 12.2696L10.7476 0.308594L11.7223 26.7174Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clip-path id="clip0_270_4528">
        <rect
          width="26"
          height="23"
          fill="white"
          transform="translate(10.7476 0.308594) rotate(27.3895)"
        />
      </clip-path>
    </defs>
  </svg>
);
const StarShape = (props) => (
  <svg
    {...props}
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.22955 1.98771C5.94419 -0.211722 9.05581 -0.211722 9.77045 1.98771C10.09 2.97133 11.0067 3.63729 12.0409 3.63729C14.3535 3.63729 15.3151 6.59661 13.4441 7.95593C12.6074 8.56384 12.2573 9.64138 12.5769 10.625C13.2915 12.8244 10.7742 14.6534 8.90321 13.2941C8.0665 12.6862 6.9335 12.6862 6.09679 13.2941C4.22584 14.6534 1.70849 12.8244 2.42313 10.625C2.74273 9.64138 2.39261 8.56384 1.5559 7.95593C-0.315054 6.59661 0.646487 3.63729 2.95911 3.63729C3.99335 3.63729 4.90996 2.97133 5.22955 1.98771Z"
      fill="#666666"
    />
  </svg>
);
