import React from 'react';

const TitleWithText = ({ children, title, count, text, id }) => {
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">
        {count}. <span className="pl-1">{title}</span>
      </p>

      {text && (
        <div className="flex flex-col mt-5 gap-4">
          {text.map((textT, index1) => (
            <p className="smallText16Normal" key={index1}>
              {textT}
            </p>
          ))}
        </div>
      )}
      {children}
    </div>
  );
};

export default TitleWithText;
