import React from 'react';
import { useTranslation } from 'react-i18next';
import Allfeature from './Allfeature';
import FlexiblePayment from './FlexiblePayment';

const KeyFeature = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto pt-[100px] max_md:pt-10 max_sm:pt-[50px] max_md:px-10 max_sm:px-5">
        <div className="flex flex-col items-center">
          <h2
            className="headingText text-center max-w-[920px] max_md:max-w-[524px] max_sm:max-w-[100%]"
            dangerouslySetInnerHTML={{ __html: t('Keyfeatures') }}
          ></h2>
          <p className="Text24 mt-10 max_sm:mt-5 font-normal text-center w-[88%] max_md:w-full">
            {t('KeyfeaturesT')}
          </p>
        </div>
      </div>
      <Allfeature />
      <FlexiblePayment />
    </>
  );
};

export default KeyFeature;
