import React from 'react'
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import { useTranslation } from 'react-i18next';

const BudgetCalc = () => {
  const {t} = useTranslation()
  return (
    <CommonSizeContainer>
      <div
        className="flex flex-col gap-6 max_md:gap-4 pt-[140px] max_720:pt-[60px]"
        id="BudgetCalculatorId"
      >
        <p className="Text20Normal font-medium text-DisableText">
          {t('BudgetCalculator')}
        </p>
        <h2 className="Text50 max_md:mt-[14px] max_sm:mt-0">
          {t('BudgetCalcT')}
        </h2>
        <p className="Text24">{t('BudgetCalcText')}</p>
        <div className="mt-4 max_md:mt-9 max_sm:mt-4 flex max_md:flex-col gap-6 justify-center p-5 bg-LightBgSecondary max_md:p-10 max_sm:p-5">
          <div>
            <img
              src="https://images.dcdial.com/dcdial.com/v2/BudgetCalculator.svg"
              alt="BudgetCalculator"
            />
          </div>
          <div className="flex flex-col gap-6">
            <div>
              <img
                src="https://images.dcdial.com/dcdial.com/v2/MonthlyIncome.svg"
                alt="MonthlyIncome"
              />
            </div>
            <div>
              <img
                src="https://images.dcdial.com/dcdial.com/v2/MonthlyExpense.svg"
                alt="MonthlyExpense"
              />
            </div>
          </div>
        </div>
      </div>
    </CommonSizeContainer>
  );
}

export default BudgetCalc