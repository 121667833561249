import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProvisionsEuropean = ({ title, count, data, id }) => {
  const { t } = useTranslation();
  const [dataTransfer, setDataTransfer] = useState([]);
  useEffect(() => {
    setDataTransfer(getTranslatedList(t));
  }, [t]);
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">
        {count}. <span className="pl-1">{title}</span>
      </p>

      <div className="mt-4 flex flex-col gap-4">
        {data.map((list, index) => (
          <div className="flex gap-1" key={index}>
            <p className="w-5 smallText16Normal text-SecondaryT font-medium">
              {list.id}.
            </p>
            <div
              className="w-[calc(100%-24px)] smallText16Normal"
              dangerouslySetInnerHTML={{ __html: list.name }}
            ></div>
          </div>
        ))}
        <div className="flex gap-2 pl-6">
          <p className="w-[6px] h-[6px] bg-SecondaryT rounded-full mt-2"></p>
          <p
            className="smallText16Normal w-[calc(100%-18px)]"
            dangerouslySetInnerHTML={{ __html: t('E_Sub_T1') }}
          ></p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 pl-6">
            <p className="w-[6px] h-[6px] bg-SecondaryT rounded-full mt-2"></p>
            <p
              className="smallText16Normal w-[calc(100%-18px)]"
              dangerouslySetInnerHTML={{ __html: t('E_Sub_T2') }}
            ></p>
          </div>
          {dataTransfer.map((list, index5) => (
            <React.Fragment key={index5}>
              <div className="flex gap-1 pl-10">
                <p className="smallText16Normal text-SecondaryT font-medium">
                  {index5 + 1}.
                </p>
                <p
                  className="smallText16Normal"
                  dangerouslySetInnerHTML={{ __html: list.text }}
                ></p>
              </div>
              {list.subText && (
                <>
                  {list.subText.map((subList, index6) => (
                    <div className="flex gap-2 pl-[58px]" key={index6}>
                      <p className="w-[6px] h-[6px] bg-TertiaryT rounded-full mt-2"></p>
                      <p
                        className="smallText16Normal w-[calc(100%-14px)]"
                        dangerouslySetInnerHTML={{ __html: subList }}
                      ></p>
                    </div>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  const lists = [
    {
      text: t('E_Sub_T2_1'),
    },
    {
      text: t('E_Sub_T2_2'),
      subText: [
        t('E_Sub_T2_In_1'),
        t('E_Sub_T2_In_2'),
        t('E_Sub_T2_In_3'),
        t('E_Sub_T2_In_4'),
        t('E_Sub_T2_In_5'),
      ],
    },
    {
      text: t('E_Sub_T2_3'),
    },
  ];
  return lists;
};
export default ProvisionsEuropean;
