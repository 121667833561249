import React, { useState } from 'react';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import { ButtonWithArrowIcon } from '../../utils/Button';
import PaymentF from '../../assets/images/Products/PaymentF.svg';
import CRMF from '../../assets/images/Products/CRMF.svg';
import CommunicationF from '../../assets/images/Products/CommunicationF.svg';
import AIAssitantF from '../../assets/images/Products/AIAssitantF.svg';
import { useNavigate } from 'react-router-dom';

const ProductCom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [productDetails] = useState([
    {
      title: t('Payments'),
      text: t('PaymentT'),
      id: 'payId',
      image: PaymentF,
      link: '/products/payments',
    },
    {
      title: t('CRM'),
      text: t('CRMT'),
      id: 'crmId',
      image: CRMF,
      link: '/products/crm',
    },
    {
      title: t('Communication'),
      text: t('CommunicationT'),
      id: 'communicationId',
      image: CommunicationF,
      link: '/products/communication',
    },
    {
      title: t('AIAssistant'),
      text: t('AIAssistantText'),
      id: 'aiId',
      image: AIAssitantF,
      link: '/products/ai-assistant',
    },
  ]);
  const handleClick = () => {
    navigate('/products');
    window.scrollTo(0, 0);
  };
  return (
    <CommonSizeContainer isTopMargin>
      <h2 className="Text50 text-center">{t('Products')}</h2>
      <p className="paragraphText text-center mt-6 max_md:mt-10 max_sm:mt-5 text-TertiaryT">
        {t('ProductsT')}
      </p>
      <div className="mt-10 m-auto max-w-[230px] max_sm:mt-5 max_sm:max-w-[210px]">
        <ButtonWithArrowIcon
          title="Explore all products"
          rounded="60px"
          arrowColor="#484540"
          isBorder
          color="#484540"
          onClick={() => handleClick()}
        />
      </div>
      <div className="mt-[60px] flex flex-wrap justify-between gap-10 max_xl:gap-5 max_md:flex-col max_md:flex-nowrap max_md:items-center max_md:gap-10">
        {productDetails.map((product, index) => (
          <div
            key={index}
            className="relative rounded-[20px] bg-white flex flex-col pt-10 pl-10 max_sm:pl-5 max_sm:pt-5 w-[600px] h-[560px] justify-between max_xl:w-[480px] max_xl:h-[480px] max_600:h-auto max_600:w-[100%]"
          >
            <div>
              <h3 className="Text30">{product.title}</h3>
              <p className="paragraphText text-TertiaryT max-w-[460px] max_xl:max-w-[390px] mt-5">
                {product.text}
              </p>
              <div className="w-max mt-5">
                <ButtonWithArrowIcon
                  title="Let’s go"
                  rounded="60px"
                  isBackground
                  arrowColor="#ffffff"
                  onClick={() => navigate(product.link)}
                />
              </div>
            </div>
            <div className="absolute right-0 bottom-0 max_md:mt-4 max_600:hidden">
              <div className="flex items-end w-[84%] justify-end text-end max_xl:float-end">
                <img src={product.image} alt={product.title} />
              </div>
            </div>
            <div className='hidden max_600:block'>
              <div className="flex justify-end max_md:mt-4">
                <div className="flex items-end">
                  <img src={product.image} alt={product.title} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </CommonSizeContainer>
  );
};

export default ProductCom;
