import React from 'react';

const HeadingComp = ({ title, text,isFullWidth }) => {
  return (
    <div className="flex flex-col items-center mt-[60px] max_sm:mt-5">
      <h1
        className={`headingText text-center max_sm:max-w-[100%] ${
          isFullWidth ? '' : 'max-w-[920px] max_md:max-w-[554px]'
        }`}
        dangerouslySetInnerHTML={{ __html: title }}
      ></h1>
      <p className="Text24 mt-10 max_sm:mt-5 text-LightTextParagraph text-center w-[88%] max_md:w-full">
        {text}
      </p>
    </div>
  );
};

export default HeadingComp;
