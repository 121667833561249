import React, { useEffect, useState } from 'react';
import { TickCircle } from '../../../assets/Icons/securityIcons';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import { ButtonWithArrowIcon } from '../../../utils/Button';
import { useTranslation } from 'react-i18next';
// import VirtualAI from '../../../assets/images/Products/AI/VirtualAI.svg';
import { useWindowSize } from '../../../utils/Hooks/useWindowSize';

const VirtualAssistant = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [virtualAssistantD, setVirtualAssistantD] = useState([]);
  const [voicebot, setVoicebot] = useState([]);
  useEffect(() => {
    setVirtualAssistantD(getTranslatedList(t, 'virtual_assistant'));
    setVoicebot(getTranslatedList(t, 'voicebot'));
  }, [t]);
  return (
    <>
      <CommonSizeContainer isTopPadding>
        <div
          className="flex justify-between max_xl:gap-[100px] max_md:gap-10 max_md:flex-col-reverse"
          id="VirtualAssistantId"
        >
          <div className="max-w-[450px]">
            <h2 className="Text50">{t('Virtualassistant')}</h2>
            <p className="paragraphText mt-6 text-TertiaryT">
              {t('VirtualAIT')}
            </p>
            <div className="max_md:hidden max_sm:block">
              <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5 ">
                {virtualAssistantD?.map((feature, index1) => (
                  <div key={index1} className="flex gap-3 ">
                    <div className="mt-[2px] max_sm:mt-0 flex items-center">
                      <TickCircle
                        style={{
                          width: isMobileView ? '20' : '28',
                          height: isMobileView ? '20' : '28',
                        }}
                      />
                    </div>
                    <p className="Text20Normal font-medium text-PrimaryT">
                      {feature}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="hidden max_md:block max_sm:hidden">
              <div className="flex gap-[60px] max_600:gap-5">
                <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
                  {virtualAssistantD?.slice(0, 3).map((feature, index1) => (
                    <div key={index1} className="flex gap-3 w-[210px]">
                      <div className="mt-[2px] max_sm:mt-0">
                        <TickCircle />
                      </div>
                      <p className="Text20Normal font-medium text-PrimaryT">
                        {feature}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="mt-10 max_xl:mt-8 flex flex-col gap-5 max_sm:gap-4 max_sm:mt-5">
                  {virtualAssistantD?.slice(3, 6).map((feature, index1) => (
                    <div key={index1} className="flex gap-3 w-[230px]">
                      <div className="mt-[2px] max_sm:mt-0">
                        <TickCircle />
                      </div>
                      <p className="Text20Normal font-medium text-PrimaryT">
                        {feature}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <img
              src="https://images.dcdial.com/avnovo.com/VirtualAI.svg"
              alt="VirtualAssistant"
            />
          </div>
        </div>
      </CommonSizeContainer>

      <div
        className="my-[150px] max_xl:my-[100px] max_sm:my-[60px]"
        id="VoicebotsId"
      >
        <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-10 max_sm:px-5">
          <h2 className="Text50 text-center">{t('Voicebots')}</h2>
          <p className="paragraphText mt-6 text-TertiaryT text-center">
            {t('VoicebotsText')}
          </p>
        </div>
        <div className="mt-[60px] max_sm:mt-10 flex max-w-[1440px] m-auto max_md:flex-col max_md:max-w-[480px] max_sm:max-w-full">
          {voicebot.map((items, index2) => (
            <div
              className={`p-[50px] max_1440:p-[40px] max_xl:p-[30px] flex flex-col gap-3 border-[1px] border-StrokePrimary ${
                index2 === 1
                  ? 'border-l-[0px] max_md:border-l-[1px] border-r-[0px] max_md:border-r-[1px] border-t-[1px] max_md:border-t-[0px] border-b-[1px] max_md:border-b-[0px]'
                  : 'border-[1px]'
              }`}
              key={index2}
            >
              <p className="smallText18L text-PrimaryT font-medium">
                {items.id}.
              </p>
              <p className="paragraphText max_sm:text-textMedium font-semibold max_xl:min-h-[50px] max_md:min-h-0 leading-[25px]">
                {items.name}
              </p>
              <p className="smallText18L font-normal text-TertiaryT">
                {items.text}
              </p>
              <div className="mt-7 w-max">
                <ButtonWithArrowIcon
                  title={items.buttonText}
                  rounded="60px"
                  isBackground
                  icon
                  arrowColor="#ffffff"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    virtual_assistant: [
      t('EmailAssistant'),
      t('MeetingScheduler'),
      t('LiveChat'),
      t('WebsiteChatbot'),
      t('SMSAssistant'),
      t('ReportsInsights'),
    ],
    voicebot: [
      {
        id: '01',
        name: t('NaturalEng'),
        text: t('NaturalEngT'),
        buttonText: t('HowDoesNLU'),
      },
      {
        id: '02',
        name: t('SelfCap'),
        text: t('SelfCapText'),
        buttonText: t('Learnmore'),
      },
      {
        id: '03',
        name: t('EnhancedExp'),
        text: t('EnhancedExpT'),
        buttonText: t('WhatVoicebot'),
      },
    ],
  };

  return lists[listKey];
};

export default VirtualAssistant;
