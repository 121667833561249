import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import { useLocation } from 'react-router-dom';
import DeveloperPolicyContent from '../../Components/Legal/LegalPages/DeveloperPolicyContent';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const DeveloperPolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionD');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Avnovo Developer Policy"
        description="Read our Developer Policy for guidelines and best practices when working with Avnovo's platform, ensuring smooth integration and optimal performance."
      />
      <LegalSideLayout>
        <DeveloperPolicyContent />
      </LegalSideLayout>
    </>
  );
};

export default DeveloperPolicy;
