import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonFilled } from '../../utils/Button';
import { PlayIcon } from '../../assets/Icons/homePageIcons';
// import BannerImg from '../../assets/images/HomePage/PaymentSolutions.svg';
import RightEllipse from '../../assets/images/HomePage/RightEllipse.svg';
import LeftEllipse from '../../assets/images/HomePage/LeftEllipse.svg';
import Rectangle from '../../assets/images/HomePage/Rectangle.svg';
import RightEllipseRes from '../../assets/images/HomePage/RightERes.svg';
import LeftEllipseRes from '../../assets/images/HomePage/LeftERes.svg';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const HeroComponent = React.memo(({ isImageLoaded, setImageLoaded }) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth < 500;

  return (
    <>
      <div className="bg-PrimaryT min-h-[600px]">
        <div className="relative max-w-[1920px] m-auto overflow-hidden">
          <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5 pt-[150px] max_md:pt-10 max_sm:pt-[50px]">
            <div className="flex items-center flex-col">
              <h1
                className="headingText text-white text-center max-w-[920px] max_md:max-w-[524px] max_sm:max-w-[100%] z-10"
                dangerouslySetInnerHTML={{ __html: t('HeroTitle') }}
              ></h1>
              <p className="text-white Text24 mt-[30px] max_sm:mt-5 font-normal text-center w-[88%] max_md:w-full">
                {t('HeroText')}
              </p>
              <div className="mt-[60px] max_sm:mt-10 flex gap-8 items-center max_sm:flex-col max_sm:gap-4">
                <a
                  href="https://calendly.com/avnovo-sales/30min"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ButtonFilled
                    title={t('ScheduleDemo')}
                    rounded="60px"
                    // onClick={toggleModal}
                  />
                </a>

                {/* <div className="flex items-center gap-[10px] cursor-pointer  max_sm:flex-row-reverse">
                  <PlayIcon
                    style={{
                      width: isTabView ? 30 : 40,
                      height: isTabView ? 30 : 40,
                    }}
                  />
                  <p className="smallText18L text-white font-semibold uppercase max_sm:text-textSmall max_sm:font-medium">
                    Play video
                  </p>
                </div> */}
              </div>
              <div className="mt-[60px] mb-5 max_md:mt-10 max_sm:mt-5 z-10 min-h-[800px] max_xl:min-h-[670px] max_md:min-h-0">
                <img
                  src="https://images.dcdial.com/avnovo.com/PaymentSolutions.svg"
                  alt="PaymentSolutions"
                  onLoad={() => setImageLoaded(true)}
                />
              </div>
            </div>
          </div>
          {isImageLoaded && (
            <>
              <div className="absolute right-10 max_md:right-[-150px] max_sm:right-0 top-[-64px] z-0">
                <img
                  src={isTabView ? RightEllipseRes : RightEllipse}
                  alt="ellipse"
                />
              </div>
              <div className="absolute left-0 top-96 max_sm:top-56 z-0">
                <img
                  src={isTabView ? LeftEllipseRes : LeftEllipse}
                  alt="ellipse"
                />
              </div>
            </>
          )}
          {isImageLoaded && (
            <div className="absolute right-0 bottom-24 z-0 max_md:w-[30%]">
              <img src={Rectangle} alt="Rectangle" />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default HeroComponent;
