import React, { useState } from 'react';
import LogoEn from '../../assets/images/Footer/Logo.svg';
import CloseIcon from '../../assets/images/Navbar/CloseIcon.svg';
import { useTranslation } from 'react-i18next';
import ArrowUp from '../../assets/images/Footer/arrow-up.svg';
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../utils/Button';

const ManageCookies = ({ setManageClicked, handleSelection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);
  const [data, setData] = useState([
    {
      question: t('StrictlyCookies'),
      answer: t('StrictlyCookiesText'),
      checked: true,
      id: 'Strictly_Cookies',
    },
    {
      question: t('AnalyticsCookies'),
      answer: t('AnalyticsCookiesT'),
      checked: false,
      id: 'Analytics_Cookies',
    },
    {
      question: t('FunctionalCookies'),
      answer: t('FunctionalCookiesText'),
      checked: false,
      id: 'Functional_Cookies',
    },
    {
      question: t('TargetingCookies'),
      answer: t('TargetingCookiesText'),
      checked: false,
      id: 'Targeting_Cookies',
    },
  ]);
  const handleItemClick = (index) => {
    const newData = [...data];
    newData[index].checked = !newData[index].checked; // Toggle checked property
    setData(newData);
  };
  return (
    <div>
      <div className="flex justify-between pb-5">
        <div className="w-[110px]">
          <img src={LogoEn} alt="Logo" className="w-full" />
        </div>
        <div
          className="cursor-pointer "
          onClick={() => setManageClicked(false)}
        >
          <img draggable="false" src={CloseIcon} alt="closeIcon" />
        </div>
      </div>
      <div className="max-h-[620px] max_sm:max-h-[410px] overflow-y-auto hideScrollbar">
        <div className="mt-4 flex flex-col gap-4">
          <p className="Text18Price text-PrimaryT">{t('PrivacyPreference')}</p>
          <p className="Text16Normal">{t('Preference1')}</p>
          <p className="Text16Normal">{t('Preference2')}</p>
          <p className="Text16Normal">
            {t('Preference3')}{' '}
            <span
              className="cursor-pointer Text16Normal text-ButtonP underline"
              onClick={() => navigate('/legal/cookie-policy')}
            >
              {t('CookiePolicy')}
            </span>
          </p>
          <ButtonFilled
            title={t('AllowAll')}
            width="100%"
            fontSize="16px"
            rounded="30px"
            onClick={() => handleSelection('allow-all')}
          />
        </div>
        <div className="mt-10">
          <p className="Text18Price text-PrimaryT">{t('ManageConsent')}</p>
          <div className="mt-4 flex flex-col">
            {data?.map((list, index) => {
              return (
                <React.Fragment key={index + 1}>
                  <div className="pb-2 border-b-[1px] border-StrokePrimary mb-4">
                    <div className="flex justify-between">
                      <div
                        className={`flex gap-3 max_sm:gap-1 items-center cursor-pointer max_sm_380:items-start`}
                        onClick={() => {
                          if (openQuestionIndex === index) {
                            setOpenQuestionIndex(-1);
                          } else {
                            setOpenQuestionIndex(index);
                          }
                        }}
                      >
                        <p className="Text16Normal font-medium text-PrimaryT w-max max_sm_380:w-[144px] max_esm:w-[126px] max_esm:text-[14px]">
                          {list.question}
                        </p>
                        <div className="max_sm_380:mt-[2px]">
                          <img
                            src={ArrowUp}
                            alt="Downarrow"
                            className={`${
                              openQuestionIndex !== index
                                ? 'rotate-[360deg]'
                                : 'rotate-180'
                            } transition-all`}
                          />
                        </div>
                      </div>
                      {index === 0 ? (
                        <>
                          <p className="max_sm:w-[50px] text-textExtraSmall font-OutfitFont font-medium text-ButtonP">
                            {t('AlwaysActive')}
                          </p>
                        </>
                      ) : (
                        <>
                          <div>
                            <Switch
                              onColor="#484540"
                              offColor="#F1F1F1"
                              offHandleColor="#ffffff"
                              onHandleColor="#ffffff"
                              handleDiameter={20}
                              borderRadius={17}
                              uncheckedIcon={
                                <div className="cookie-switch text-[11px] text-TertiaryT font-OutfitFont font-bold">
                                  OFF
                                </div>
                              }
                              checkedIcon={
                                <div className="cookie-switch text-[11px] text-white font-OutfitFont font-bold">
                                  ON
                                </div>
                              }
                              onChange={() => handleItemClick(index)}
                              checked={list.checked}
                              className="react-switch"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={`answerSection ${
                        openQuestionIndex === index ? 'open' : ''
                      }`}
                    >
                      <div
                        className={`mt-1 w-[80%] text-textExtraSmall font-OutfitFont font-normal text-TertiaryT`}
                      >
                        {list.answer}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="mt-4 flex gap-5 max_sm:flex-col">
          <ButtonFilled
            title={t('RejectAll')}
            fontSize="16px"
            width="100%"
            rounded="60px"
            onClick={() => handleSelection('reject-all')}
          />
          <ButtonFilled
            title={t('ConfirmMyChoices')}
            fontSize="16px"
            rounded="60px"
            width="100%"
            onClick={() => handleSelection('my-choice', data)}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageCookies;
