import React from 'react';

const ProgressBar = ({progress}) => {
  return (
    <div className="w-full bg-white rounded-full h-[12px]">
      <div
        className="bg-ButtonP rounded-full h-full"
        style={{ width: progress }}
      ></div>
    </div>
  );
};

export default ProgressBar;
