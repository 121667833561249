import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import { useLocation } from 'react-router-dom';
import DPAContent from '../../Components/Legal/LegalPages/DPAContent';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const DataProcessing = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionDPA');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Data Processing Agreement"
        description="Read Avnovo's Data Processing Agreement to understand how we process and protect your personal data, ensuring compliance with privacy laws."
      />
      <LegalSideLayout>
        <DPAContent />
      </LegalSideLayout>
    </>
  );
};

export default DataProcessing;
