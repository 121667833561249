import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import { useLocation } from 'react-router-dom';
import AcceptableUseContent from '../../Components/Legal/LegalPages/AcceptableUseContent';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const AcceptableUsePolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionA');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Acceptable Use Policy"
        description="Our Acceptable Use Policy sets the standards for acceptable behavior when using Avnovo's services, promoting a safe and respectful environment for all users."
      />
      <LegalSideLayout>
        <AcceptableUseContent />
      </LegalSideLayout>
    </>
  );
};

export default AcceptableUsePolicy;
