import React from 'react'
import CommonSizeContainer from '../Common/CommonSizeContainer';
import Card from '../Common/Card';

const ProductCardContainer = ({allData,title,text,id}) => {
  return (
    <CommonSizeContainer isTopMargin>
      <h2 className="Text50" id={id}>{title}</h2>
      <p className="paragraphText mt-4 max-w-[750px] text-TertiaryT">{text}</p>
      <div className="flex justify-between max_md:flex-wrap max_md:gap-[30px] max_md:justify-center max_md:mt-[60px] max_md:mb-10">
        {allData.map((data, index3) => (
          <Card key={index3} index={index3} data={data} />
        ))}
      </div>
    </CommonSizeContainer>
  );
}

export default ProductCardContainer;