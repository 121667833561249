import React from 'react';
import BannerWithTitleDesc from '../Common/BannerWithTitleDesc';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { ButtonFilled } from '../../utils/Button';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';

const CommonProductComp = ({ title, text, data, dataTitle, dataText, id }) => {
  const { t } = useTranslation();

  return (
    <>
      <BannerWithTitleDesc title={title} text={text} />
      <CommonSizeContainer isBottomMargin>
        <div
          className="flex justify-between mt-[50px] max_md:mt-[60px] max_sm:mt-0 max_xl:gap-5 max_md:gap-[60px] max_sm:gap-10 max_md:flex-col"
          id={id}
        >
          <div className="max-w-[540px] max_xl:max-w-[485px] max_md:max-w-full">
            <h2 className="Text50">{dataTitle}</h2>
            <p className="mt-6 paragraphText text-TertiaryT">{dataText}</p>
            <div className="w-max mt-10 max_md:mt-[30px] max_sm:hidden">
              <a
                href="https://calendly.com/avnovo-sales/30min"
                rel="noopener noreferrer"
                target="_blank"
              >
                <ButtonFilled title={t('BookDemo')} rounded="60px" />
              </a>
            </div>
          </div>
          <div className="max-w-[540px] max_xl:max-w-[480px] max_md:max-w-full flex flex-col w-full gap-[60px] max_sm:gap-10">
            {data?.map((details, index) => (
              <div key={index}>
                <div className="flex justify-between items-center">
                  <p
                    className={`Text30 max_sm_380:text-[22px] max_sm:w-[140px] max_sm_380:w-[126px]`}
                  >
                    {details.value}
                  </p>
                  <p
                    className={`smallText16Normal max_sm:text-[13px] max-w-[320px] text-end  ${
                      details.isFullWidth
                        ? 'max_xl:max-w-[300px] max_sm:max-w-[275px] w-[calc(100%-140px)]'
                        : 'max_xl:max-w-[330px] w-[calc(100%-110px)]'
                    }`}
                  >
                    {details.text}
                  </p>
                </div>
                <div className="mt-[10px]">
                  <ProgressBar progress={details.progress} />
                </div>
              </div>
            ))}
          </div>

          <div className="hidden max_sm:block">
            <div className="w-max">
              <ButtonFilled title={t('BookDemo')} rounded="60px" />
            </div>
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
};

export default CommonProductComp;
