import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SeeMoreArrow } from '../../assets/Icons/icon';
// import BillingAuto from '../../assets/images/HomePage/BillingAutomation.svg';
// import RecurringPayments from '../../assets/images/HomePage/RecurringPayments.svg';
// import CustomerPortal from '../../assets/images/Products/CRM.svg';
// import VirtualCollector from '../../assets/images/HomePage/VirtualCollector.svg';
// import AIAssistant from '../../assets/images/HomePage/AdvancedAI.svg';
// import ContactManagement from '../../assets/images/HomePage/ContactManagement.svg';
import { useNavigate } from 'react-router-dom';
import CommonSizeContainer from '../Common/CommonSizeContainer';

const Allfeature = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [fade] = useState(true);
  const productRefs = useRef([]);

  const [products] = useState([
    {
      id: 'BillingAutomationId',
      name: t('BillingAutomation'),
      text: t('BillingAutomationT'),
      image: 'https://images.dcdial.com/avnovo.com/BillingAutomation.svg',
      link: '/products/payments',
    },
    {
      id: 'RecurringPaymentsId',
      name: t('RecurringPayments'),
      text: t('RecurringPaymentsT'),
      image: 'https://images.dcdial.com/avnovo.com/RecurringPayments.svg',
      link: '/products/payments',
    },
    {
      id: 'CustomerPortalId',
      name: t('CustomerPortalU'),
      text: t('CustomerPortalUT'),
      image: 'https://images.dcdial.com/avnovo.com/CRMProduct.svg',
      link: '/products/payments',
    },
    {
      id: 'VirtualCollectId',
      name: t('VirtualCollectorU'),
      text: t('VirtualCollectorUT'),
      image: 'https://images.dcdial.com/avnovo.com/VirtualCollector.svg',
      link: '/products/payments',
    },
    {
      id: 'AssistantId',
      name: t('AIAssistant'),
      text: t('AIAssistantT'),
      image: 'https://images.dcdial.com/avnovo.com/AdvanceAI.svg',
      link: '/products/ai-assistant',
    },
    {
      id: 'contactManagementId',
      name: t('ContactManagementU'),
      text: t('ContactManagementUT'),
      image: 'https://images.dcdial.com/avnovo.com/ContactManagement.svg',
      link: '/products/crm',
    },
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // setTimeout(() => {
            setVisibleIndex(Number(entry.target.dataset.index));
            // }, 400);
          }
        });
      },
      { threshold: 0.7 }
    );

    // Attach observer to elements
    productRefs.current.forEach((ref) => ref && observer.observe(ref));

    // Cleanup observer on component unmount or dependencies change
    return () => {
      observer.disconnect();
    };
  }, [products]);

  // useEffect(() => {
  //   setProducts(getTranslatedList(t, 'productsList'));
  // }, [t]);

  // useEffect(() => {
  //   const abcTest = sessionStorage.getItem('product_id')

  //   console.log('abcTest',typeof(abcTest));
  //   if (abcTest) {
  //     const element = document.getElementById(abcTest);
  //     console.log('CustomerPortalId', element);
  //     if (element) {
  //       console.log('element', element);
  //       element.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, [])

  return (
    <>
      <div className="w-[100%] max_md:hidden max_sm:hidden">
        <div id="work-container" className="">
          <div className="w-full max-w-[1240px] max_xl:max-w-[980px] flex justify-between mx-auto">
            <div
              className={`py-[150px] flex flex-col gap-[220px] max_xl:gap-[100px]`}
            >
              {products.map((allproduct, index) => (
                <div
                  className="max-w-[430px] max_xl:max-w-[380px]"
                  key={index}
                  ref={(el) => (productRefs.current[index] = el)}
                  data-index={index}
                  id={allproduct.id}
                >
                  <h3 className="text40class text-PrimaryT font-semibold leading-[50px]">
                    {allproduct.name}
                  </h3>
                  <p className="mt-[30px] Text24 font-normal">
                    {allproduct.text}
                  </p>
                  <div className="mt-[30px]">
                    <div
                      className="flex gap-2 items-center cursor-pointer"
                      onClick={() => {
                        navigate(allproduct.link);
                      }}
                    >
                      <p className="Text20Normal font-medium text-PrimaryT">
                        Learn more
                      </p>
                      <div className="max_sm_380:mt-[2px] flex items-center">
                        <SeeMoreArrow color="#484540" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              id="product-work-text"
              className={`text-content content-center sticky flex flex-wrap top-2`}
            >
              <div className="headingText mt-[-140px] max_1500:mt-[-90px] max_1366:mt-[-40px] max_xl:mt-[-20px] min-h-[472px] max_xl:min-h-[414px]">
                <img
                  src={products[visibleIndex]?.image}
                  alt={products[visibleIndex]?.name}
                  draggable="false"
                  className={`transition-opacity duration-500 ${
                    fade ? 'opacity-100' : 'opacity-0'
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MOBILE DESIGN */}

      <div className="hidden max_md:block mt-10 pb-[60px] max_sm:pb-10">
        <CommonSizeContainer>
          {products.map((allproduct, index) => (
            <div
              className={` ${index === 0 ? '' : 'mt-[60px] max_sm:mt-10'}`}
              key={index}
              data-index={index}
            >
              <h3 className="text40class text-PrimaryT font-semibold leading-[50px] max_sm:leading-[35px]">
                {allproduct.name}
              </h3>
              <p className="mt-[30px] Text24 font-normal max_md:mt-6 max_sm:mt-3">
                {allproduct.text}
              </p>
              <div className="mt-[30px] max_md:mt-5">
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => navigate(allproduct.link)}
                >
                  <p className="Text20Normal font-medium text-PrimaryT">
                    Learn more
                  </p>
                  <div className="max_sm_380:mt-[2px] flex items-center">
                    <SeeMoreArrow color="#484540" />
                  </div>
                </div>
              </div>
              <div className="mt-10 flex justify-center">
                <img src={allproduct.image} alt={allproduct.name} />
              </div>
            </div>
          ))}
        </CommonSizeContainer>
      </div>
    </>
  );
});
// const getTranslatedList = (t, listKey) => {
//   const lists = {
//     productsList: ,
//   };

//   return lists[listKey];
// };
export default Allfeature;

// useEffect(() => {
//   const observer = new IntersectionObserver(
//     (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setTimeout(() => {
//             setVisibleIndex(Number(entry.target.dataset.index));
//           }, 400);
//         }
//       });
//     },
//     { threshold: 0.7 }
//   );

//   // Attach observer to elements
//   productRefs.current.forEach((ref) => ref && observer.observe(ref));

//   // Cleanup observer on component unmount or dependencies change
//   return () => {
//     observer.disconnect();
//   };
// }, [product]);

// onClick={() => {
//   if (openQuestionIndex === index) {
//     setOpenQuestionIndex(null);
//   } else {
//     setOpenQuestionIndex(index);
//   }
// }}

// useEffect(() => {
//   const observer = new IntersectionObserver(
//     (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           if (
//             Number(entry.target.dataset.index) === 0 &&
//             scrollDirection == 'down'
//           ) {
//             setVisibleIndex(Number(entry.target.dataset.index));
//           } else if (
//             Number(entry.target.dataset.index) === 3 &&
//             scrollDirection == 'up'
//           ) {
//             setVisibleIndex(Number(entry.target.dataset.index));
//           } else {
//             setTimeout(() => {
//               setFade(false); // Start fading out the current image
//               setTimeout(() => {
//                 setVisibleIndex(Number(entry.target.dataset.index));
//                 setFade(true); // Start fading in the new image
//               }, 500); // 500ms matches the CSS transition time
//             }, 400);
//           }
//         }
//       });
//     },
//     { threshold: 0.7 }
//   );

//   productRefs.current.forEach((ref) => ref && observer.observe(ref));

//   return () => {
//     observer.disconnect();
//   };
// }, [products, scrollDirection]);

//  <div className="hidden max_md:block">
//    <div className="px-10 max_sm:px-5 m-auto mt-[100px] max_sm:mt-[60px]">
//      <div className={`flex flex-col gap-[100px] max_600:gap-[60px]`}>
//        {products.map((allproduct, index) => (
//          <div
//            className="flex flex-col gap-[60px] max_600:gap-10 items-center"
//            key={index}
//          >
//            <div
//              className="flex max_600:flex-col gap-[60px] max_600:gap-10"
//              data-index={index}
//            >
//              <div className="max-w-[350px] max_600:max-w-full">
//                <p className="text40class text-LightPrimary leading-[50px] max_600:leading-[40px]">
//                  {allproduct.name}
//                </p>
//                <p className="mt-4 paragraphText font-normal leading-[30px] max_600:leading-6">
//                  {allproduct.text}
//                </p>
//              </div>

//              {/* Mobile Image */}
//              <div className="hidden max_600:block">
//                <div className="flex justify-center">
//                  <img src={allproduct.image} alt={allproduct.id} />
//                </div>
//              </div>

//              <div className="min-w-[250px] max_sm:max-w-full">
//                <div className="flex gap-2 items-center">
//                  <p className="subParagraphText text-LightPrimary max_md:text-headingNormal max_sm:text-textSmall">
//                    See also
//                  </p>
//                  <div className="max_sm_380:mt-[2px] flex items-center">
//                    <RightArrowIcon color="#6D77A0" />
//                  </div>
//                </div>
//                <div className="flex flex-col gap-2 mt-3">
//                  {allproduct.product.map((item, index1) => (
//                    <p
//                      className="subParagraphText text-ButtonPrimary font-normal cursor-pointer max_md:text-headingNormal max_sm:text-textSmall"
//                      id={item.id}
//                      key={index1}
//                      onClick={() => {
//                        navigate(allproduct.link);
//                        setTimeout(() => {
//                          handleClickScroll(item.id);
//                        }, 400);
//                      }}
//                    >
//                      {item.title}
//                    </p>
//                  ))}
//                </div>
//              </div>
//            </div>
//            <div className="max_600:hidden">
//              <div className="flex justify-center w-full">
//                <img
//                  src={allproduct.image}
//                  alt={allproduct.id}
//                  className="w-full"
//                />
//              </div>
//            </div>
//          </div>
//        ))}
//      </div>
//    </div>
//  </div>;
