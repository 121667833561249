import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import CookiePolicyContent from '../../Components/Legal/LegalPages/CookiePolicyContent';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const CookiePolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionCP');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Cookie Policy"
        description="Read Avnovo's Cookie Policy to understand how we use cookies on our website to enhance your browsing experience and track usage for analytics."
      />
      <LegalSideLayout>
        <CookiePolicyContent />
      </LegalSideLayout>
    </>
  );
}

export default CookiePolicy