import React, { useState } from 'react';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const WhatIncludes = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth <= 980;
  const AllPlansData = [
    {
      column: [
        t('CustomerPortalU'),
        t('PaymentLinksU'),
        t('SecurePayments'),
        t('RecurringBillingU'),
        t('SubscriptionManagement'),
      ],
    },
    {
      column: [
        t('WorkflowAutomation'),
        t('DunningManagement'),
        t('QuotesAndInvoices'),
        t('ContactManagementU'),
        t('RealReporting'),
      ],
    },
    {
      column: [
        t('CustomBranding'),
        t('ComplianceSuite'),
        t('APIAccess'),
        t('DedicatedCustomerSupport'),
        t('AIAssistance'),
      ],
    },
  ];
  const AllPlansDataTab = [
    {
      column: [
        t('CustomerPortalU'),
        t('PaymentLinksU'),
        t('SecurePayments'),
        t('RecurringBillingU'),
        t('SubscriptionManagement'),
        t('WorkflowAutomation'),
        t('DunningManagement'),
        t('QuotesAndInvoices'),
      ],
    },
    {
      column: [
        t('ContactManagementU'), t('RealReporting'),
        t('CustomBranding'),
        t('ComplianceSuite'),
        t('APIAccess'),
        t('DedicatedCustomerSupport'),
        t('AIAssistance'),
      ],
    },
  ];
  const [allPlanDetails] = useState(AllPlansData);
  const [allPlanDetailsTab] = useState(AllPlansDataTab);
  return (
    <CommonSizeContainer isTopMargin>
      <h2 className="text-center Text50">{t('WhatIncluded')}</h2>
      <div className="w-full mt-[60px] max_sm:mt-10 flex justify-between max_720:flex-col max_600:items-center max_720:gap-[14px] max_md:justify-center max_720:items-center">
        {(isTabView ? allPlanDetailsTab :allPlanDetails)?.map((plans, index) => {
          return (
            // <div
            //   className="w-full flex gap-5 justify-between border-[1px]"
            //   key={index}
            // >
            <div
              className="flex flex-col gap-[30px] p-5 max_xl:p-2 max_xl:gap-5 max_720:gap-7"
              key={index}
            >
              {plans.column.map((items, index1) => {
                return (
                  <div className="flex justify-center gap-3 w-[316px] max_xl1160:w-[280px] max_md:w-[316px] max_800:w-[280px] max_720:w-[316px] max_sm:w-[250px] bg-white rounded-lg py-[14px] border-dashed border-[1.5px] border-StrokePrimary" key={index1}>
                    <p className="Text20Normal font-medium text-PrimaryT">
                      {items}
                    </p>
                  </div>
                );
              })}
              {/* </div> */}
            </div>
          );
        })}
      </div>
    </CommonSizeContainer>
  );
};

export default WhatIncludes;
