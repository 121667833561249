export const OnDemandIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.875 23.7249H9.125C8.6 23.7249 8.0125 23.3125 7.8375 22.8125L2.6625 8.33745C1.925 6.26245 2.7875 5.62495 4.5625 6.89995L9.4375 10.3875C10.25 10.95 11.175 10.6625 11.525 9.74995L13.725 3.88745C14.425 2.01245 15.5875 2.01245 16.2875 3.88745L18.4875 9.74995C18.8375 10.6625 19.7625 10.95 20.5625 10.3875L25.1375 7.12495C27.0875 5.72495 28.025 6.43745 27.225 8.69995L22.175 22.8375C21.9875 23.3125 21.4 23.7249 20.875 23.7249Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.125 27.5H21.875"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.875 17.5H18.125"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const BasicPlanIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2373 9.76205L20.5944 4.40491M15.2373 16.3097L22.9754 8.57157M15.2373 22.2621L24.1659 13.3335"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M15.2377 25.2381C20.4976 25.2381 24.7615 20.8978 24.7615 15.5437C24.7615 10.2316 21.7218 4.03286 16.9792 1.81615C16.4265 1.55778 15.8321 1.42859 15.2377 1.42859M15.2377 25.2381C9.97782 25.2381 5.71387 20.8978 5.71387 15.5437C5.71387 10.2316 8.75353 4.03286 13.4961 1.81615C14.0489 1.55778 14.6433 1.42859 15.2377 1.42859M15.2377 25.2381V1.42859"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M14.4873 29.0476C14.4873 29.4619 14.8231 29.7976 15.2373 29.7976C15.6515 29.7976 15.9873 29.4619 15.9873 29.0476H14.4873ZM14.4873 21.9048V29.0476H15.9873V21.9048H14.4873Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export const StartupIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.4375 2.5V27.5"
          stroke={color}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.4375 5H20.4375C23.8125 5 24.5625 6.875 22.1875 9.25L20.6875 10.75C19.6875 11.75 19.6875 13.375 20.6875 14.25L22.1875 15.75C24.5625 18.125 23.6875 20 20.4375 20H6.4375"
          stroke={color}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const GrowPlanIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8497 15.9H13.0247V20.9625C13.0247 21.7125 13.9497 22.0625 14.4497 21.5L19.7747 15.45C20.2372 14.925 19.8622 14.1 19.1622 14.1H16.9872V9.03753C16.9872 8.28753 16.0622 7.93754 15.5622 8.50004L10.2372 14.55C9.77471 15.075 10.1497 15.9 10.8497 15.9Z"
          stroke={color}
          strokeWidth="1.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 7.50006C3.4375 9.58756 2.5 12.1876 2.5 15.0001C2.5 21.9001 8.1 27.5001 15 27.5001C21.9 27.5001 27.5 21.9001 27.5 15.0001C27.5 8.10006 21.9 2.50006 15 2.50006C13.2125 2.50006 11.5 2.87506 9.9625 3.56256"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const ProPlanIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8375 17.5H5C3.625 17.5 2.5 18.625 2.5 20V27.5H10.8375V17.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6627 12.5H13.3252C11.9502 12.5 10.8252 13.625 10.8252 15V27.5H19.1627V15C19.1627 13.625 18.0502 12.5 16.6627 12.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.9996 21.25H19.1621V27.5H27.4996V23.75C27.4996 22.375 26.3746 21.25 24.9996 21.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6508 2.58752L16.3133 3.91252C16.4008 4.10002 16.6383 4.27502 16.8383 4.30002L18.0383 4.50002C18.8008 4.62502 18.9883 5.1875 18.4383 5.725L17.5008 6.6625C17.3383 6.825 17.2508 7.12501 17.3008 7.33751L17.5633 8.48752C17.7758 9.40002 17.2883 9.75003 16.4883 9.27503L15.3633 8.61253C15.1633 8.48753 14.8258 8.48753 14.6258 8.61253L13.5008 9.27503C12.7008 9.75003 12.2133 9.40002 12.4258 8.48752L12.6883 7.33751C12.7383 7.12501 12.6508 6.8125 12.4883 6.6625L11.5633 5.73751C11.0133 5.18751 11.1883 4.6375 11.9633 4.5125L13.1633 4.31253C13.3633 4.27503 13.6008 4.10003 13.6883 3.92503L14.3508 2.6C14.7133 1.875 15.2883 1.87502 15.6508 2.58752Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const SilverPlanIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.3631 13.1424H15.0876H9.25932L4.60938 19.5374L15.2762 30L15.7156 29.5688L25.9421 19.5374L21.293 13.1424H15.3631ZM20.2622 14.3994L19.4034 18.2144L16.3648 14.3994H20.2622ZM15.2331 14.7629L18.5391 18.9111H11.9977L15.2331 14.7629ZM14.1061 14.3994L11.1435 18.1989V18.1998L10.2902 14.3995H14.1061V14.3994ZM9.32711 15.1849L10.1648 18.9111H6.61715L9.32711 15.1849ZM10.5026 20.0244L13.436 26.4359L6.8991 20.0244H10.5026ZM15.2762 27.7835L11.7258 20.0244H18.8257L15.2762 27.7835ZM17.1154 26.4359L20.0488 20.0244H23.6514L17.1154 26.4359ZM20.3867 18.9111L21.2253 15.1849L23.9344 18.9111H20.3867Z"
          fill={color}
        />
        <path
          d="M29.3681 10.6585C27.8502 10.6585 27.3256 10.2777 27.0298 9.98288C26.7359 9.68716 26.3542 9.16257 26.3542 7.64464C26.3542 7.58698 26.3487 7.4231 26.1674 7.4231C25.987 7.4231 25.9806 7.58698 25.9806 7.64464C25.9806 9.16257 25.5988 9.68716 25.304 9.98288C25.0083 10.2777 24.4828 10.6585 22.9667 10.6585C22.9081 10.6585 22.7451 10.6649 22.7451 10.8462C22.7451 11.0266 22.9081 11.033 22.9667 11.033C24.4828 11.033 25.0083 11.4148 25.304 11.7096C25.5988 12.0044 25.9806 12.5299 25.9806 14.0478C25.9806 14.1064 25.987 14.2685 26.1674 14.2685C26.3487 14.2685 26.3542 14.1064 26.3542 14.0478C26.3542 12.5299 26.736 12.0044 27.0298 11.7096C27.3256 11.4139 27.8502 11.033 29.3681 11.033C29.4267 11.033 29.5896 11.0275 29.5896 10.8453C29.5896 10.6658 29.4267 10.6585 29.3681 10.6585Z"
          fill={color}
        />
        <path
          d="M6.79031 4.96031C8.87678 4.96031 9.59824 5.48584 10.0038 5.89049C10.4094 6.29607 10.9331 7.01842 10.9331 9.10488C10.9331 9.18451 10.9441 9.40793 11.1912 9.40793C11.4403 9.40793 11.4476 9.18451 11.4476 9.10488C11.4476 7.01842 11.9722 6.29602 12.3778 5.89049C12.7824 5.4849 13.5039 4.96031 15.5913 4.96031C15.6709 4.96031 15.8953 4.95299 15.8953 4.70396C15.8953 4.45676 15.671 4.4458 15.5913 4.4458C13.5039 4.4458 12.7824 3.92209 12.3778 3.51744C11.9722 3.11092 11.4476 2.39045 11.4476 0.303926C11.4476 0.224297 11.4403 0 11.1912 0C10.944 0 10.9331 0.224297 10.9331 0.303984C10.9331 2.39045 10.4094 3.11098 10.0038 3.5175C9.59824 3.92215 8.87684 4.44586 6.79031 4.44586C6.71068 4.44586 6.48633 4.45594 6.48633 4.70402C6.48633 4.95117 6.71062 4.96031 6.79031 4.96031Z"
          fill={color}
        />
        <path
          d="M6.70623 11.4844C6.70623 11.3187 6.55606 11.3122 6.50297 11.3122C5.1068 11.3122 4.6234 10.9616 4.3524 10.6906C4.08141 10.4187 3.72984 9.93623 3.72984 8.54005C3.72984 8.48697 3.72434 8.33679 3.5577 8.33679C3.39287 8.33679 3.38555 8.48697 3.38555 8.54005C3.38555 9.93623 3.03492 10.4187 2.76393 10.6906C2.49199 10.9616 2.00859 11.3122 0.613359 11.3122C0.559395 11.3123 0.410156 11.3187 0.410156 11.4844C0.410156 11.6501 0.559395 11.6565 0.613418 11.6565C2.00871 11.6565 2.49211 12.0081 2.76398 12.2791C3.03498 12.5501 3.38561 13.0335 3.38561 14.4306C3.38561 14.4837 3.39293 14.6329 3.55775 14.6329C3.72439 14.6329 3.7299 14.4837 3.7299 14.4306C3.7299 13.0335 4.08147 12.5501 4.35246 12.2791C4.62346 12.0072 5.10686 11.6565 6.50303 11.6565C6.55611 11.6565 6.70623 11.651 6.70623 11.4844Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export const GoldPlanIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1875 20.625V23.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.9375 27.5H21.4375V26.25C21.4375 24.875 20.3125 23.75 18.9375 23.75H11.4375C10.0625 23.75 8.9375 24.875 8.9375 26.25V27.5V27.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.6875 27.5H22.6875"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 20C10.1625 20 6.25 16.0875 6.25 11.25V7.5C6.25 4.7375 8.4875 2.5 11.25 2.5H18.75C21.5125 2.5 23.75 4.7375 23.75 7.5V11.25C23.75 16.0875 19.8375 20 15 20Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.8373 14.5625C5.8998 14.2625 5.0748 13.7125 4.4248 13.0625C3.2998 11.8125 2.5498 10.3125 2.5498 8.5625C2.5498 6.8125 3.9248 5.4375 5.6748 5.4375H6.4873C6.2373 6.0125 6.1123 6.65 6.1123 7.3125V11.0625C6.1123 12.3125 6.3748 13.4875 6.8373 14.5625Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1631 14.5625C24.1006 14.2625 24.9256 13.7125 25.5756 13.0625C26.7006 11.8125 27.4506 10.3125 27.4506 8.5625C27.4506 6.8125 26.0756 5.4375 24.3256 5.4375H23.5131C23.7631 6.0125 23.8881 6.65 23.8881 7.3125V11.0625C23.8881 12.3125 23.6256 13.4875 23.1631 14.5625Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6132 9.84062L17.8382 10.7906C17.7132 10.9281 17.6257 11.2031 17.6382 11.3906L17.7132 12.6156C17.7632 13.3656 17.2257 13.7531 16.5257 13.4781L15.3882 13.0281C15.2132 12.9656 14.9132 12.9656 14.7382 13.0281L13.6007 13.4781C12.9007 13.7531 12.3632 13.3656 12.4132 12.6156L12.4882 11.3906C12.5007 11.2031 12.4132 10.9281 12.2882 10.7906L11.5132 9.84062C11.0257 9.26562 11.2382 8.62812 11.9632 8.44062L13.1507 8.14062C13.3382 8.09063 13.5632 7.91563 13.6632 7.75313L14.3257 6.72812C14.7382 6.09063 15.3882 6.09063 15.8007 6.72812L16.4632 7.75313C16.5632 7.91563 16.7882 8.09063 16.9757 8.14062L18.1632 8.44062C18.8882 8.62812 19.1007 9.26562 18.6132 9.84062Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export const PlatinumIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 15L20 10M20 10V6.25L22.5 3.75L23.75 6.25L26.25 7.5L23.75 10H20Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.25 15C26.25 17.225 25.5902 19.4001 24.354 21.2502C23.1179 23.1002 21.3609 24.5422 19.3052 25.3936C17.2495 26.2451 14.9875 26.4679 12.8052 26.0338C10.623 25.5998 8.61839 24.5283 7.04505 22.955C5.47171 21.3816 4.40025 19.3771 3.96617 17.1948C3.53209 15.0125 3.75487 12.7505 4.60636 10.6948C5.45785 8.63914 6.89979 6.88213 8.74984 5.64597C10.5999 4.4098 12.775 3.75 15 3.75"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 15C20 15.9889 19.7068 16.9556 19.1574 17.7779C18.6079 18.6001 17.8271 19.241 16.9134 19.6194C15.9998 19.9978 14.9945 20.0969 14.0246 19.9039C13.0546 19.711 12.1637 19.2348 11.4645 18.5355C10.7652 17.8363 10.289 16.9454 10.0961 15.9755C9.90315 15.0055 10.0022 14.0002 10.3806 13.0866C10.759 12.173 11.3999 11.3921 12.2222 10.8427C13.0444 10.2932 14.0111 10 15 10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export const EverythingPlanIcon = ({ color }) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 5.625V22.5C12.5 23.85 11.95 25.0875 11.075 25.9875L11.025 26.0375C10.9125 26.15 10.7875 26.2625 10.675 26.35C10.3 26.675 9.87498 26.925 9.43748 27.1C9.29998 27.1625 9.1625 27.2125 9.025 27.2625C8.5375 27.425 8.0125 27.5 7.5 27.5C7.1625 27.5 6.82502 27.4625 6.50002 27.4C6.33752 27.3625 6.175 27.325 6.0125 27.275C5.8125 27.2125 5.62501 27.15 5.43751 27.0625C5.43751 27.05 5.4375 27.05 5.425 27.0625C5.075 26.8875 4.73751 26.6875 4.42501 26.45L4.4125 26.4375C4.25 26.3125 4.10002 26.1875 3.96252 26.0375C3.82502 25.8875 3.6875 25.7375 3.55 25.575C3.3125 25.2625 3.11251 24.925 2.93751 24.575C2.95001 24.5625 2.95001 24.5625 2.93751 24.5625C2.93751 24.5625 2.9375 24.55 2.925 24.5375C2.85 24.3625 2.78749 24.175 2.72499 23.9875C2.67499 23.825 2.63748 23.6625 2.59998 23.5C2.53748 23.175 2.5 22.8375 2.5 22.5V5.625C2.5 3.75 3.75 2.5 5.625 2.5H9.375C11.25 2.5 12.5 3.75 12.5 5.625Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.5 20.625V24.375C27.5 26.25 26.25 27.5 24.375 27.5H7.5C8.0125 27.5 8.5375 27.425 9.025 27.2625C9.1625 27.2125 9.29998 27.1625 9.43748 27.1C9.87498 26.925 10.3 26.675 10.675 26.35C10.7875 26.2625 10.9125 26.15 11.025 26.0375L11.075 25.9875L19.575 17.5H24.375C26.25 17.5 27.5 18.75 27.5 20.625Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.01212 27.275C5.26212 27.05 4.54964 26.6375 3.96214 26.0375C3.36214 25.45 2.94961 24.7375 2.72461 23.9875C3.21211 25.55 4.44962 26.7875 6.01212 27.275Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.9627 14.1125L19.5752 17.5L11.0752 25.9875C11.9502 25.0875 12.5002 23.85 12.5002 22.5V10.425L15.8877 7.03751C17.2127 5.71251 18.9877 5.71251 20.3127 7.03751L22.9627 9.68751C24.2877 11.0125 24.2877 12.7875 22.9627 14.1125Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 23.75C8.19036 23.75 8.75 23.1904 8.75 22.5C8.75 21.8096 8.19036 21.25 7.5 21.25C6.80964 21.25 6.25 21.8096 6.25 22.5C6.25 23.1904 6.80964 23.75 7.5 23.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
