import React, { useRef, useState } from 'react';
import { LeftIcon, RightIcon } from '../../assets/Icons/icon';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import PlanCard from './PlanCard';
import { HighVolumePlansM, pricingPlansM } from './jsonData';

const AddOns = () => {
  const sliderRef = useRef();
  const sliderSmallRef = useRef();
  const [lowVolumPlan] = useState(pricingPlansM);
  const [highVolumPlan] = useState(HighVolumePlansM);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const updateButtonState = () => {
    if (sliderRef.current) {
      setIsBeginning(sliderRef.current.swiper.isBeginning);
      setIsEnd(sliderRef.current.swiper.isEnd);
    }
  };
  const updateButtonState1 = () => {
    if (sliderSmallRef.current) {
      setIsBeginning(sliderSmallRef.current.swiper.isBeginning);
      setIsEnd(sliderSmallRef.current.swiper.isEnd);
    }
  };
  const handlePrev = () => {
    sliderRef.current.swiper.slidePrev();
    updateButtonState();
  };
  const handlePrev1 = () => {
    sliderSmallRef.current.swiper.slidePrev();
    updateButtonState1();
  };
  const handleNext = () => {
    sliderRef.current.swiper.slideNext();
    updateButtonState();
  };
  const handleNext1 = () => {
    sliderSmallRef.current.swiper.slideNext();
    updateButtonState1();
  };
  return (
    <div className="flex flex-col m-auto max-w-[1440px] max_sm:px-5 max_md:px-10 mt-[150px] max_md:mt-[100px] max_sm:mt-[60px]">
      <h2 className="text-center text-[50px] font-OutfitFont font-semibold text-PrimaryT">
        Add-ons
      </h2>
      <h3 className="mt-[60px] w-[1240px] m-auto Text30 max_1440:w-[822px] max_md:w-auto max_md:m-auto">
        Communication
      </h3>
      <div className="w-full flex items-center justify-center gap-5 max_md:hidden">
        <div onClick={handlePrev} className="cursor-pointer max_1440:hidden">
          <LeftIcon color={isBeginning ? '#D9D9D9' : '#ABABAB'} />
        </div>
        <div
          onClick={handlePrev1}
          className="hidden cursor-pointer max_1440:block"
        >
          <LeftIcon color={isBeginning ? '#D9D9D9' : '#ABABAB'} />
        </div>
        <div className="mt-10 w-full max-w-[1240px] max_1440:hidden">
          <Swiper
            pagination={false}
            modules={[Pagination]}
            className="mySwiperPlan"
            ref={sliderRef}
            // speed={1100}
          >
            <SwiperSlide>
              <div className="w-full flex gap-4 justify-center">
                {lowVolumPlan?.map((planInfo, index) => {
                  return (
                    <React.Fragment key={index}>
                      <PlanCard planInfo={planInfo} />
                    </React.Fragment>
                  );
                })}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full flex gap-6 justify-center">
                {highVolumPlan?.map((planInfo, index) => {
                  return (
                    <React.Fragment key={index}>
                      <PlanCard planInfo={planInfo} fullWidth />
                    </React.Fragment>
                  );
                })}
              </div>
            </SwiperSlide>
          </Swiper>
          <p className="Text20Normal text-[#000000]">
            *Carrier surcharges for voice/SMS are not included.
          </p>
        </div>
        {/* SMALL DESKTOP */}
        <div className="hidden mt-10 w-full max-w-[822px] max_1440:block max_md:hidden">
          <Swiper
            slidesPerView={3}
            // spaceBetween={30}
            pagination={false}
            modules={[Pagination]}
            className="mySwiperPlan"
            ref={sliderSmallRef}
            // speed={1100}
          >
            {lowVolumPlan?.concat(highVolumPlan).map((planInfo, index1) => {
              return (
                <SwiperSlide key={index1 + 1}>
                  <PlanCard planInfo={planInfo} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <p className="Text20Normal text-[#000000]">
            *Carrier surcharges for voice/SMS are not included.
          </p>
        </div>

        <div onClick={handleNext} className="cursor-pointer max_1440:hidden">
          <RightIcon color={isEnd ? '#D9D9D9' : '#ABABAB'} />
        </div>
        <div
          onClick={handleNext1}
          className="hidden cursor-pointer max_1440:block"
        >
          <RightIcon color={isEnd ? '#D9D9D9' : '#ABABAB'} />
        </div>
      </div>

      {/* SMALL DESKTOP AND TAB DESIGN */}
      <div className="hidden max_md:block mt-10">
        <div className="w-max grid grid-cols-3 gap-5 max_md:grid-cols-2 max_600:grid-cols-1 m-auto">
          {lowVolumPlan?.concat(highVolumPlan).map((planInfo, index) => {
            return (
              <React.Fragment key={index}>
                <PlanCard planInfo={planInfo} fullWidth />
              </React.Fragment>
            );
          })}
        </div>
        <div className="max-w-[590px] max_600:max-w-[270px] m-auto mt-1">
          <p className="Text20Normal max_md:text-textNormal max_sm:text-textSmall text-[#000000] max-w-[285px]">
            *Carrier surcharges for voice/SMS are not included.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddOns;
