import React from 'react';
import Layout from './Layout';
import { Outlet } from 'react-router-dom';
const CommonLayout = ({ isImageLoaded, setImageLoaded }) => {
  return (
    <>
      <Layout isImageLoaded={isImageLoaded}>
        <Outlet />
      </Layout>
    </>
  );
};
export default CommonLayout;
