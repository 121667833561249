import React from 'react'

const CommonSectionContent = ({ children }) => {
  return (
    <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto py-[60px] max_md:py-10 max_sm:py-[30px] max_md:px-10 max_sm:px-5">
      {children}
    </div>
  );
};

export default CommonSectionContent