import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LevelUpImage from '../../assets/images/HomePage/LevelUp1.svg';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import {
  CashAppIcon,
  CloseSeekIcon,
  Desk1Icon,
  FreshDeskIcon,
  HubspotIcon,
  MailChipIcon,
  PaypalIcon,
  SalesForceIcon,
  StripeIcon,
  ZapierIcon,
  ZendeskIcon,
  ZohoIcon,
} from '../../assets/Icons/paymentsIcon';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';
import { ButtonWithArrowIcon } from '../../utils/Button';
import { useNavigate } from 'react-router-dom';

const LevelUp = React.memo(() => {
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const isSmallDesktop = windowWidth <= 1300;
  const isMobileView = windowWidth <= 500;
  const { t } = useTranslation();
  const [dataLeft] = useState([
    {
      title: t('TransparentPrice'),
      text: t('TransparentPriceT'),
    },
    {
      title: t('EasyInte'),
      text: t('EasyInteT'),
    },
    {
      title: t('CustScalability'),
      text: t('CustScalabilityT'),
    },
  ]);
  const [dataRight] = useState([
    {
      title: t('SecurityT'),
      text: t('SecurityCompT'),
    },
    {
      title: t('ReliableSupport'),
      text: t('ReliableSupportT'),
    },
    {
      title: t('AnalyticsIns'),
      text: t('AnalyticsInsT'),
    },
  ]);
  const [seamlessData] = useState([
    {
      title: t('Payments'),
      icons: [<StripeIcon />, <CashAppIcon />, <PaypalIcon />],
    },
    {
      title: t('Marketing'),
      icons: [<HubspotIcon />, <MailChipIcon />, <ZapierIcon />],
    },
    {
      title: t('CRM'),
      icons: [<SalesForceIcon />, <ZohoIcon />, <CloseSeekIcon />],
    },
    {
      title: t('CustomerSupport'),
      icons: [<ZendeskIcon />, <Desk1Icon />, <FreshDeskIcon />],
    },
  ]);
  const [hoverIndex, setHoverIndex] = useState(null);
  return (
    <>
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto pt-[100px] max_sm max_md:pt-10 max_sm:pt-[50px] max_md:px-10 max_sm:px-5">
        <div>
          <h2 className="Text50 text-center">{t('LevelTitle')}</h2>
          <div className="hidden max_md:block mt-[60px] max_sm:mt-10">
            <div className="FLEX_CENTER">
              <img src={LevelUpImage} alt="LevelUpImage" />
            </div>
          </div>
          <div className="mt-[100px] max_md:mt-[60px] max_sm:mt-10 flex justify-between items-center max_720:flex-col max_md:items-start max_720:items-start max_720:gap-[30px]">
            <div className="flex flex-col gap-[50px] max_xl:gap-9 max_md:gap-5 max-w-[300px] max_xl:max-w-[270px] max_md:max-w-[48%] max_720:max-w-[100%] max_720:gap-[30px]">
              {dataLeft.map((data1, index1) => (
                <div key={index1}>
                  <p className="Text30 max_xl:text-[28px] max_sm:text-textMedium">
                    {data1.title}
                  </p>
                  <p className="paragraphText max_xl:text-[22px] max_720:text-textMedium max_sm:text-textSmall mt-5 max_sm:mt-3 max_xl:mt-4 text-TertiaryT max_md:min-h-[84px] max_720:min-h-0">
                    {data1.text}
                  </p>
                </div>
              ))}
            </div>
            <div className="max_md:hidden min-h-[652px] max_xl:min-h-[450px]">
              <img src={LevelUpImage} alt="LevelUpImage" />
            </div>
            <div className="flex flex-col gap-[50px] max_xl:gap-9 max_md:gap-5 max-w-[300px] max_xl:max-w-[270px] max_md:max-w-[48%] max_720:max-w-[100%] max_720:gap-[30px]">
              {dataRight.map((data2, index2) => (
                <div key={index2}>
                  <p className="Text30 max_xl:text-[28px] max_sm:text-textMedium">
                    {data2.title}
                  </p>
                  <p className="paragraphText max_xl:text-[22px] max_720:text-textMedium max_sm:text-textSmall mt-5 max_sm:mt-3 max_xl:mt-4 text-TertiaryT max_md:min-h-[84px] max_720:min-h-0">
                    {data2.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <CommonSizeContainer isTopMargin>
        <h2 className="Text50 text-center">{t('DesignedSeamless')}</h2>
        <p className="paragraphText text-center mt-6 max_md:mt-10 max_sm:mt-5 text-TertiaryT">
          {t('DesignedSeamlessT')}
        </p>
        <div className="mt-[60px] max_sm:mt-10 flex flex-wrap gap-x-[44px] gap-y-12 max_xl:gap-y-8 max_md:gap-y-[30px] max_sm:gap-y-5 max_xl:gap-x-4 max_md:justify-center">
          {seamlessData.map((integration, index3) => (
            <div
              className={`cursor-pointer rounded-md p-2 flex gap-[14px] max_xl:gap-3 max_sm:gap-1 ${
                hoverIndex === index3 ? 'payment-shadow' : ''
              }`}
              key={index3}
              onMouseEnter={() => setHoverIndex(index3)}
              onMouseLeave={() => setHoverIndex(null)}
              onClick={() => navigate('/apps-payments')}
            >
              <div className="w-[230px] max_sm:w-[160px] max_sm_380:w-[140px] bg-StrokePrimary rounded-md FLEX_CENTER paragraphText font-semibold max_sm_380:text-textSmall max_sm_380:font-medium text-PrimaryT max_sm:text-textNormal">
                {integration.title}
              </div>
              {integration.icons.map((icon, index4) => (
                <p
                  className="p-4 max_xl:p-2 max_md:p-4 max_sm:p-[5px] FLEX_CENTER border-[1px] border-StrokePrimary rounded-md"
                  key={index4}
                >
                  {React.cloneElement(icon, {
                    style: {
                      width: isMobileView ? '30' : isSmallDesktop ? '48' : '60',
                      height: isMobileView
                        ? '30'
                        : isSmallDesktop
                        ? '48'
                        : '60',
                    },
                  })}
                </p>
              ))}
            </div>
          ))}
        </div>
        <div>
          <div className="mt-[60px] max_sm:mt-10 flex justify-center gap-5 max_600:flex-col max_600:max-w-[260px] max_sm:max-w-[234px] max_600:mx-auto">
            <ButtonWithArrowIcon
              title="Payment gateways"
              rounded="60px"
              isBackground
              arrowColor="#ffffff"
              onClick={() => {
                navigate('/apps-payments');
                localStorage.setItem('appsCount', 1);
                // sessionStorage.setItem('scrollHome', window.scrollY);
              }}
            />
            <ButtonWithArrowIcon
              title="Explore all integrations"
              rounded="60px"
              arrowColor="#484540"
              isBorder
              color="#484540"
              onClick={() => {
                navigate('/apps-payments');
                // sessionStorage.setItem('scrollHome', window.scrollY);
              }}
            />
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
});

export default LevelUp;
