import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import PrivacyPolicyContent from '../../Components/Legal/LegalPages/PrivacyPolicyContent';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const PrivacyPolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionPP');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Privacy Policy"
        description="Avnovo's Privacy Policy explains how we collect, use, and protect your personal data, ensuring transparency and confidentiality."
      />
      <LegalSideLayout>
        <PrivacyPolicyContent />
      </LegalSideLayout>
    </>
  );
};

export default PrivacyPolicy;
