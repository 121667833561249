import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../Components/ContactUs/ContactForm';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';
import axios from 'axios';

const GetInTouch = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [userCountry, setUserCountry] = useState({
    ip: '',
    countryName: '',
    country_code: '',
  });
  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setUserCountry({
          ...userCountry,
          ip: data.ip,
          countryName: data.country_name,
          country_code: data.country_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <>
      <TitleAndDesc
        title="Contact Us | Avnovo"
        description="Reach out to us for direct support, inquiries, or feedback. Our team is ready to assist you with any questions you have about our products or services."
      />
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto py-[100px] max_md:py-[40px] max_md:px-10 max_sm:px-5 flex justify-between max_md:flex-col max_md:gap-[100px] max_sm:gap-[60px]">
        <div className="max-w-[400px] flex flex-col gap-[50px] max_sm:gap-10 max_sm:w-[100%]">
          <div className="">
            <h1 className="headingText">{t('ContactUs')}</h1>
            <div className="mt-10 max_sm:mt-5 flex flex-col gap-5 max_sm:gap-4 Text20Normal">
              <p className="">{t('ContacUsText')}</p>
              <p>
                <span>sales</span>
                <span>@</span>
                <span>avnovo.</span>
                <span>com</span>
              </p>
              <p>(855) 966-3100</p>
            </div>
          </div>
          {/* Customer Support */}
          <div className="">
            <h2 className="Text30">{t('CustomerSupport')}</h2>
            <div className="mt-4 max_sm:mt-3 flex flex-col gap-5 max_sm:gap-3 Text20Normal">
              <p className="">{t('CustomerSupportText')}</p>
              <p>
                <span>support</span>
                <span>@</span>
                <span>avnovo.</span>
                <span>com</span>
              </p>
              <p>(855) 966-3200</p>
            </div>
          </div>

          <div className="">
            <h2 className="Text30">{t('BusinessHours')}</h2>
            <p className="mt-4 max_sm:mt-3 Text20Normal">{t('BusinessTime')}</p>
          </div>

          <div className="">
            <h2 className="Text30">{t('Address')}</h2>
            <p className="mt-4 max_sm:mt-3 Text20Normal">{t('Add1')}</p>
            <p className="Text20Normal">{t('Add2')}</p>
          </div>
        </div>

        {/* FORM */}
        <ContactForm userCountry={userCountry} />
      </div>
    </>
  );
};

export default GetInTouch;
