import React, { useEffect, useRef, useState } from 'react';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import {
  Confirmation,
  CustomerInteIcon,
  GenerationIcon,
  LineAnimated,
  LineAnimatedRes,
  LineAnimatedResTab,
  PayAuth,
  Processing,
  SMSIcon,
  StraitLine,
} from '../../../assets/Icons/productsIcons';
import { useTranslation } from 'react-i18next';
import useVisibility from '../../../utils/Hooks/useVisibility';
import { useWindowSize } from '../../../utils/Hooks/useWindowSize';

const PaymentLink = () => {
  const { t } = useTranslation();
  const animateRef = useRef();
  const isAnimating = useVisibility(animateRef, 0.7);
  const [startAnim, setStartAnim] = useState(false);
  const isAnimatingLine = useVisibility(animateRef, 0.4);
  const [startAnimLine, setStartAnimLine] = useState(false);
  const [paymentLink, setPaymentLink] = useState([]);
  const intervals = [200, 600, 500, 1900, 900, 1400];
  const intervalsRes = [200, 850, 900, 700, 900, 2800];
  const [count, setCount] = useState(1);
  const [countRes, setCountRes] = useState(1);
  const [visitedDiv] = useState([]);
  const [visitedResDiv] = useState([]);
  const { width: windowWidth } = useWindowSize();
  const isSmallDesktop = windowWidth <= 1300;
  const isTabView = windowWidth <= 980;
  const isMobileView = windowWidth <= 720;

  useEffect(() => {
    setPaymentLink(getTranslatedList(t, 'payment_link'));
  }, [t]);

  useEffect(() => {
    if (!startAnim && isAnimating) {
      setStartAnim(true);
    }
    if (isMobileView) {
      if (!startAnimLine && isAnimatingLine) {
        setStartAnimLine(true);
      }
    }
  }, [isAnimating, isAnimatingLine]);

  useEffect(() => {
    if (startAnim && !isMobileView) {
      if (count < 7) {
        // Only set interval if count is less than 5
        const interval = setInterval(() => {
          // setActiveShadow((prevActiveShadow) => (prevActiveShadow + 1) % 5);
          visitedDiv.push(count);
          setCount((prevCount) => prevCount + 1);
        }, intervals[count - 1]);

        return () => clearInterval(interval);
      }
    }
  }, [count, startAnim]);

    useEffect(() => {
      if (isMobileView) {
        if (startAnimLine) {
          if (countRes < 7) {
            const interval = setInterval(() => {
              visitedResDiv.push(countRes);
              setCountRes((prevCount) => prevCount + 1);
            }, intervalsRes[countRes - 1]);

            return () => clearInterval(interval);
          }
        }
      }
    }, [countRes, startAnimLine]);

  return (
    <CommonSizeContainer isTopMargin isBottomMargin>
      <div ref={animateRef} id="PaymentLinksId">
        <h2 className="Text50 text-center">{t('PaymentLink')}</h2>
        <p className="Text24 mt-4 text-center">{t('PaymentLinkT')}</p>

        <div className="mt-[130px] relative w-max max_xl:w-full m-auto max_md:w-[640px] max_md:mt-20 max_720:hidden">
          {isTabView ? (
            <LineAnimatedResTab
              startAnim={startAnim}
              style={{ width: '100%' }}
            />
          ) : isSmallDesktop ? (
            <LineAnimatedRes startAnim={startAnim} style={{ width: '99.5%' }} />
          ) : (
            <LineAnimated startAnim={startAnim} />
          )}

          <div className="absolute top-[-30px] flex max_md:gap-3 justify-between left-[58px] w-[1034px] max_xl:w-[920px] max_xl:left-[28px] max_xl:top-1 max_md:w-[96%] max_md:top-[5px] max_md:left-[10px]">
            <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
              <div
                className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                  visitedDiv.includes(1)
                    ? 'shadow-animate-product border-PrimaryT'
                    : 'border-StrokePrimary'
                }`}
              >
                <GenerationIcon
                  color={visitedDiv.includes(1) ? '#1D1D1D' : '#ABABAB'}
                />
              </div>
              <p className="mt-3 max_xl:mt-2 Text20Normal text-PrimaryT font-medium text-center">
                {t('Generation')}
              </p>
              <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                {t('GenerationT')}
              </p>
            </div>
            <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
              <div
                className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                  visitedDiv.includes(2)
                    ? 'shadow-animate-product border-PrimaryT'
                    : 'border-StrokePrimary'
                }`}
              >
                <SMSIcon
                  color={visitedDiv.includes(2) ? '#1D1D1D' : '#ABABAB'}
                />
              </div>
              <p className="mt-3 max_xl:mt-2 Text20Normal text-PrimaryT font-medium text-center">
                {t('Delivery')}
              </p>
              <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                {t('DeliveryT')}
              </p>
            </div>
            <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
              <div
                className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                  visitedDiv.includes(3)
                    ? 'shadow-animate-product  border-PrimaryT'
                    : 'border-StrokePrimary'
                }`}
              >
                <CustomerInteIcon
                  color={visitedDiv.includes(3) ? '#1D1D1D' : '#ABABAB'}
                />
              </div>
              <p className="mt-3 max_xl:mt-2 Text20Normal text-PrimaryT font-medium text-center">
                {t('CustomerInteraction')}
              </p>
              <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                {t('CustomerInteractionT')}
              </p>
            </div>
          </div>
          <div className="absolute bottom-[-170px] max_xl:bottom-[-133px] flex max_md:gap-3 justify-between right-[50px] max_xl:right-[36px] w-[1034px] max_xl:w-[920px] max_md:w-[98%] max_md:bottom-[-140px] max_md:right-[13px]">
            <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
              <div
                className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                  visitedDiv.includes(4)
                    ? 'shadow-animate-product  border-PrimaryT'
                    : 'border-StrokePrimary'
                }`}
              >
                <PayAuth
                  color={visitedDiv.includes(4) ? '#1D1D1D' : '#ABABAB'}
                />
              </div>
              <p className="mt-3 max_xl:mt-2 Text20Normal text-PrimaryT font-medium text-center">
                {t('PaymentAuthorization')}
              </p>
              <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                {t('PaymentAuthT')}
              </p>
            </div>
            <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
              <div
                className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                  visitedDiv.includes(5)
                    ? 'shadow-animate-product  border-PrimaryT'
                    : 'border-StrokePrimary'
                }`}
              >
                <Processing
                  color={visitedDiv.includes(5) ? '#1D1D1D' : '#ABABAB'}
                />
              </div>
              <p className="mt-3 max_xl:mt-2 Text20Normal text-PrimaryT font-medium text-center">
                {t('Processing')}
              </p>
              <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                {t('ProcessingT')}
              </p>
            </div>
            <div className="w-[250px] max_md:w-[224px] flex flex-col items-center">
              <div
                className={`w-max p-4 max_xl:p-3 rounded-full border-[1px] bg-white ${
                  visitedDiv.includes(6)
                    ? 'shadow-animate-product  border-PrimaryT'
                    : 'border-StrokePrimary'
                }`}
              >
                <Confirmation
                  color={visitedDiv.includes(6) ? '#1D1D1D' : '#ABABAB'}
                />
              </div>
              <p className="mt-3 max_xl:mt-2 Text20Normal text-PrimaryT font-medium text-center">
                {t('Confirmation')}
              </p>
              <p className="mt-2 max_xl:mt-[6px] text-center smallText16Normal font-medium max_md:leading-5">
                {t('ConfirmationT')}
              </p>
            </div>
          </div>
        </div>

        {/* Mobile tab view */}
        <div className="mt-10 hidden max_720:block">
          <div className="pl-10 relative">
            <StraitLine startAnim={startAnimLine} />

            <div className="absolute top-0 left-3 max_sm:left-[14px] flex flex-col">
              {paymentLink.map((paymentData, index) => (
                <div
                  className={`flex gap-5 ${
                    index === 5 ? 'max_350:!mt-[45px]' : ''
                  }`}
                  key={index}
                  style={{ marginTop: paymentData.mt }}
                >
                  <div
                    className={`w-[60px] max_sm:w-[50px] max_sm:h-[50px] h-[60px] flex justify-center p-3 max_sm:p-2 rounded-full border-[1px] bg-white ${
                      visitedResDiv.includes(paymentData.id)
                        ? 'shadow-animate-product  border-PrimaryT'
                        : 'border-StrokePrimary'
                    }`}
                  >
                    {React.cloneElement(paymentData.icon, {
                      color: visitedResDiv.includes(paymentData.id)
                        ? '#1D1D1D'
                        : '#ABABAB',
                    })}
                  </div>
                  <div className="max-w-[300px] w-[calc(100%-80px)]">
                    <p className="Text20Normal font-medium text-PrimaryT max_sm:text-headingNormal">
                      {paymentData.name}
                    </p>
                    <p className="mt-2 smallText16Normal font-medium tracking-[1px] max_sm_380:tracking-[0px] max_md:leading-5">
                      {paymentData.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CommonSizeContainer>
  );
};

const getTranslatedList = (t, listKey) => {
  const lists = {
    payment_link: [
      {
        id: 1,
        name: t('Generation'),
        text: t('GenerationT'),
        icon: <GenerationIcon />,
        mt: '0px',
      },
      {
        id: 2,
        name: t('Delivery'),
        text: t('DeliveryT'),
        icon: <SMSIcon />,
        mt: '58px',
      },
      {
        id: 3,
        name: t('CustomerInteraction'),
        text: t('CustomerInteractionT'),
        icon: <CustomerInteIcon />,
        mt: '54px',
      },
      {
        id: 4,
        name: t('PaymentAuthorization'),
        text: t('PaymentAuthT'),
        icon: <PayAuth />,
        mt: '58px',
      },
      {
        id: 5,
        name: t('Processing'),
        text: t('ProcessingT'),
        icon: <Processing />,
        mt: '56px',
      },
      {
        id: 6,
        name: t('Confirmation'),
        text: t('ConfirmationT'),
        icon: <Confirmation />,
        mt: '56px',
      },
    ],
  };

  return lists[listKey];
};
export default PaymentLink;
