import React from 'react';
import { useTranslation } from 'react-i18next';
// import ActivityTrackImg from '../../../assets/images/Products/CRM/ActivityTrack.svg';

const ActivityTrack = () => {
  const { t } = useTranslation();
  return (
    <div className="clip-path-class bg-white py-[180px] max_xl:py-[140px] max_md:py-[100px]">
      <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5 flex justify-between items-center gap-10 max_md:flex-col max_md:gap-[60px]">
        <div className="max-w-[450px] max_md:max-w-[100%] flex  flex-col gap-6 max_xl:max-w-[420px] max_sm:gap-4">
          <p className="Text20Normal font-[450] text-DisableText">
            {t('ContactTrack')}
          </p>

          <h2 className="Text50 max_sm:mt-3">{t('YourFingertips')}</h2>
          <p className="paragraphText text-TertiaryT ">{t('YourFingertipT')}</p>
        </div>
        <div>
          <img
            src="https://images.dcdial.com/avnovo.com/ActivityTrack.svg"
            alt="ActivityTracking"
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityTrack;
