import React, { useEffect, useState } from 'react';

import { getCountries } from 'country-state-picker';
import {
  SelectBoxField,
  SelectCountry,
  SelectState,
  TextField,
} from '../../Components/Common/InputFields';
import SelectMobileNumber from '../../Components/Common/SelectPhoneNumber';
import { ErrorIcon } from '../../assets/Icons/icon';
import { industryList } from '../../Components/Pricing/jsonData';

const CompanyDetails = ({
  companyDetails,
  handleChange,
  setInputMobileData,
  inputMobileData,
  error,
  userCountry,
  selectedCountry,
  setSelectedCountry,
}) => {
  const [countries, setCountries] = useState(getCountries());
  const [stateList, setStateList] = useState([]);
  const [countryLib, setCountryLib] = useState(
    (userCountry.country_code && userCountry.country_code) || 'US'
  );

  useEffect(() => {
    if (userCountry?.country_code) {
      // setStateList(State.getStatesOfCountry(userCountry.country_code));
      // const allStates = StateList[userCountry.country_code];
      // setSelectedCountry(allStates);
      // setStateList(allStates.states);
    }
  }, [userCountry]);

  return (
    <>
      <TextField
        type="text"
        placeholder="Enter your company name"
        name="company"
        label="Company Name"
        value={companyDetails.company}
        handleOnChange={handleChange}
        error={error.company}
        errorMsg="Please enter a valid company name."
      />

      <SelectBoxField
        placeholder="Select your industry"
        data={industryList}
        name="industry"
        label="Industry"
        handleOnChange={handleChange}
        value={companyDetails.industry}
        // error={errors.inquiry}
      />

      <SelectBoxField
        placeholder="Select number of employees"
        data={[
          '1-10 employees',
          '11-50 employees',
          '51-100 employees',
          '101-250 employees',
          '251-500 employees',
          '>500 employees',
        ]}
        name="number_of_employees"
        label="Number of employees"
        handleOnChange={handleChange}
        value={companyDetails.number_of_employees}
        // error={errors.inquiry}
      />
      <div>
        <p className="labelClass">Company Phone number</p>
        <SelectMobileNumber
          inputMobileData={inputMobileData}
          setInputValue={setInputMobileData}
          // error={error.phone_number}
          // setErrors={setErrors}
          isAuth
          countryLib={countryLib}
          setCountryLib={setCountryLib}
        />
        {error.phone_number && (
          <div className="flex gap-1 items-center mt-[1px]">
            <ErrorIcon />
            <p className="font-OutfitFont text-[#ff0000] text-[13px] font-normal ml-[2px] max_sm:ml-0 mt-[1px]">
              Please enter a valid company phone number
            </p>
          </div>
        )}
      </div>

      <SelectCountry
        placeholder="Select your country"
        data={countries}
        name="country"
        label="Country"
        handleOnChange={handleChange}
        value={companyDetails.country}
        setStateList={setStateList}
        // error={errors.inquiry}
      />

      <TextField
        type="text"
        placeholder="Enter company URL"
        name="company_website"
        label="Company website (URL)"
        value={companyDetails.company_website}
        handleOnChange={handleChange}
        error={error.company_website}
        errorMsg="Please enter a valid company URL."
      />
      <TextField
        type="text"
        placeholder="Enter your company email address"
        name="company_email"
        label="Company email address"
        value={companyDetails.company_email}
        handleOnChange={handleChange}
        error={error.company_email}
        errorMsg="Please enter a valid company email address."
      />
      <TextField
        type="text"
        placeholder="Enter street address 1"
        name="address1"
        label="Street address 1"
        value={companyDetails.address1}
        handleOnChange={handleChange}
        error={error.address1}
        errorMsg="Please enter a valid street address."
      />
      <TextField
        type="text"
        placeholder="Enter street address 2"
        name="address2"
        label="Street address 2"
        value={companyDetails.address2}
        handleOnChange={handleChange}
      />
      <div className="flex gap-5 w-full">
        <div className="w-[50%]">
          <TextField
            type="text"
            placeholder="Enter city"
            name="city"
            label="City"
            value={companyDetails.city}
            handleOnChange={handleChange}
            error={error.city}
            errorMsg="Please enter a valid city name."
          />
        </div>
        <div className="w-[50%]">
          <SelectState
            placeholder="Select state"
            data={companyDetails.country ? stateList : []}
            name="state"
            label="State"
            handleOnChange={handleChange}
            value={companyDetails.state}
            isTop
            isDisable={companyDetails.country ? false : true}
            // error={errors.inquiry}
          />
        </div>
      </div>
      <TextField
        type="text"
        placeholder="Enter zip/postal code"
        name="postal_code"
        label="Zip/Postal Code"
        value={companyDetails.postal_code}
        handleOnChange={handleChange}
        error={error.postal_code}
        errorMsg="Please enter a valid zipcode"
      />
    </>
  );
};

export default CompanyDetails;
