import React from 'react';
import BgAnimatedLayer from '../Common/BgAnimatedLayer';
import { ButtonWithArrow } from '../../utils/Button';
import ArrowImage from '../../assets/images/Footer/arrow-right.png'
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const ContinueWithAV = ({
  title,
  subTitle1,
  isbreakable,
  subTitle2,
  link,
  BtnText1,
  BtnClick1,
}) => {
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <div className="mt-[150px] max_md:mt-[100px] max_sm:mt-[60px] relative w-full h-[550px] max_md:h-[500px] max_sm:h-[450px] flex justify-center items-center overflow-hidden z-0 bg-PrimaryT">
      <div className="flex flex-col items-center gap-[40px] max_md:gap-6 z-50">
        <h2 className="Text50 max_md:text-text2Xl max_md:leading-[34px] max_sm:text-[22px] max_sm:leading-[30px] text-white max_sm:px-5 text-center px-5">
          {title}
        </h2>
        <p
          className={`max-w-[980px] text-center text-DisableText Text24 max_md:px-10 max_sm:px-5 ${
            isbreakable ? 'max_md:hidden' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: subTitle1 }}
        ></p>
        <p
          className={`hidden text-center paragraphText max_md:px-10 max_sm:px-5 ${
            isbreakable ? 'max_md:block' : ''
          }`}
        >
          {subTitle2}
        </p>

        <div className="flex gap-5 max_sm:flex-col">
          <ButtonWithArrow
            title={BtnText1}
            image={ArrowImage}
            rounded="60px"
            isBackground
            width={isMobileView ? '130px' : '150px'}
            onClick={() => BtnClick1()}
          />
          <ButtonWithArrow
            title="Contact sales"
            image={ArrowImage}
            rounded="60px"
            onClick={() => navigate('/contact/get-in-touch')}
          />
        </div>
      </div>
      <div className="animated-layer absolute w-[1440px] h-full max_md:w-[100%]">
        <BgAnimatedLayer />
      </div>
    </div>
  );
};

export default ContinueWithAV;
