import React from 'react'

const PlanCard = ({ planInfo, fullWidth }) => {
  return (
    <div
      className={`relative rounded-[16px] bg-white p-6 ${
        fullWidth
          ? 'w-[285px] max_720:w-[260px] max_600:w-[285px] max_sm:w-[280px] max_sm_380:w-[260px]'
          : 'w-[240px] max_1440:w-[250px]'
      }`}
    >
      <div>
        {React.cloneElement(planInfo.icon, {
          color: '#484540',
        })}
      </div>
      <p className={`Text20Normal font-medium text-PrimaryT mt-5`}>
        {planInfo.planName}
      </p>
      <div
        className={`text-[38px] font-OutfitFont font-semibold text-PrimaryT`}
      >
        {planInfo.price}
        <span className="text-[16px] font-semibold ml-2">
          {planInfo.priceUnit}
        </span>
      </div>
      <div>
        <p className={`mt-[30px] Text18Price`}>
          {planInfo.timeValue}
          {planInfo.planName !== 'On Demand' && (
            <span className="font-medium ml-2">minutes/texts</span>
          )}
        </p>
        <p className="Text18Price font-semibold mt-3">
          {planInfo.emailValue}
          <span className="Text18Price font-medium ml-2">emails</span>
        </p>

        <p className="Text18Price font-semibold mt-[30px] text-PrimaryT">
          Additional Services
        </p>
        <p className="Text18Price font-semibold mt-5">
          {planInfo.perMinTextValue}
          <span className="Text18Price font-medium ml-2">per min/text</span>
        </p>
        <p className="Text18Price font-semibold mt-5">
          {planInfo.perNumberValue}
          <span className="Text18Price font-medium ml-2">per number</span>
        </p>
      </div>
    </div>
  );
};

export default PlanCard