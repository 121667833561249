import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import DigitalServiceContent from '../../Components/Legal/LegalPages/DigitalServiceContent';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const DigitalServicePolicy = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionSD');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="European Union Digital Services Act"
        description="Learn about the European Union Digital Services Act, which sets standards for digital services, ensuring accountability, transparency, and protection for users."
      />
      <LegalSideLayout>
        <DigitalServiceContent />
      </LegalSideLayout>
    </>
  );
};

export default DigitalServicePolicy;
