import React from 'react';
const Card = ({ data, index }) => {
  return (
    <div
      className={`w-[360px] max_xl:w-[300px] max_md:w-[340px] max_sm_380:w-auto ${
        index === 0
          ? 'max_md:w-[550px] FLEX_CENTER max_800:w-[340px] max_sm_380:w-auto'
          : ''
      }`}
    >
      <div
        className={`flex flex-col rounded-[20px] ${
          index === 0
            ? 'mt-[140px] max_md:mt-0'
            : index === 1
            ? 'mt-[80px] max_md:mt-0'
            : 'mt-[-8px] max_md:mt-0'
        }`}
      >
        <div className="w-[360px] max_xl:w-[300px] max_md:w-[340px] max_sm_380:w-auto">
          <img src={data.image} alt={data.title} />
        </div>
        <div className="w-[360px] max_xl:w-[300px] max_md:w-[340px] max_sm_380:w-auto p-[30px] max_xl:p-5 max_600:p-5 max_md:p-[30px] border-[1px] border-StrokePrimary rounded-b-[20px] mt-[-1px] bg-white">
          <p className="Text20Normal font-semibold text-PrimaryT text-center">
            {data.title}
          </p>
          <p className="smallText16Normal max-w-[260px] m-auto text-center mt-3 min-h-[96px] max_sm:min-h-0 max_sm:text-textExtraSmall">
            {data.text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
