import React, { useEffect } from 'react';
import FetureHerosection from '../Components/Features/FeatureHerosection';
import AllFeatures from '../Components/Features/AllFeatures';
import TitleDescWithKeyword from '../Components/HelmetComp/TitleDescWithKeyword';

const FeaturePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="Features | Avnovo"
        description="Discover the full range of Avnovo's features, including innovative payment solutions, a robust communication suite, contact management, reports and compliance tools."
        keywords="Cutting-edge dialer technology, Predictive dialer solutions,Power dialer tools, Preview manual dialer, Voice broadcast software, Click-to-dial feature, Campaign management tools, Two-way text messaging, SMS and email broadcast campaigns, Inbound call routing, Call recording and monitoring, Interactive voice response (IVR), Automatic call distribution (ACD), Cloud-based agent interface, CRM integration with DCDial, API integration for contact management, AI-powered workflow automation, Automated call transcription, Secure payment processing integration, Customizable reporting and dashboards"
        isPageURL="https://avnovo.com/features"
      />
      <div>
        <FetureHerosection />

        <AllFeatures />
      </div>
    </>
  );
};

export default FeaturePage;
