import React from 'react';
import OTPInput from 'react-otp-input';

const VerifyOtp = ({
  userInput,
  otp,
  setOtp,
  formattedSeconds,
  counter,
  resend_email,
  isLoading,
  otpErr,
}) => {
  return (
    <>
      <p className="text-textSmall font-OutfitFont font-normal text-TertiaryT text-center">
        Enter the verification code we sent to{' '}
        <span className="font-bold">{userInput.email}</span> or click the link
        in the email.
      </p>

      <div>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span> </span>}
          inputStyle={{
            width: '54px',
            height: '54px',
            margin: '0px 7px',
            fontSize: '26px',
            fontWeight: 700,
            textAlign: 'center',
            background: '#F3F4F8',
            borderRadius: '6px',
            color: '#1D1D1D',
            outline: 'none',
            fontFamily: 'Outfit,sans-serif',
          }}
          containerStyle={{
            justifyContent: 'space-between',
            width: '100%',
          }}
          renderInput={(props) => <input {...props} />}
        />
      </div>

      <div className="mt-[6px] flex flex-col items-center">
        {otpErr && (
          <p className="smallText16 text-[#ff0000] max_sm:text-[14px] ml-[2px] max_sm:ml-0 mt-[1px]">
            Please enter a valid otp
          </p>
        )}
        <div
          className={`min-h-[28px] text-textMedium font-OutfitFont font-normal text-ButtonPrimary text-center leading-[23px] ${
            counter === 0 ? 'hidden' : ''
          }`}
        >
          <span style={{ width: '30px', display: 'inline-block' }}>00:</span>
          <span style={{ width: '26px', display: 'inline-block' }}>
            {formattedSeconds}
          </span>{' '}
          sec
        </div>

        <p className="text-center mt-4 text-TertiaryT text-textSmall font-OutfitFont font-medium">
          Didn't receive an email?
        </p>

        <p
          className={`mt-3 text-textSmall font-OutfitFont font-medium px-4 py-[5px] text-center ${
            counter > 0
              ? 'text-DisableText cursor-not-allowed'
              : 'text-ButtonP cursor-pointer'
          }`}
          onClick={() => {
            if (counter === 0 && !isLoading) {
              resend_email();
            }
          }}
        >
          Resend email
        </p>
      </div>
    </>
  );
};

export default VerifyOtp;
