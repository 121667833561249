import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const EmpoweringBusComp = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isTablateView = windowWidth <= 720;
  const [featureList, setFeatureList] = useState([]);
  const [featureListRes, setFeatureListRes] = useState([]);
  useEffect(() => {
    setFeatureList(getTranslatedList(t, 'list1'));
    setFeatureListRes(getTranslatedList(t, 'list2'));
  }, [t]);

  return (
    <>
      <CommonSizeContainer isTopMargin>
        <div className="flex justify-between max_md:flex-col max_md:gap-[30px] max_sm:gap-5 z-10">
          <h2 className="headingText tracking-[-2px] max_md:tracking-[0px] w-[40%] max_xl:w-[42%] max_md:w-[80%] max_720:w-[100%]">
            {t('EmpoweringBusinesses')}
          </h2>
          <div className="Text24 flex flex-col gap-[50px] w-[50%] max_xl:gap-[30px] max_md:w-[100%] max_sm:gap-5">
            <p>{t('EmpoweringText1')}</p>
            <p>{t('EmpoweringText2')}</p>
          </div>
        </div>
        {/* BUSINESS CUSTOMER RATING */}
        <div className="mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]">
          <h2
            className="max_md:hidden text-center Text50"
            dangerouslySetInnerHTML={{ __html: t('BusinessCustomers') }}
          ></h2>
          {/* MOBILE DESIGN */}
          <p className="hidden max_md:block Text50 text-center">
            {t('BusinessCustomersRes')}
          </p>
          <div className="mt-[100px] max_md:mt-[60px] max_sm:mt-10 ">
            <div className="flex flex-wrap gap-[150px] max_md:gap-[100px] max_xl:gap-[100px] justify-center max_md:justify-between max_md:gap-x-0 max_md:gap-y-[60px] max_sm:gap-y-10 z-10">
              {(isTablateView ? featureListRes : featureList).map(
                (item, index) => (
                  <React.Fragment key={index + 1}>
                    <div className="relative max-w-[408px] max_md:max-w-[370px] max_800:max-w-[312px] max_720:max-w-[100%] z-[2]">
                      <h3 className="text40class text-PrimaryT max_md:text-[30px] font-semibold">
                        {item.title}:
                      </h3>
                      <p className="Text24 mt-4 max_md:mt-6 max_sm:mt-4">
                        {item.text}
                      </p>
                      <p className="z-[-1] absolute top-[-84px] left-0 text-[152px] font-OutfitFont font-[900] text-[#DEDEDD] max_md:text-[136px] max_sm:text-[100px] max_md:top-[-75px] max_sm:top-[-58px]">
                        {item.id}
                      </p>
                    </div>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </CommonSizeContainer>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    list1: [
      {
        title: t('Automation'),
        text: t('AutomationT'),
        id: 1,
      },
      {
        title: t('Customization'),
        text: t('CustomizationT'),
        id: 4,
      },
      {
        title: t('Efficiency'),
        text: t('EfficiencyT'),
        id: 2,
      },
      {
        title: t('Innovation'),
        text: t('InnovationT'),
        id: 5,
      },
      {
        title: t('SecurityT'),
        text: t('SecurityText'),
        id: 3,
      },
      {
        title: t('Support'),
        text: t('SupportT'),
        id: 6,
      },
    ],
    list2: [
      {
        title: t('Automation'),
        text: t('AutomationT'),
        id: 1,
      },
      {
        title: t('Efficiency'),
        text: t('EfficiencyT'),
        id: 2,
      },
      {
        title: t('SecurityT'),
        text: t('SecurityText'),
        id: 3,
      },
      {
        title: t('Customization'),
        text: t('CustomizationT'),
        id: 4,
      },
      {
        title: t('Innovation'),
        text: t('InnovationT'),
        id: 5,
      },
      {
        title: t('Support'),
        text: t('SupportT'),
        id: 6,
      },
    ],
  };

  return lists[listKey];
};

export default EmpoweringBusComp;
