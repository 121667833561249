import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../utils/Button';
import { ExploreArrow } from '../../assets/Icons/featureIcon';
import CommonSection from '../Common/CommonSection';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const FeatureHerosection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <div className="max-w-[1920px] m-auto bg-PrimaryT">
      <CommonSection>
        <div className="flex flex-col items-center gap-10 max_sm:gap-5">
          <div className="shadow-[4px_8px_40px_0px_#FFFFFF26] rounded-[48px] border-[1px] border-white flex gap-3 w-fit m-auto px-5 py-3 cursor-pointer max_sm:py-[10px] max_sm:px-3">
            <p
              className="smallText18L text-white font-medium max_sm:text-textSmall"
              onClick={() => navigate('/products')}
            >
              {t('ExploreProducts')}
            </p>
            <div>
              <ExploreArrow />
            </div>
          </div>
          <h1 className="headingText text-white text-center max_sm:max-w-[100%]">
            {t('SmartTool')}
          </h1>
          <p className="Text24 font-medium text-center w-[88%] max_md:w-full">
            {t('SmartToolText')}
          </p>
          <div className="mt-5">
            <a
              href="https://calendly.com/avnovo-sales/30min"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilled
                title="Get a demo"
                height={isMobileView ? '40px' : '46px'}
                width={isMobileView ? '132px' : '142px'}
                rounded="30px"
                fontWeight="500"
              />
            </a>
          </div>
        </div>
      </CommonSection>
    </div>
  );
};

export default FeatureHerosection;
