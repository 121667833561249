import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  ClosePIcon,
  SalesforceIcon,
  ZOHOIcon,
  Bitrix24Icon,
  KeapIcon,
  KommoIcon,
  PipDriveIcon,
  FreshsalesIcon,
} from '../../../assets/Icons/integrationIcon';
import {
  AvLogo,
  SyncLine,
  SyncLine1440,
  SyncLineTab,
} from '../../../assets/Icons/productsIcons';
import { useWindowSize } from '../../../utils/Hooks/useWindowSize';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import Card from '../../Common/Card';
import Img1 from '../../../assets/images/Products/CRM/DataOrg.svg';
import Img2 from '../../../assets/images/Products/CRM/Analytics.svg';
import Img3 from '../../../assets/images/Products/CRM/Interactions.svg';
import ProductCardContainer from '../ProductCardContainer';

const FlawlessAndCustomize = () => {
  const { t } = useTranslation();
  const animateRef = useRef();
  const [customizeCRMData, setCustomizeCRMData] = useState([
    {
      image: Img1,
      title: t('DataOrg'),
      text: t('DataOrgT'),
    },
    {
      image: Img2,
      title: t('AnalyticsInsL'),
      text: t('AnalyticsInsLT'),
    },
    {
      image: Img3,
      title: t('MeaningfulInt'),
      text: t('MeaningfulIntT'),
    },
  ]);
  const [isAnimating1, setIsAnimating1] = useState(false);
  const [isAnimating2, setIsAnimating2] = useState(false);

  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);

  const array1 = [
    <SalesforceIcon />,
    <ZOHOIcon />,
    <ClosePIcon />,
    <Bitrix24Icon />,
  ];

  const array2 = [
    <KeapIcon />,
    <KommoIcon />,
    <PipDriveIcon />,
    <FreshsalesIcon />,
  ];

  const { width: windowWidth } = useWindowSize();
  const isSmallDesktop = windowWidth <= 1440;
  const isTabView = windowWidth <= 980;
  const isMobileView = windowWidth <= 600;
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating1(true);
      setIsAnimating2(true);
      setTimeout(() => {
        setIndex1((prevIndex) => (prevIndex + 1) % array1.length);
        setIndex2((prevIndex) => (prevIndex + 1) % array2.length);
      }, 300);
    }, 2900);

    return () => clearInterval(interval);
  }, [array1.length, array2.length]);

  useEffect(() => {
    if (isAnimating1) {
      setTimeout(() => setIsAnimating1(false), 300);
    }
    if (isAnimating2) {
      setTimeout(() => setIsAnimating2(false), 300);
    }
  }, [index1, index2]);

  const getStyledIcon = (iconArray, index, isAnimating) => {
    const icon = iconArray[index];
    const style = {
      width: isMobileView ? 30 : isTabView ? 40 : isSmallDesktop ? 60 : 70,
      height: isMobileView ? 30 : isTabView ? 40 : isSmallDesktop ? 60 : 70,
      transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
      opacity: isAnimating ? 0 : 1,
      transform: isAnimating ? 'scale(0.5)' : 'scale(1)',
    };
    return React.cloneElement(icon, { style });
  };

  // useEffect(() => {
  //   setCustomizeCRMData(getTranslatedList(t));
  // }, [t]);

  return (
    <>
      <div
        className="m-auto w-full max-w-[1920px] mt-[150px] max_xl:mt-[100px] max_sm:mt-[60px]"
        id="CRMSyncingId"
      >
        <div className="m-auto max-w-[872px] max_md:px-10 max_sm:px-5">
          <h2 className="Text50 text-center">{t('AVFlaw')}</h2>
          <p className="paragraphText text-TertiaryT text-center mt-4">
            {t('AVFlawT')}
          </p>
        </div>
        <div
          className="w-full mt-[-60px] relative max_1440:mt-[-60px] max_md:mt-[-20px] pb-[50px] max_800:hidden"
          ref={animateRef}
        >
          {isSmallDesktop ? (
            <SyncLine1440 style={{ width: '100%' }} />
          ) : (
            <SyncLine style={{ width: '100%' }} />
          )}

          <div
            className="absolute top-10 max_1680:top-12 max_md:top-20 left-[23%] max_1440:left-[15%] bg-white p-5 max_1440:p-[14px] rounded-[20px]"
            style={{ boxShadow: '0px 12px 30px 0px #3C425714' }}
          >
            {getStyledIcon(array1, index1, isAnimating1)}
          </div>
          <div className="absolute left-[43.5%] top-36 max_1680:left-[43.5%] max_1500:left-[42.5%] max_1680:top-32 max_1440:top-36 max_xl1160:top-32 max_xl1160:left-[41.5%] max_md:left-[40.5%]">
            <AvLogo
              style={{
                width: isTabView ? 180 : isSmallDesktop ? 190 : 230,
                height: isTabView ? 180 : isSmallDesktop ? 190 : 230,
                // width: isTabView ? 110 : 150,
                // height: isTabView ? 110 : 150,
              }}
            />
          </div>
          <div
            className="absolute top-10 max_1680:top-12 max_md:top-20 right-[23%] max_1440:right-[15%] bg-white p-5 max_1440:p-[14px] rounded-[20px]"
            style={{ boxShadow: '0px 12px 30px 0px #3C425714' }}
          >
            {getStyledIcon(array2, index2, isAnimating2)}
          </div>
        </div>
        {/* Tab Mobile view RESPONSIVE //////  */}
        <div className="hidden max_800:block mt-5 mb-[-50px]">
          <div className="relative">
            <SyncLineTab style={{ width: '100%' }} />

            <div className="icon-shadow absolute top-9 left-[14%] max_720:left-[12%] max_600:left-[13%] max_600:top-16 max_sm:top-20  bg-white p-[10px] max_600:p-[6px] rounded-[20px]">
              {getStyledIcon(array1, index1, isAnimating1)}
            </div>
            <div className="icon-shadow absolute top-9 right-[14%] max_720:right-[12%] max_600:right-[13%] max_600:top-16 max_sm:top-20 bg-white p-[10px] max_600:p-[6px] rounded-[20px]">
              {getStyledIcon(array2, index2, isAnimating2)}
            </div>
          </div>
        </div>
        <div id="Customizable"></div>
      </div>
      <ProductCardContainer
        allData={customizeCRMData}
        title={t('CustomizableFields')}
        text={t('CustomizableT')}
        id="CustomizableFieldId"
      />
    </>
  );
};
// const getTranslatedList = (t) => {
//   const lists = [
//     {
//       title: t('DataManagement'),
//       text: t('DataManagementT'),
//       icon: <DataManagement />,
//     },
//     {
//       title: t('PersonalizedInsights'),
//       text: t('PersonalizedInsightsT'),
//       icon: <PersonalizedInsights />,
//     },
//     {
//       title: t('DataInsightS'),
//       text: t('DataInsightT'),
//       icon: <MeaningfulInteractions />,
//     },
//   ];

//   return lists;
// };

export default FlawlessAndCustomize;
