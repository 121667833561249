import React, { useState } from 'react';
import GradientBg from '../../Components/Common/GradientBg';
import { AVLogo } from '../../assets/Icons/icon';
import EmailPassword from './EmailPassword';
import { useNavigate } from 'react-router-dom';

const SignInPage = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    email: '',
    password: '',
  });

  const errorInitial = {
    email: false,
    password: false,
  };
  const [errors, setErrors] = useState(errorInitial);
  const [showErr, setShowErr] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const isContinueDisable =
    userInput?.email?.length === 0 || userInput?.password?.length === 0;

  const validateForm = (type) => {
    const newErrors = {};
    // Check for non-empty fields
    if (type === 'email_details') {
      newErrors.email =
        userInput.email.length < 5 || userInput.email.length > 100;
      newErrors.password = !userInput.password;

      if (!newErrors.email) {
        newErrors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInput.email);
      }
    }
    return newErrors;
  };
  const SignInFun = () => {
    const formErrors = validateForm('email_details');
    setErrors(formErrors);
    const hasErrors = Object.values(formErrors).some((error) => error);
    if (!hasErrors) {
      setShowErr(true);
    }
  };
  return (
    <>
      <GradientBg>
        <div className="w-full flex justify-center items-center h-full max_720:px-10 max_sm:px-5">
          <div className="max_720:w-full">
            <div className="w-[600px] max_800:px-10 max_720:w-full h-fit rounded-[20px] bg-[#fff] py-[30px] px-[60px] flex flex-col items-center max_sm:px-5">
              <div className="cursor-pointer" onClick={() => navigate('/')}>
                <AVLogo color="#484540" />
              </div>

              <p className="mt-[30px] text-[26px] font-semibold font-OutfitFont text-PrimaryT leading-[33px] max_sm:text-[20px]">
                Sign in to your account
              </p>
              <div className="flex flex-col gap-4 mt-7 max_sm:mt-5 w-full">
                <EmailPassword
                  userInput={userInput}
                  handleChange={handleChange}
                  error={errors}
                  isContinueDisable={isContinueDisable}
                  onSignIn={SignInFun}
                  showErr={showErr}
                />
              </div>
            </div>

            <div className="w-[600px] max_720:w-full flex gap-4 mt-5 items-center max_sm:gap-2">
              <p className="text14 cursor-pointer max_sm:text-textExtraSmall">© Avnovo</p>
              <p className="w-[4px] h-[4px] bg-PrimaryT rounded-full"></p>
              <p
                className="text14 cursor-pointer max_sm:text-textExtraSmall"
                onClick={() => {
                  navigate('/contact/get-in-touch');
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 200);
                }}
              >
                Contact
              </p>
              <p className="w-[4px] h-[4px] bg-PrimaryT rounded-full"></p>
              <p
                className="text14 cursor-pointer max_sm:text-textExtraSmall"
                onClick={() => {
                  navigate('/legal/privacy-policy');
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 300);
                }}
              >
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </GradientBg>
    </>
  );
};

export default SignInPage;
