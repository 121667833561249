import React from 'react';

const ToggleMethod = ({
  selected,
  setSelected,
  options,
  handleClickScroll,
  width,
}) => {
  return (
    <div
      className={`border-[1px] border-StrokePrimary p-1 rounded-[60px] flex text-center flex-wrap justify-center gap-y-2 gap-x-1 w-fit m-auto max_350:rounded-[10px] max_350:max-w-[170px]`}
    >
      {options?.map((list, index) => {
        return (
          <div
            key={index + 1}
            style={{ width: width }}
            className={`cursor-pointer rounded-[60px] max_sm:px-1 max_sm_380:px-0 Text20Normal max_sm:text-textExtraSmall py-3 max_sm:py-2 flex justify-center max_350:rounded-[10px] ${
              selected?.name === list.name
                ? 'text-white bg-ButtonP'
                : 'text-TertiaryT'
            }`}
            onClick={() => {
              setSelected(list);
              // handleClickScroll(list.id);
            }}
          >
            {list.name}
          </div>
        );
      })}
    </div>
  );
};

export default ToggleMethod;
