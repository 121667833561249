import React from 'react'
import CommonSizeContainer from '../Common/CommonSizeContainer'
import { useTranslation } from 'react-i18next';

const MissionAndVission = () => {
  const {t} = useTranslation()
  return (
    <CommonSizeContainer isTopMargin>
      <div className="flex gap-20 max_xl:gap-10 max_md:flex-col max_md:max-w-[580px] max_md:gap-20 max_sm:gap-10 m-auto">
        <div className="p-10 rounded-[20px] max_xl:p-7">
          <h2 className="text40class text-PrimaryT font-semibold">
            {t('OurMission')}
          </h2>
          <p className="mt-6 max_xl:mt-4 Text24 max_sm:mt-6">
            {t('OurMissionText')}
          </p>
        </div>
        <div className="p-10 rounded-[20px] bg-PrimaryT max_xl:p-7">
          <h2 className="text40class text-white font-semibold">
            {t('OurVision')}
          </h2>
          <p className="mt-6 max_xl:mt-4 Text24 text-white max_sm:mt-6">
            {t('OurVisionText')}
          </p>
        </div>
      </div>
    </CommonSizeContainer>
  );
}

export default MissionAndVission