import React, { useState, useEffect } from 'react';
import { InputField, SelectBox, TextAreaField } from '../Common/InputFields';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
import SelectMobileNumber from '../Common/SelectPhoneNumber';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../utils/Button';
import { TickMarkGreenIcon } from '../../assets/Icons/icon';
import axios from 'axios';
import SelectPhoneNew from '../Common/SelectPhoneNew';

const ContactForm = ({ userCountry }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputMobileData, setInputMobileData] = useState({
    country_code: '',
    phone_number: '',
  });
  const [inquiryList, setInquiryList] = useState([]);
  const initialData = {
    firstName: '',
    lastName: '',
    email: '',
    inquiry: '',
    mobile_no: '',
    message: '',
  };
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    inquiry: false,
    // mobile_no: false,
    message: false,
  });
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaErr, setCaptchaErr] = useState(false);
  const [userDetails, setUserDetails] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [countryLib, setCountryLib] = useState(
    userCountry.country_code || 'US'
  );
  // const [selectedInq]
  useEffect(() => {
    setInquiryList(getTranslatedList(t));
  }, [t]);
  const handleOnChange = (e, name) => {
    if (name === 'inquiry') {
      setUserDetails({
        ...userDetails,
        [name]: e, // Update the specific field based on fieldName
      });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    } else {
      setUserDetails({
        ...userDetails,
        [name]: e.target.value, // Update the specific field based on fieldName
      });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Check for non-empty fields
    newErrors.firstName = !userDetails.firstName;
    newErrors.lastName = !userDetails.lastName;
    newErrors.email = !userDetails.email;
    // newErrors.mobile_no = !inputMobileData.phone_number;
    newErrors.message = !userDetails.message;
    newErrors.inquiry = !userDetails.inquiry;

    if (!newErrors.email) {
      newErrors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userDetails.email);
    }

    return newErrors;
  };

  const toStoreData = async () => {
    const storeData = {
      type: 'add_account',
      api_key: 'bb157599ab1ab83353115a3340e808b5fc2beba4',
      account: {
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        company_id: '10700',
        account_status: 'interested',
      },
      phones: [
        {
          phone_number: inputMobileData.phone_number,
          phone_type: 'MOBILE',
        },
      ],
      emails: [userDetails.email],
      accounts_leads: {
        list_id: '9923013042218',
      },
    };

    axios
      .post('https://api.dcdial.com/api/', storeData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {})
      .catch((err) => {});
  };

  const sendEmail = async () => {
    const data = {
      type: 'send_general_email',
      email_type: 'thankyou_contacting_avnovo',
      email: userDetails.email,
      api_key: 'bb157599ab1ab83353115a3340e808b5fc2beba4',
      Name: userDetails.firstName + ' ' + userDetails.lastName,
      email_content: {
        message: userDetails.message,
        phone_number: inputMobileData.phone_number,
        inquiry: userDetails.inquiry,
      },
    };

    axios
      .post('https://api.dcdial.com/api/', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toStoreData();
          setIsSuccess(true);
          setUserDetails(initialData);
          setInputMobileData({
            country_code: '',
            phone_number: '',
          });
          setCaptchaValue('');
          reloadCaptcha();
          setCountryLib(userCountry.country_code || 'US');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    setIsSuccess(false);
    const formErrors = validateForm();
    setErrors(formErrors);

    // Check if there are any errors
    const hasErrors = Object.values(formErrors).some((error) => error);
    if (!hasErrors) {
      if (validateCaptcha(captchaValue) === true) {
        setCaptchaErr(false);
        sendEmail();
        setIsLoading(true);
      } else {
        setCaptchaErr(true);
      }
    }
  };
  const reloadCaptcha = () => {
    loadCaptchaEnginge(6, 'transparent', '#1d1d1d');
  };

  useEffect(() => {
    let context = document.getElementById('canv');
    if (context) {
      const parentDiv = document.createElement('div');
      parentDiv.className = 'my-custom-captcha';
      context.parentNode.insertBefore(parentDiv, context);
      parentDiv.appendChild(context);
    }
  }, []);
  
  useEffect(() => {
    reloadCaptcha();
  }, []);

  const handleOnChangeCaptcha = (e) => {
    setCaptchaValue(e.target.value);
  };

  return (
    <div className="w-[540px] bg-white p-[30px] rounded-[30px] max_xl:w-[500px] max_sm:w-[100%] max_sm:px-4 max_sm:py-6">
      <p className="text40class text-PrimaryT">{t('GetInTouch')}</p>
      <div className="flex flex-col gap-5 mt-[30px]">
        <div className="flex gap-5 max_sm:flex-col">
          <div className="w-[50%] max_sm:w-[100%]">
            <InputField
              type="text"
              value={userDetails.firstName}
              placeholder="First name*"
              name="firstName"
              handleOnChange={handleOnChange}
              error={errors.firstName}
            />
          </div>
          <div className="w-[50%] max_sm:w-[100%]">
            <InputField
              type="text"
              value={userDetails.lastName}
              placeholder="Last name*"
              name="lastName"
              handleOnChange={handleOnChange}
              error={errors.lastName}
            />
          </div>
        </div>
        <div>
          <InputField
            type="text"
            value={userDetails.email}
            placeholder="Email address*"
            name="email"
            handleOnChange={handleOnChange}
            error={errors.email}
          />
        </div>
        <div>
          {/* <SelectMobileNumber
            inputMobileData={inputMobileData}
            setInputValue={setInputMobileData}
            error={errors.mobile_no}
            setErrors={setErrors}
            defaultCountry={userCountry.country_code || 'US'}
            countryLib={countryLib}
            setCountryLib={setCountryLib}
          /> */}

          <SelectPhoneNew
            inputMobileData={inputMobileData}
            setInputValue={setInputMobileData}
            // errorPhone={error}
            setErrors={setErrors}
            detectedCountry={
              userCountry.country_code && userCountry.country_code
            }
            // handleOnBlur={handleBlurURL}
          />
        </div>
        <div>
          <SelectBox
            placeholder="Inquiry"
            data={inquiryList}
            name="inquiry"
            handleOnChange={handleOnChange}
            value={userDetails.inquiry}
            error={errors.inquiry}
          />
        </div>
        <div>
          <TextAreaField
            type="text"
            value={userDetails.message}
            placeholder="How can we help?"
            name="message"
            handleOnChange={handleOnChange}
            error={errors.message}
          />
        </div>
        <div className="relative pt-12px">
          <InputField
            id="captcha"
            name="captcha"
            placeholder="Enter captcha"
            value={captchaValue}
            handleOnChange={handleOnChangeCaptcha}
            error={captchaErr}
          />
        </div>
        <div className="flex w-full justify-center relative">
          <LoadCanvasTemplateNoReload reloadColor="red" className="w-[100%]" />
          <div
            className="absolute top-1 right-0 cursor-pointer"
            onClick={reloadCaptcha}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M22 12.5C22 18.02 17.52 22.5 12 22.5C6.48 22.5 3.11 16.94 3.11 16.94M3.11 16.94H7.63M3.11 16.94V21.94M2 12.5C2 6.98 6.44 2.5 12 2.5C18.67 2.5 22 8.06 22 8.06M22 8.06V3.06M22 8.06H17.56"
                stroke="#484540"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div>
          <ButtonFilled
            isLoading={isLoading}
            title="Submit"
            rounded="30px"
            onClick={() => {
              if (!isLoading) {
                handleSubmit();
              }
            }}
          />
          {isSuccess && (
            <div className="mt-4 flex gap-2">
              <div className="mt-1">
                <TickMarkGreenIcon style={{ width: 20, height: 20 }} />
              </div>
              <p className="smallText16 text-[#23B339] max_sm:text-[14px]">
                Thank you for contacting Avnovo! Your request has been
                submitted. We’ll get back to you soon.
              </p>
            </div>
          )}
        </div>
        <p className="text-textSmall font-OutfitFont font-normal text-TertiaryT text-center">
          By contacting us, you agree to our{' '}
          <span
            className="text-textSmall font-OutfitFont text-PrimaryT font-semibold cursor-pointer"
            onClick={() => navigate('/legal/terms-of-service')}
          >
            Terms of Service
          </span>{' '}
          and
          <span
            className="text-textSmall font-OutfitFont text-PrimaryT font-semibold cursor-pointer"
            onClick={() => navigate('/legal/privacy-policy')}
          >
            {' '}
            Privacy Policy
          </span>
        </p>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      name: t('GeneralInquiry'),
      id: 1,
    },
    {
      name: t('SalesInquiry'),
      id: 2,
    },
    {
      name: t('TechnicalSupport'),
      id: 3,
    },
    {
      name: t('Partnership'),
      id: 4,
    },
    {
      name: t('ProductDemo'),
      id: 5,
    },
    {
      name: t('BillingInquiry'),
      id: 6,
    },
    {
      name: t('Feedback/Suggestions'),
      id: 7,
    },
    {
      name: t('Media/PressInquiry'),
      id: 8,
    },
    {
      name: t('EmploymentOpportunities'),
      id: 9,
    },
    {
      name: t('Other'),
      id: 10,
    },
  ];

  return lists;
};

export default ContactForm;
