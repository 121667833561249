import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberWithHeadingComp from './NumberWithHeadingComp';

const FeesComp = ({ data, id, handleOnClick }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">3. {t('Fees')}</p>
      {data.map((uses, index3) => (
        <React.Fragment key={index3}>
          {index3 === 0 ? (
            <>
              {' '}
              <div className="mt-5 flex flex-col">
                <p className="smallText18L text-SecondaryT font-semibold leading-5">
                  {t('3.1SubscriptionFees')}
                </p>
                <div className="mt-4 flex flex-col">
                  <p className="smallText16Normal">{t('SubscriptionFeesT')}</p>
                  <div className="mt-4 flex flex-col gap-4">
                    {uses.text.map((list, index4) => (
                      <p
                        key={index4}
                        className="smallText16Normal w-[calc(100%-18px)]"
                        dangerouslySetInnerHTML={{
                          __html: list,
                        }}
                        onClick={handleOnClick}
                      ></p>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <NumberWithHeadingComp
                data={uses}
                handleOnClick={handleOnClick}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default FeesComp;
