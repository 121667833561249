import React from 'react';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import TagsComp from '../Common/TagsComp';
import { useTranslation } from 'react-i18next';
import AnimateSection from './AnimateSection';

const FlexiblePayment = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div className="bg-PrimaryT py-[50px]">
      <CommonSizeContainer>
        <div className='flex flex-col gap-6 max_md:gap-5'>

        <TagsComp text={t('Paymentmethods')} />
        <h2 className="Text50 text-white max_md:mt-[10px]">{t('FlexibleOptions')}</h2>
        <div className="flex items-center justify-between max_md:flex-col max_md:items-start max_md:gap-5">
          <p className="paragraphText text-white max-w-[800px] max_xl:max-w-[660px] max_md:max-w-full">
            {t('FlexibleOptionsT')}
          </p>
          </div>
          
          <div className='my-16'>
            <AnimateSection />
          </div>
        </div>
      </CommonSizeContainer>
    </div>
  );
});

export default FlexiblePayment;
