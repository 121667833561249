import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DigiCertTrustedImg from '../../assets/images/Security/trusted.svg';
import InternalAuditsImg from '../../assets/images/Security/Internal Audits.svg';
import PCICompliantImg from '../../assets/images/Security/PCILevelCompliant.svg';
import NACHACompliantImg from '../../assets/images/Security/NACHA Compliant.svg';
import SOCCentersImg from '../../assets/images/Security/SOCDataCenters.svg';
import TokenizedVaultImg from '../../assets/images/Security/Tokenized Vault.svg';
import { DataRetentionIcon, DataStorageIcon, InfrastructureIcon, UserTrainingIcon } from '../../assets/Icons/securityIcons';
import { TickMarkGreenIcon } from '../../assets/Icons/icon';

const ProtectingData = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [securitycompliance] = useState([
    {
      title: t('DigiCertTrusted'),
      image: DigiCertTrustedImg,
    },
    {
      title: t('InternalAudits'),
      image: InternalAuditsImg,
    },
    {
      title: t('PCICompliant'),
      image: PCICompliantImg,
    },
    {
      title: t('NACHACompliant'),
      image: NACHACompliantImg,
    },
    {
      title: t('SOCCenters'),
      image: SOCCentersImg,
    },
    {
      title: t('TokenizedVault'),
      image: TokenizedVaultImg,
    },
  ]);
  useEffect(() => {
    setData(getTranslatedList(t));
  }, [t]);

  return (
    <>
      <div className="clip-path-class-upper bg-white pt-[180px] max_sm:pt-[90px] pb-[100px] max_sm:pb-[50px]">
        <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5 ">
          <h2 className="Text50 max-w-[750px] max_md:max-w-full">{t('ProtectingYourData')}</h2>
          <p className="mt-6 max-w-[750px] max_md:max-w-full paragraphText text-TertiaryT">
            {t('ProtectingYourDataT')}
          </p>
          <div className="mt-[60px] max_sm:mt-10 flex flex-wrap gap-7 justify-center">
            {data.map((service, index) => (
              <div
                className="bg-WebRootColor p-[30px] rounded-xl max-w-[600px] max_xl:max-w-[474px] "
                key={index}
              >
                <div>{service.icon}</div>
                <h3 className="mt-6 paragraphText text-PrimaryT font-semibold">
                  {service.title}
                </h3>
                <div className="mt-4 flex flex-col gap-4">
                  {service.text.map((list, index1) => (
                    <div className="flex gap-2" key={index1}>
                      <div className="mt-[2px]">
                        <TickMarkGreenIcon />
                      </div>
                      <p className="Text20Normal text-TertiaryT">{list}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-BgSecondary py-10">
        <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5 ">
          <h2 className="text40class font-medium text-PrimaryT text-center">
            {t('CommittedSecurityCompliance')}
          </h2>
          <div className="flex justify-between gap-12 mt-[60px] max_sm:mt-10 max_md:flex-wrap max_md:justify-center">
            {securitycompliance.map((list, index4) => (
              <div
                className="w-[150px] flex flex-col items-center"
                key={index4}
              >
                <div>
                  <img src={list.image} alt={list.title} />
                </div>
                <p className="paragraphText text-SecondaryT text-textMedium mt-6 text-center max_xl:tracking-[-1px] max_md:tracking-normal max_sm:text-textSmall">
                  {list.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
const getTranslatedList = (t) => {
  const services = [
    {
      title: t('Infrastructure'),
      text: [
        t('Infrastructure1'),
        t('Infrastructure2'),
        t('Infrastructure3'),
        t('Infrastructure4'),
      ],
      icon: <InfrastructureIcon />,
    },
    {
      title: t('UserTraining'),
      text: [t('UserTraining1'), t('UserTraining2'), t('UserTraining3')],
      icon: <UserTrainingIcon />,
    },
    {
      title: t('DataStorage'),
      text: [t('DataStorage1'), t('DataStorage2'), t('DataStorage3')],
      icon: <DataStorageIcon />,
    },
    {
      title: t('DataRetention'),
      text: [t('DataRetention1'), t('DataRetention2'), t('DataRetention3')],
      icon: <DataRetentionIcon />,
    },
  ];

  return services;
};
export default ProtectingData;
