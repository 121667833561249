import React from 'react';
import BannerWithTitleDesc from '../Components/Common/BannerWithTitleDesc';
import { useTranslation } from 'react-i18next';
import AllProducts from '../Components/Products/AllProducts';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const ProductPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <TitleAndDesc
        title="Avnovo | Explore All Products"
        description="Discover Avnovo's range of products, including advanced payment solutions, a comprehensive communication suite, robust contact management, and AI-driven tools."
        isImageURL="https://images.dcdial.com/avnovo.com/Payments.svg"
        isPageURL="https://avnovo.com/products"
      />
      <div>
        <BannerWithTitleDesc
          title={t('ProductTitle')}
          text={t('ProductText')}
          isButton
        />

        <AllProducts />
      </div>
    </>
  );
};

export default ProductPage;
