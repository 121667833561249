import React, { useEffect, useState } from 'react';
import CommonProductComp from '../Components/Products/CommonProductComp';
import { useTranslation } from 'react-i18next';
import IVRComp from '../Components/Products/Communications/IVRComp';
import ProductCardContainer from '../Components/Products/ProductCardContainer';
import Img1 from '../assets/images/Products/Communication/Seamless.svg';
import Img2 from '../assets/images/Products/Communication/Campaigns.svg';
import Img3 from '../assets/images/Products/Communication/cross-channel.svg';
// import OutboundImg from '../assets/images/Products/Communication/OuboundCall.svg';
import {
  EmailSMS,
  PaymentIVR,
  VoiceBroadCast,
} from '../assets/Icons/communicationIcon';
import BgCenterComp from '../Components/Products/BgCenterComp';
import SmartRouting from '../Components/Products/Communications/SmartRouting';
import TitleDescWithKeyword from '../Components/HelmetComp/TitleDescWithKeyword';

const CommunicationPage = () => {
  const { t } = useTranslation();
  const [data] = useState([
    {
      value: 'By 10-20%',
      text: 'Increased conversion rate with instant access to customer support agents.',
      progress: '15%',
    },
    {
      value: 'By 25%',
      text: 'Decreased cost per acquisition with our real-time one- and two-way communication.',
      progress: '26%',
    },
    {
      value: 'By 86%',
      text: 'Improved engagement by connecting with customers via their preferred method of communication.',
      progress: '82%',
    },
  ]);
  const [UnifiedCom] = useState([
    {
      image: Img1,
      title: t('IntegrationCapability'),
      text: t('SeamlessIntT'),
    },
    {
      image: Img2,
      title: t('TargetedCampaigns'),
      text: t('TargetedCampaignsT'),
    },
    {
      image: Img3,
      title: t('CrossChannel'),
      text: t('CrossChannel'),
    },
  ]);
  const [CommunicationData, setCommunicationData] = useState({
    // AgentData: [],
    OutBoundData: [],
  });
  useEffect(() => {
    setCommunicationData((prevData) => ({
      ...prevData,
      // AgentData: getTranslatedList(t, 'agent_data'),
      OutBoundData: getTranslatedList(t, 'outbound_data'),
    }));
  }, [t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="Communications | Avnovo"
        description="Enhance your business communications with advanced tools like two-way text messaging, email campaigns, integrated dialing, and interactive voice response (IVR) systems."
        keywords="Advanced call routing, Inbound call center solutions, Outbound call center management, Interactive voice response (IVR), Automatic call distribution (ACD), Real-time call management, Agent productivity tools, Customer support automation, Cloud-based agent interface, AI-powered call center, Seamless customer communication, Predictive dialing software, Power dialing features, SMS broadcast campaigns, Email broadcast campaigns, Unified communications platform, Workflow automation for call centers, Call recording and monitoring, Compliance features for call centers, Personalized customer greetings, Call transfer and monitoring, Enhanced customer engagement, Customer identity verification, Payment reminder calls, Customizable call handling, Cross-channel customer interaction, Call center campaign management, Reduce cost per acquisition, Increase first call resolution, Scale call center operations"
        isPageURL="https://avnovo.com/products/communication"
      />
      <div>
        <CommonProductComp
          title={t('Communication')}
          text={t('CommunicationPageT')}
          data={data}
          dataTitle={t('OutSupport')}
          dataText={t('OutSupportT')}
          id="InboundCallId"
        />

        <IVRComp />

        <ProductCardContainer
          allData={UnifiedCom}
          title={t('UnifiedComU')}
          text={t('UnifiedComT')}
          id="UnifiedComId"
        />

        <BgCenterComp
          title={t('OutboundCall')}
          text={t('OutboundCallT')}
          background="#FFFFFF"
          data={CommunicationData.OutBoundData}
          id="OutboundCallId"
        >
          <div className="relative flex justify-center pt-[60px] pb-[100px] max_md:pt-0 max_md:pb-0 max-w-[800px] m-auto">
            <img
              src="https://images.dcdial.com/avnovo.com/OuboundCall.svg"
              alt="OutboundCall"
            />
          </div>
        </BgCenterComp>

        <SmartRouting />
      </div>
    </>
  );
};

const getTranslatedList = (t, listKey) => {
  const lists = {
    // agent_data: [
    //   {
    //     name: t('Flexibility'),
    //     text: t('FlexibilityText'),
    //     icon: <FlexibilityIcon />,
    //   },
    //   {
    //     name: t('AutomatedWorkflows'),
    //     text: t('AutomatedWorkflowsText'),
    //     icon: <AutomatedIcon />,
    //   },
    //   {
    //     name: t('RealTimecall'),
    //     text: t('RealTimecallText'),
    //     icon: <RealTimeIcon />,
    //   },
    // ],
    outbound_data: [
      {
        name: t('Voice'),
        text: t('VoiceText'),
        icon: <VoiceBroadCast />,
      },
      {
        name: t('EmailSMS'),
        text: t('EmailSMSText'),
        icon: <EmailSMS />,
      },
      {
        name: t('PaymentIVR'),
        text: t('PaymentIVRText'),
        icon: <PaymentIVR />,
      },
    ],
  };

  return lists[listKey];
};

export default CommunicationPage;
