import React, { useEffect, useRef, useState } from 'react';
import 'react-international-phone/style.css';
import searchIcon from '../../assets/images/HomePage/searchIcon.svg';
import arrowIcon from '../../assets/images/HomePage/arrow-down.svg';

import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { useOnClickOutside } from './useOnClickOutside';

const SelectPhoneNew = ({
  inputMobileData,
  setInputValue,
  detectedCountry,
  setErrors,
  handleOnBlur,
  onChange,
  isAuth,
  ...restProps
}) => {
  const countryRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [countries, setCountries] = useState(defaultCountries);
  const [searchInput, setSearchInput] = useState('');
  const [value, setValue] = useState(
    inputMobileData.phone_number ? inputMobileData.phone_number : ''
  );
  useEffect(() => {
    setValue(inputMobileData.phone_number);
  }, [inputMobileData]);

  useOnClickOutside(countryRef, () => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, 200);
    }
  });

  const handleOnChangeSearch = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    if (searchInput.length > 0) {
      const filteredData = defaultCountries.filter((country) =>
        country[0].toLowerCase().includes(searchInput.toLowerCase())
      );
      setCountries(filteredData);
    }
  }, [searchInput]);

  const handleNumberChange = (data) => {
    setValue(data);
    setInputValue((prev) => {
      return {
        ...prev,
        phone_number: data,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone_number: false,
    }));
  };
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      forceDialCode: true,
      defaultCountry: detectedCountry || 'us',
      value,
      countries: defaultCountries,
      onChange: (data) => {
        handleNumberChange(data.phone);
      },
    });

  useEffect(() => {
    if (detectedCountry) {
      setCountry(detectedCountry.toLowerCase());
    }
  }, [detectedCountry]);

  return (
    <div
      className={`relative flex items-center border-[1px] border-StrokePrimary ${
        isAuth ? 'rounded-[7px]' : 'rounded-[30px]'
      }`}
    >
      <div className="pl-4 pr-2 w-[70px] cursor-pointer">
        <div
          onClick={() => {
            if (!isOpen) {
              setIsOpen(true);
            }
          }}
        >
          <div className="flex items-center gap-2">
            <FlagImage iso2={country.iso2} className="" />
            <div className="w-[14px] h-[14px]">
              <img
                className={`transform w-[14px] h-[14px] transition-transform duration-200 ${
                  isOpen ? 'rotate-180' : ''
                }`}
                src={arrowIcon}
                alt="arrow icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[1px] h-[48px] bg-StrokePrimary"></div>
      <div className="w-[calc(100%-70px)]">
        <input
          type="tel"
          value={inputValue}
          onChange={handlePhoneValueChange}
          ref={inputRef}
          className="w-full p-3 rounded-[30px] focus:outline-none font-OutfitFont text-textSmall font-normal text-PrimaryT"
          placeholder="Phone number"
          onBlur={() => {
            if (handleOnBlur) handleOnBlur('phone');
          }}
          {...restProps}
        />
      </div>

      <div
        style={{
          display: !isOpen ? 'none' : 'block',
          boxShadow: '0px 8px 40px 0px #19224C1A',
        }}
        ref={countryRef}
        className="absolute z-30 w-full top-[55px] rounded-[10px] left-0 bg-white"
      >
        <div className="Phone relative rounded-tl-[10px] rounded-tr-[10px]">
          <input
            placeholder="Search for country"
            className="border-b-StrokePrimary border-b-[1px] h-[40px] w-full pl-[40px] focus:outline-none placeholder:text-LightTextParagraph rounded-tl-[10px] rounded-tr-[10px] text-textExtraSmall font-DMSansFont font-normal"
            name="searchInput"
            value={searchInput}
            onChange={handleOnChangeSearch}
            onKeyUp={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <div className="absolute top-[11px] left-4">
            <img src={searchIcon} alt="search" className="" />
          </div>
        </div>
        <div className="bg-white py-[8px] h-fit max-h-[288px] overflow-y-auto rounded-bl-[10px] rounded-br-[10px]">
          {countries &&
            countries.map((c) => {
              const country = parseCountry(c);
              return (
                <div
                  key={country.iso2}
                  value={country.iso2}
                  onClick={(e) => {
                    setIsOpen(false);
                    setCountry(country.iso2);
                  }}
                  className="flex cursor-pointer justify-between px-3 py-[6px] text-LightTextParagraph h-[32px] mb-2 hover:bg-LightBgSecondary text-[14px] font-OutfitFont font-medium hover:!text-LightPrimary"
                >
                  <div className="flex items-center">
                    <FlagImage
                      iso2={country.iso2}
                      className="inline-block mr-2"
                    />
                    <span className="mr-2 text-[12px] font-medium font-DMSansFont">
                      {country.name}
                    </span>
                  </div>
                  <span className="text-[14px] font-medium font-DMSansFont">
                    +{country.dialCode}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default SelectPhoneNew;
