import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import { useLocation } from 'react-router-dom';
import DeveloperTermsContent from '../../Components/Legal/LegalPages/DeveloperTermsContent';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const DeveloperTerms = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionDT');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Avnovo Developer Terms"
        description="Discover the terms governing the use of Avnovo's developer tools and APIs, ensuring developers understand their rights and responsibilities."
      />
      <LegalSideLayout>
        <DeveloperTermsContent />
      </LegalSideLayout>
    </>
  );
};

export default DeveloperTerms;
