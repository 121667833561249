import React from 'react';

const CardContainer = ({ service, minHeight, NoMinHeight, isFixWidth }) => {
  return (
    <div
      className={`border-StrokePrimary border-[1px] rounded-[20px] p-[30px] max_xl:p-5 flex flex-col items-center  ${
        isFixWidth ? 'w-[360px]' : ''
      }`}
    >
      <div>{service?.icon}</div>
      <p
        className={`paragraphText font-semibold mt-6 text-center max_xl:min-h-[66px] max_md:min-h-0 max_sm:text-textMedium ${
          minHeight ? 'min-h-[66px]' : ''
        } ${NoMinHeight ? 'max_xl:min-h-0' : ''}`}
      >
        {service?.title}
      </p>
      <p className="Text20Normal mt-3 text-center">{service?.text}</p>
    </div>
  );
};

export default CardContainer;
