import React from 'react';
import CommonSection from './CommonSection';
import { ButtonFilled } from '../../utils/Button';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const BannerWithTitleDesc = ({ title, text, isButton }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <CommonSection>
      <div className="flex flex-col items-center">
        <h1
          className="headingText text-center max-w-[920px] max_md:max-w-[524px] max_sm:max-w-[100%]"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
        <p className="Text24 mt-10 max_sm:mt-5 text-center w-[88%] max_md:w-full">
          {text}
        </p>
        {isButton && (
          <div className="mt-[50px] max_sm:mt-10 w-fit m-auto">
            <a
              href="https://calendly.com/avnovo-sales/30min"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonFilled
                title="Get a demo"
                height={isMobileView ? '40px' : '46px'}
                width={isMobileView ? '132px' : '142px'}
                rounded="30px"
                fontWeight="500"
              />
            </a>
          </div>
        )}
      </div>
    </CommonSection>
  );
};

export default BannerWithTitleDesc;
