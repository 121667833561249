import React, { useEffect, useRef, useState } from 'react';
import CommonProductComp from '../Components/Products/CommonProductComp';
import { useTranslation } from 'react-i18next';
// import AIChat from '../assets/images/Products/AI/AIChat.svg';
import BgCenterComp from '../Components/Products/BgCenterComp';
import {
  ConsistencyPrecision,
  CustomerCare,
  SwiftInfo,
} from '../assets/Icons/productsIcons';
import useVisibility from '../utils/Hooks/useVisibility';
import AITransformAndChatbot from '../Components/Products/AI/AITransformAndChatbot';
import VirtualAssistant from '../Components/Products/AI/VirtualAssistant';
import AIChatAnimation from '../animation/AI/AvnovoChat.json';
import Lottie from 'react-lottie';
import TitleDescWithKeyword from '../Components/HelmetComp/TitleDescWithKeyword';

const AIAssitantPage = () => {
  const { t } = useTranslation();
  const [animationIsInview, setAnimationIsInView] = useState(false);
  const [animationData, setAnimationData] = useState(null);
  const AIChatRef = useRef();
  const containerRef = useRef();
  const isInView = useVisibility(containerRef, 0.2);
  const [data] = useState([
    {
      value: '60%',
      text: 'increase in agent productivity was reported due to AI-powered automation, enabling agents to handle more inquiries in less time.',
      progress: '60%',
      isFullWidth: true,
    },
    {
      value: '91%',
      text: 'improvement in first-contact resolution rates was reported by businesses utilizing AI for customer service.',
      progress: '91%',
    },
    {
      value: '35%',
      text: 'reduction in customer service costs was reported by businesses implementing AI-driven tools for customer service.',
      progress: '35%',
    },
  ]);
  useEffect(() => {
    if (isInView && !animationIsInview) {
      setAnimationIsInView(true);
    }
  }, [isInView, animationIsInview]);
  const [customerToolData, setCustomerToolData] = useState([]);
  useEffect(() => {
    setCustomerToolData(getTranslatedList(t, 'knowledge_tool'));
  }, [t]);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(
          'https://images.dcdial.com/avnovo.com/AvnovoChat.json'
        );
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error('Error fetching animation data:', error);
      }
    };

    fetchAnimationData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const AIChatAnimationData = {
    loop: true,
    autoplay: true,
    animationData: AIChatAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <TitleDescWithKeyword
        title="AI Assistant | Avnovo"
        description="Enhance agent efficiency, streamline operations, and offer round-the-clock customer service with automated chatbots and voicebots for continuous interaction."
        keywords="AI-powered customer service tools, Agent productivity with AI, AI-driven customer service automation, Real-time agent guidance, First-contact resolution improvement, Reduce customer service costs with AI, Workflow automation, AI task prioritization, Prevent agent burnout with AI, AI chatbots, 24/7 chatbot availability, Instant chatbot responses, Scalable chatbot solutions, Predictive customer engagement, AI upsell and cross-sell opportunities, Real-time sentiment analysis, AI-driven data insights, Personalized customer service with AI, AI knowledge management tools, Swift information retrieval, Consistent customer support responses, Personalized customer recommendations, AI virtual assistant, AI email assistant, AI meeting scheduler, Live chat with AI, Website chatbot solutions, SMS assistant with AI, AI-generated reports and insights, Voicebots with natural language understanding"
        isPageURL="https://avnovo.com/products/ai-assistant"
        isImageURL="https://images.dcdial.com/avnovo.com/VirtualAI.svg"
      />
      <div>
        <CommonProductComp
          title={t('AIAssistant')}
          text={t('AIT')}
          data={data}
          dataTitle={t('AIPowered')}
          dataText={t('AIPoweredT')}
          id="AgentAssistanceId"
        />
        <BgCenterComp
          title={t('SpeedyAnswers')}
          text={t('SpeedyAnswersT')}
          tagText={t('KnowledgeTools')}
          background="#FFFFFF"
          data={customerToolData}
          id="KnowledgeToolId"
          noChange
        >
          <div
            className={`flex justify-center mt-[60px] w-[85%] max_md:w-full m-auto 
            ${
              !animationIsInview
                ? 'min-h-[740px] max_xl:min-h-[584px] max_md:min-h-[492px]'
                : ''
            }
              `}
            ref={containerRef}
          >
            {/* <img src={AIChat} alt="OutboundSec" className="AIChat" /> */}
            {animationIsInview && animationData && (
              <Lottie options={AIChatAnimationData} ref={AIChatRef} />
            )}
          </div>
        </BgCenterComp>

        <AITransformAndChatbot />

        <VirtualAssistant />
      </div>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  const lists = {
    knowledge_tool: [
      {
        name: t('SwiftInfo'),
        text: t('SwiftInfoT'),
        icon: <SwiftInfo />,
      },
      {
        name: t('ConsistencyPrecision'),
        text: t('ConsistencyPrecisionT'),
        icon: <ConsistencyPrecision />,
      },
      {
        name: t('CustomerCare'),
        text: t('CustomerCareT'),
        icon: <CustomerCare />,
      },
    ],
  };

  return lists[listKey];
};

export default AIAssitantPage;
