import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import ProductSpecificContent from '../../Components/Legal/LegalPages/ProductSpecificContent';
import { useLocation } from 'react-router-dom';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const SpecificProductService = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionS');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }

  return (
    <>
      <TitleAndDesc
        title="Product Specific Terms"
        description="Learn about the additional rules and conditions that apply to specific products or features offered by Avnovo in our Product Specific Terms."
      />
      <LegalSideLayout>
        <ProductSpecificContent />
      </LegalSideLayout>
    </>
  );
};

export default SpecificProductService;
