import React, { useEffect, useState } from 'react';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import { CallResolution, CostEfficiency, EfficientCall, MinimizedTimes, ScalOperations, TailoredCall } from '../../../assets/Icons/communicationIcon';
import { useTranslation } from 'react-i18next';

const SmartRouting = () => {
  const { t } = useTranslation();
  const [serviceList, setServiceList] = useState([]);
  useEffect(() => {
    setServiceList(getTranslatedList(t));
  }, [t]);
  return (
    <CommonSizeContainer isBottomMargin isTopMargin>
      <h2 className="Text50 text-center" id="SmartRoutingId">
        {t('SmartRoutingTitle')}
      </h2>
      <div className="grid grid-cols-3 gap-6 max_xl:gap-4 mt-[60px] max_600:mt-10 max_md:grid-cols-2 max_600:grid-cols-1">
        {serviceList?.map((service, index1) => (
          <div
            key={index1}
            className="bg-white rounded-[20px] p-[30px] max_xl:p-5 flex flex-col "
          >
            <div>{service.icon}</div>
            <p className="Text20Normal text-PrimaryT font-semibold mt-6 max_xl:min-h-[75px] max_md:min-h-0">
              {service.title}
            </p>
            <p className="Text16Normal mt-3 max_sm:text-textExtraSmall">
              {service.text}
            </p>
          </div>
        ))}
      </div>
    </CommonSizeContainer>
  );
};
const getTranslatedList = (t) => {
  const lists = [
    {
      title: t('EfficientCall'),
      text: t('EfficientCallT'),
      icon: <EfficientCall />,
    },
    {
      title: t('MinimizedTimes'),
      text: t('MinimizedTimesT'),
      icon: <MinimizedTimes />,
    },
    {
      title: t('CallResolution'),
      text: t('CallResolutionT'),
      icon: <CallResolution />,
    },
    {
      title: t('ScalOperations'),
      text: t('ScalOperationsT'),
      icon: <ScalOperations />,
    },
    {
      title: t('CostEfficiency'),
      text: t('CostEfficiencyT'),
      icon: <CostEfficiency />,
    },
    {
      title: t('TailoredCall'),
      text: t('TailoredCallT'),
      icon: <TailoredCall />,
    },
  ];

  return lists;
};

export default SmartRouting;
