import React, { useEffect, useState } from 'react';
import CommonProductComp from '../Components/Products/CommonProductComp';
import { useTranslation } from 'react-i18next';
import BgCenterComp from '../Components/Products/BgCenterComp';
import {
  AccessControl,
  ImprovedAccuracy,
  IntegrationCapability,
  ReactTimeUpdate,
  Scalability,
  ShiftManagement,
  UserManagement,
} from '../assets/Icons/productsIcons';
// import ListLoadImg from '../assets/images/Products/ListLoadAutomation.svg';
import FlawlessAndCustomize from '../Components/Products/CRM/FlawlessAndCustomize';
import ActivityTrack from '../Components/Products/CRM/ActivityTrack';
import ThirdpartyIntegration from '../Components/Products/CRM/ThirdpartyIntegration';
import TitleDescWithKeyword from '../Components/HelmetComp/TitleDescWithKeyword';

const CRMPage = () => {
  const { t } = useTranslation();
  const [data] = useState([
    {
      value: 'By up to 34%',
      text: 'sales productivity can increase after implementing a CRM system.',
      progress: '34%',
      isFullWidth: true,
    },
    {
      value: '37%',
      text: 'increase in revenue on average was achieved by businesses with effective CRM systems.',
      progress: '37%',
    },
    {
      value: '$8.71',
      text: 'return on average businesses can expect for every dollar spent on CRM.',
      progress: '43%',
    },
  ]);
  const [dataCRM, setDataCRM] = useState({
    listLoadData: [],
    masteringUser: [],
  });
  useEffect(() => {
    setDataCRM((prevData) => ({
      ...prevData,
      listLoadData: getTranslatedList(t, 'list_load'),
      masteringUser: getTranslatedList(t, 'mastering_user'),
    }));
  }, [t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleDescWithKeyword
        title="Contact Management | Avnovo"
        description="Streamline your contact management, simplify list imports, and sync seamlessly with preferred tools for efficient data integration."
        keywords="CRM solutions, Contact management, Centralized contact repository, Business productivity tools, Increase sales productivity, CRM system benefits, Revenue increase with CRM, ROI on CRM investment, CRM integration, Third-party application integration, List load automation, Automate contact list import, Reduce data entry errors, Scale outreach efforts, Real-time contact updates, Synchronize CRM data, Customizable CRM fields, Customer data management, Personalized customer insights, Targeted communication, Track contact activity, Customer engagement history, Optimize workforce management, User access management, Assign roles and permissions, Secure data access, Shift management, Automate payment processes, Advanced call routing, Predictive dialing features"
        isPageURL="https://avnovo.com/products/crm"
        isImageURL="https://images.dcdial.com/dcdial.com/v2/WorkFlow.svg"
      />
      <div>
        <CommonProductComp
          title={t('CRM')}
          text={t('CRMPageT')}
          data={data}
          dataTitle={t('StrategicManagement')}
          dataText={t('StrategicManagementT')}
          id="ContactManagementId"
        />
        <BgCenterComp
          title={t('ListAutomation')}
          text={t('ListAutomationText')}
          tagText={t('Features')}
          background="#FFFFFF"
          data={dataCRM.listLoadData}
          id="ListAutomationId"
        >
          <div className="flex justify-center mt-[-212px] max_md:mt-[-22px] max_md:mb-[-22px]">
            <img
              src="https://images.dcdial.com/avnovo.com/ListLoadAutomation.svg"
              alt="ListLoadAutomation"
              className=""
            />
          </div>
        </BgCenterComp>
        <FlawlessAndCustomize />
        <ActivityTrack />
        <ThirdpartyIntegration />
        <BgCenterComp
          title={t('MasteringUser')}
          text={t('MasteringUserT')}
          data={dataCRM.masteringUser}
          id="UserManagementId"
        >
          <div className="flex justify-center mt-10 max_md:mt-0 mb-10 max_md:mb-0">
            <img
              src="https://images.dcdial.com/dcdial.com/v2/WorkFlow.svg"
              alt="OptimizeWorkflow"
              className=""
            />
          </div>
        </BgCenterComp>
      </div>
    </>
  );
};

const getTranslatedList = (t, listKey) => {
  const lists = {
    list_load: [
      {
        name: t('ImprovedAccuracy'),
        text: t('ImprovedAccuracyT'),
        icon: <ImprovedAccuracy />,
      },
      {
        name: t('EnhancedScalability'),
        text: t('EnhancedScalabilityT'),
        icon: <Scalability />,
      },
      {
        name: t('IntegrationCapability'),
        text: t('IntegrationCapabilityT'),
        icon: <IntegrationCapability />,
      },
      {
        name: t('ReactTimeUpdate'),
        text: t('ReactTimeUpdateT'),
        icon: <ReactTimeUpdate />,
      },
    ],
    mastering_user: [
      {
        name: t('UserManagement'),
        text: t('UserManagementT'),
        icon: <UserManagement />,
      },
      {
        name: t('AccessControl'),
        text: t('AccessControlT'),
        icon: <AccessControl />,
      },
      {
        name: t('ShiftManage'),
        text: t('ShiftManageT'),
        icon: <ShiftManagement />,
      },
    ],
  };

  return lists[listKey];
};

export default CRMPage;
