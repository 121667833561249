import React, { useEffect } from 'react';
import BannerWithTitleDesc from '../Components/Common/BannerWithTitleDesc';
import { useTranslation } from 'react-i18next';
import LegalGroups from '../Components/Legal/LegalGroups';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const LegalPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="Avnovo Legal Repository"
        description="Find essential information on terms of service, privacy policies, and compliance guidelines. Visit now to stay informed about your legal rights and responsibilities with Avnovo."
      />
      <BannerWithTitleDesc title={t('Legal')} text={t('LegalText')} />
      <LegalGroups />
    </>
  );
};

export default LegalPage;
