import React, { useEffect, useState } from 'react';

import Menu from '../../assets/images/Navbar/menu.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonFilled } from '../../utils/Button';
import { AVLogo } from '../../assets/Icons/icon';
import RightEllipse from '../../assets/images/HomePage/RightEllipse.svg';
import RightEllipseRes from '../../assets/images/HomePage/RightERes.svg';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const Navbar = ({
  setIsMobileMenuOpen,
  navbarChangeColor,
  isImageLoaded,
  // activeLangauge,
  // setActiveLangauge,
  // handleClickScroll,
  // onChangeFun,
  // toggleModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [MenuList, setMenuList] = useState([]);
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth < 500;
  // useEffect(() => {
  //   setMenuList(getTranslatedList(t, activeLangauge));
  // }, [activeLangauge, t]);

  useEffect(() => {
    // Update lists when language changes
    setMenuList(getTranslatedList(t));
  }, [t]);

  const handleClick = (to, e) => {
    e.preventDefault(); // Prevent default navigation
    navigate(to);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50);
  };
  return (
    <div className="relative max-w-[1920px] m-auto overflow-hidden">
      {!navbarChangeColor && location.pathname === '/' && (
        <>
          {isImageLoaded && (
            <>
              <div className="absolute right-10 max_md:right-[-150px] max_sm:right-0 top-0 border-white z-0">
                <img
                  src={isTabView ? RightEllipseRes : RightEllipse}
                  alt="ellipse"
                />
              </div>
            </>
          )}
        </>
      )}
      <div className="relative z-[20] w-[100%] m-auto flex h-[66px] items-center max_md:px-10 max_sm:px-5 max-w-[1240px] max_xl:max-w-[980px]">
        <div className="FLEX_CENTER w-full justify-between ">
          <div className="anime-fade-in flex items-center justify-between cursor-pointer max_md:w-[100%]">
            <div
              className="z-40 w-[140px] max_sm:w-[90px]"
              onClick={() => {
                if (location.pathname === '/') {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                } else {
                  navigate('/');
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }, 100);
                }
              }}
            >
              {/* <img draggable="false" src={AvnovoLogo} alt="Logo" /> */}
              <AVLogo
                color={
                  navbarChangeColor
                    ? '#1D1D1D'
                    : location.pathname === '/' ||
                      location.pathname === '/features'
                    ? '#ffffff'
                    : '#1D1D1D'
                }
              />
            </div>
            <div className="hidden max_md:block">
              <div className="flex items-center gap-7 max_sm:gap-3">
                <div
                  className="cursor-pointer z-40"
                  onClick={() => {
                    setIsMobileMenuOpen(true);
                  }}
                >
                  <img src={Menu} alt="Menu" />
                </div>
              </div>
            </div>
          </div>

          <div className="relative FLEX_CENTER flex gap-x-[20px] max_md:hidden">
            {MenuList.map((menu, index) => {
              return (
                <div key={index}>
                  <NavLink
                    className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                    to={menu.link}
                    onClick={(e) => handleClick(menu.link, e)}
                  >
                    {({ isActive }) => (
                      <>
                        <div
                          className={`relative cursor-pointer  text-textSmall font-normal font-OutfitFont hover:  ${
                            isActive &&
                            (location.pathname === '/products' ||
                              location.pathname === '/solutions' ||
                              location.pathname === '/pricing')
                              ? 'text-PrimaryT'
                              : navbarChangeColor
                              ? 'text-TertiaryT hover:text-PrimaryT'
                              : location.pathname === '/' ||
                                location.pathname === '/features'
                              ? 'text-white'
                              : 'text-TertiaryT hover:text-PrimaryT'
                          }`}
                          // ref={outSideRef}
                        >
                          {menu.name}
                          {isActive &&
                            (location.pathname === '/products' ||
                              location.pathname === '/solutions' ||
                              location.pathname === '/pricing') && (
                              <div className="option absolute bottom-[-1px] left-0 h-[2px] rounded-[18px] w-full bg-ButtonP"></div>
                            )}
                        </div>
                      </>
                    )}
                  </NavLink>
                </div>
              );
            })}
            {/* Popup menu  */}

            <div
              className={`FLEX_CENTER text-textSmall font-normal font-OutfitFont cursor-pointer ${
                navbarChangeColor
                  ? 'text-TertiaryT hover:text-PrimaryT'
                  : location.pathname === '/' ||
                    location.pathname === '/features'
                  ? 'text-white'
                  : 'text-TertiaryT hover:text-PrimaryT'
              }`}
              onClick={() => navigate('/sign-in')}
            >
              {/* <a
                href="https://www.avnovo.com/sign-in/"
                rel="noopener noreferrer"
                target="_blank"
              > */}
              {t('SignIn')}
              {/* </a>{' '} */}
            </div>

            <div className="z-[60]">
              <ButtonFilled
                title={t('GetStarted')}
                fontSize="16px"
                rounded="60px"
                onClick={() => navigate('/contact/get-in-touch')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      name: t('Products'),
      link: '/products',
      langauge: false,
    },
    {
      name: t('Solutions'),
      link: '/solutions',
      langauge: false,
    },
    {
      name: t('Pricing'),
      link: '/pricing',
      langauge: false,
    },
  ];

  return lists;
};
export default Navbar;
