import React, { useEffect } from 'react';
import LegalSideLayout from '../../Components/Legal/LegalSideLayout';
import WebTermsOfUseContent from '../../Components/Legal/LegalPages/WebTermsOfUseContent';
import { useLocation } from 'react-router-dom';
import TitleAndDesc from '../../Components/HelmetComp/TitleAndDesc';

const WebTermsOfUse = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (state && state !== null) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  var scrollPosition = localStorage.getItem('scrollPositionWT');
  if (scrollPosition !== null) {
    window.scrollTo(0, parseInt(scrollPosition));
  }
  return (
    <>
      <TitleAndDesc
        title="Website Terms of Use"
        description="By accessing Avnovo's website, you agree to abide by our Website Terms of Use, which govern your use of our site and its content."
      />
      <LegalSideLayout>
        <WebTermsOfUseContent />
      </LegalSideLayout>
    </>
  );
};

export default WebTermsOfUse;
