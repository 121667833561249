import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import CommonSizeContainer from '../../Common/CommonSizeContainer';
import CommonAppsComp from '../CommonAppsComp';
import {
  HubspotIcon,
  SalesforceIcon,
  StripeIcon,
} from '../../../assets/Icons/integrationIcon';

const ThirdpartyIntegration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appList, setAppList] = useState([]);
  const [hoverIndex, setHoverIndex] = useState(null);
  useEffect(() => {
    setAppList(getTranslatedList(t, 'appList'));
  }, [t]);
  return (
    <CommonSizeContainer>
      <div
        className="flex flex-col gap-[60px] max_sm:gap-10 pt-14 max_sm:pt-8"
        id="ThirdPartyIntId"
      >
        <div className="flex items-center flex-col gap-4">
          <h2 className="Text50 max_sm:text-text2Xl">
            {t('ThirdPartyIntegration')}
          </h2>
          <p className="paragraphText text-TertiaryT text-center px-5">
            {t('ThirdPartyText')}
          </p>
          <div>
            <div
              className="max_sm:mt-3 cursor-pointer rounded-[60px] bg-white border-2 border-ButtonP px-4 py-[11px] max_sm:py-[10px] smallText18L font-medium text-ButtonP max_sm:text-textSmall"
              onClick={() => navigate('/apps-payments')}
            >
              View all integrations
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-[28px] max_md:grid-cols-2 max_600:grid-cols-1 max_600:gap-[30px]">
          {appList?.map((app, index1) => {
            return (
              <React.Fragment key={index1}>
                {/* <a href={app.link} rel="noopener noreferrer" target="_blank"> */}
                <CommonAppsComp
                  index={index1}
                  app={app}
                  hoverIndex={hoverIndex}
                  setHoverIndex={setHoverIndex}
                  isLearnMore
                  isCRM
                />
                {/* </a> */}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </CommonSizeContainer>
  );
};
const getTranslatedList = (t, listkey) => {
  const lists = {
    appList: [
      {
        name: 'Hubspot',
        icon: <HubspotIcon />,
        description: t('HubspotDesc'),
        tags: [t('Marketing'), t('Automation')],
        bgColor: '#FFFBFA',
        color: '#FF7A59',
        link: '/apps-payments',
      },
      {
        name: 'Salesforce',
        icon: <SalesforceIcon />,
        description: t('SalesforceDesc'),
        tags: [t('CRM')],
        bgColor: '#E8F8FF',
        color: '#00A1E0',
        link: '/apps-payments',
      },
      {
        name: 'Stripe',
        icon: <StripeIcon />,
        description: t('StripeDesc'),
        tags: [t('PaymentProcessing')],
        bgColor: '#F5F4FF',
        color: '#635BFF',
        link: '/apps-payments',
      },
    ],
  };
  return lists[listkey];
};

export default ThirdpartyIntegration;
