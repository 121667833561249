import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BannerWithTitleDesc from '../Components/Common/BannerWithTitleDesc';
import Plans from '../Components/Pricing/Plans';
import AddOns from '../Components/Pricing/AddOns';
import WhatIncludes from '../Components/Pricing/WhatIncludes';
import FAQComponent from '../Components/Common/FAQComponent';
import ContinueWithAV from '../Components/Pricing/ContinueWithAv';
import { useNavigate } from 'react-router-dom';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const PricingPage = () => {
  const { t } = useTranslation();
  const [FAQList, setFAQList] = useState([]);
  useEffect(() => {
    setFAQList(getTranslatedList(t));
  }, [t]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <TitleAndDesc
        title="Avnovo | Pricing & Plans"
        description="Discover the perfect plan to suit your business needs and budget, with no hidden fees. Start optimizing your financial workflows with Avnovo today!"
        isPageURL="https://avnovo.com/pricing"
      />
      <div>
        <BannerWithTitleDesc
          title={t('ChoosePlan')}
          text={t('ChoosePlanText')}
        />
        <Plans />
        <AddOns />
        <WhatIncludes />

        <FAQComponent isMargin={true} data={FAQList} viewAll />
        <ContinueWithAV
          title={t('ReadyToStart')}
          subTitle1={t('ReadyToStartText')}
          BtnText1={t('StartNow')}
          BtnClick1={() => navigate('/contact/get-in-touch')}
        />
      </div>
    </>
  );
};

const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      question: t('PricingQue1'),
      answer: t('PricingAns1'),
    },
    {
      question: t('PricingQue2'),
      answer: t('PricingAns2'),
    },
    {
      question: t('PricingQue3'),
      answer: t('PricingAns3'),
    },
    {
      question: t('PricingQue4'),
      answer: t('PricingAns4'),
    },
    {
      question: t('PricingQue5'),
      answer: t('PricingAns5'),
    },
  ];

  return lists;
};

export default PricingPage;
