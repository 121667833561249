import React, { useState } from 'react';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { useTranslation } from 'react-i18next';
import { TickCircle } from '../../assets/Icons/securityIcons';
import PCIImage from '../../assets/images/Security/PCIImg.svg';
import { ButtonWithArrowIcon } from '../../utils/Button';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const OnlinePaymentSecurity = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [onlinePService] = useState([
    t('OnlinePService1'),
    t('OnlinePService2'),
    t('OnlinePService3'),
    t('OnlinePService4'),
    t('OnlinePService5'),
    t('OnlinePService6'),
  ]);

  return (
    <>
      <div className="bg-PrimaryT clip-path-class py-[180px] max_xl:py-[140px] max_md:py-[100px] mt-[-100px] max_md:mt-[-50px]">
        <CommonSizeContainer>
          <div className="flex justify-between items-center gap-6">
            <div className="flex flex-col gap-6 max-w-[550px] mt-2 max_xl:mt-0 max_md:max-w-full">
              <p className="Text20Normal font-medium text-DisableText">
                {t('OnlinePaymentsSecurity')}
              </p>
              <h2 className="Text50 text-white">{t('AvPCI-DSS')}</h2>
              <p className="paragraphText text-white">{t('AvPCI-DSS-Text')}</p>
              <div className="flex justify-between max_md:gap-2">
                <div className="flex flex-col gap-4">
                  {onlinePService.map((service, index) => (
                    <div className="flex gap-2" key={index}>
                      <div>
                        <TickCircle
                          style={{
                            width: isMobileView ? '20' : '28',
                            height: isMobileView ? '20' : '28',
                          }}
                        />
                      </div>
                      <p className="smallText18L text-white font-medium max_sm:text-textExtraSmall">
                        {service}
                      </p>
                    </div>
                  ))}
                  <div className="w-max mt-2">
                    <ButtonWithArrowIcon
                      title="Learn More"
                      rounded="60px"
                      isBackground
                      icon
                      arrowColor="#ffffff"
                    />
                  </div>
                </div>
                <div className="hidden max_md:block w-[40%] max_720:hidden">
                  <div className="">
                    <img src={PCIImage} alt="PCI_Img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="max_md:hidden">
              <div>
                <img src={PCIImage} alt="PCI_Img" />
              </div>
            </div>
          </div>
        </CommonSizeContainer>
      </div>
    </>
  );
};

export default OnlinePaymentSecurity;
