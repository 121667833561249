import React, { useState } from 'react';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { ButtonOutLine } from '../../utils/Button';
import { useNavigate } from 'react-router-dom';

const Plans = () => {
  const navigate = useNavigate()
  const [addOnInfo] = useState([
    {
      name: 'Pearl',
      value: '$0.00',
      additionalService: [
        'Card 3.5% + 50¢',
        'Bank 2% (capped @ $20)',
        'Wire $20',
      ],
      revenu: 'per successful transaction',
      isRecommended: true,
      buttonText: 'Get Pearl',
    },
    {
      name: 'Core',
      value: '$599',
      additionalService: ['+0.9% of revenue'],
      revenu: 'Annual revenue under $2.4M',
      isRecommended: false,
      buttonText: 'Get Core',
    },
    {
      name: 'Professional',
      value: '$1,499',
      additionalService: ['+0.7% of revenue'],
      revenu: 'Annual revenue $2.4M - $6M',
      isRecommended: false,
      buttonText: 'Get Professional',
    },
    {
      name: 'Diamond',
      value: '$3,499',
      additionalService: ['+0.5% of revenue'],
      revenu: 'Annual revenue over $6M',
      isRecommended: false,
      buttonText: 'Get Diamond',
    },
  ]);
  return (
    <CommonSizeContainer>
      <div className="flex gap-[26px] max_xl:gap-2 max_md:gap-10 max_md:flex-wrap max_md:justify-center max_md:mt-4">
        {addOnInfo.map((addOnDetails, index) => {
          return (
            <React.Fragment key={index}>
              <div
                className={`relative p-5 rounded-b-[20px] bg-white w-[290px] max_720:w-[266px] max_600:w-[290px] ${
                  addOnDetails.isRecommended
                    ? 'border-inner'
                    : 'rounded-t-[20px]'
                }`}
              >
                {addOnDetails.isRecommended && (
                  <div className="absolute w-full top-[-34px] left-0 rounded-t-[20px] bg-ButtonP FLEX_CENTER text-textNormal font-OutfitFont text-white py-1">
                    Use Avnovo processor
                  </div>
                )}
                <div className="flex flex-col gap-3">
                  <p className="text-textMedium font-OutfitFont font-normal text-PrimaryT max_xl:min-h-[48px]">
                    {addOnDetails.name}
                  </p>
                  <p className="Text30 text-PrimaryT">
                    {addOnDetails.value}
                    <span className="text-[14px] font-semibold text-PrimaryT ml-1">
                      /month
                    </span>
                  </p>
                </div>
                <div className="mt-4">
                  <div className="flex flex-col gap-y-2 min-h-24">
                    {addOnDetails.additionalService.map((services, index1) => {
                      return (
                        <React.Fragment key={index1}>
                          <p className="text-textNormal font-OutfitFont font-semibold text-ButtonP">
                            {services}
                          </p>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <p className="mt-4 text-textNormal font-OutfitFont font-semibold text-TertiaryT">
                  {addOnDetails.revenu}
                </p>
                <div className="mt-5">
                  <ButtonOutLine
                    title={addOnDetails.buttonText}
                    rounded="60px"
                    isFilled={addOnDetails.isRecommended}
                    background="#484540"
                    onClick={() => navigate('/contact/get-in-touch')}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </CommonSizeContainer>
  );
};

export default Plans;
