import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import BgAnimatedLayer from './BgAnimatedLayer';

const ContinueWithAV = ({
  title,
  subTitle1,
  isbreakable,
  subTitle2,
  link,
  isTopMargin,
  buttonText,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation()
  return (
    <div
      className={`${
        isTopMargin ? 'mt-[150px] max_md:mt-[100px] max_sm:mt-[60px]' : ''
      } relative w-full h-[550px] max_md:h-[500px] max_sm:h-[450px] flex justify-center items-center overflow-hidden z-0 bg-PrimaryT`}
    >
      <div className="flex flex-col items-center gap-[40px] max_md:gap-6 z-50">
        <h2 className="Text50 max_md:text-text2Xl max_md:leading-[34px] max_sm:text-[22px] max_sm:leading-[30px] text-white max_sm:px-5 text-center px-5">
          {title}
        </h2>
        <p
          className={`text-center text-DisableText Text24 max_md:px-10 max_sm:px-5 ${
            location.pathname === '/security'
              ? 'max-w-[1024px]'
              : 'max-w-[980px]'
          } ${isbreakable ? 'max_md:hidden' : ''}`}
          dangerouslySetInnerHTML={{ __html: subTitle1 }}
        ></p>
        {/* {isbreakable && ( */}
        <p
          className={`hidden text-center paragraphText max_md:px-10 max_sm:px-5 ${
            isbreakable ? 'max_md:block' : ''
          }`}
        >
          {subTitle2}
        </p>
        {/* )} */}

        {link ? (
          <>
            <button
              className="rounded-[60px] text-center cursor-pointer bg-ButtonP"
              onClick={() => {
                navigate(link);
              }}
            >
              <div className="text-white text-textNormal font-OutfitFont font-medium p-4 max_sm:p-3">
                {buttonText}
              </div>
            </button>
          </>
        ) : (
          <>
            <button className="rounded-[60px] text-center cursor-pointer bg-ButtonP">
              <a
                href="https://calendly.com/avnovo-sales/30min"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="text-white text-textNormal font-OutfitFont font-medium p-4 max_sm:p-3">
                  {buttonText}
                </div>
              </a>
            </button>
          </>
        )}
      </div>
      <div className="animated-layer absolute w-[1440px] h-full max_md:w-[100%]">
        <BgAnimatedLayer />
      </div>
    </div>
  );
};

export default ContinueWithAV;
