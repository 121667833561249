export const ExploreArrow = (props) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_513_38934)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.7032 4.16137L2.43216 9.55298C2.02447 9.67965 2.20618 10.342 2.70123 10.5329L10.9467 13.7144L14.128 21.9597C14.3192 22.4541 14.9805 22.6357 15.1076 22.2285L20.4993 4.95746C20.5583 4.74937 20.4927 4.56252 20.2951 4.36539C20.0976 4.16814 19.8798 4.1052 19.7032 4.16137ZM17.902 5.90991L11.0388 12.7731L4.277 10.1638L17.902 5.90991Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_513_38934">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export const LargeRightBg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="401"
        height="568"
        viewBox="0 0 401 568"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M165.067 35.3553C184.593 15.8291 216.251 15.8291 235.777 35.3553L365.067 164.645C384.593 184.171 384.593 215.829 365.067 235.355L235.777 364.645C216.251 384.171 184.593 384.171 165.067 364.645L35.7772 235.355C16.251 215.829 16.251 184.171 35.7772 164.645L165.067 35.3553Z"
          fill="#666666"
        />
        <path
          d="M239.209 389.213C250.924 377.497 269.919 377.497 281.635 389.213L339.209 446.787C350.924 458.503 350.924 477.497 339.209 489.213L281.635 546.787C269.919 558.503 250.924 558.503 239.209 546.787L181.635 489.213C169.919 477.497 169.919 458.503 181.635 446.787L239.209 389.213Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const SmallRightBg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="207"
        height="210"
        viewBox="0 0 207 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M56.2804 14.1421C64.0909 6.33165 76.7542 6.33165 84.5647 14.1421L126.703 56.2804C134.513 64.0909 134.513 76.7542 126.703 84.5647L84.5647 126.703C76.7542 134.513 64.0909 134.513 56.2804 126.703L14.1421 84.5647C6.33165 76.7542 6.33165 64.0909 14.1421 56.2804L56.2804 14.1421Z"
          fill="#666666"
        />
        <path
          d="M165.89 145.234C169.015 142.11 174.08 142.11 177.204 145.234L201.102 169.132C204.226 172.256 204.226 177.321 201.102 180.446L177.204 204.343C174.08 207.467 169.015 207.467 165.89 204.343L141.993 180.446C138.869 177.321 138.869 172.256 141.993 169.132L165.89 145.234Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const LargeLeftBg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="400"
        height="568"
        viewBox="0 0 400 568"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M164.645 35.3553C184.171 15.8291 215.829 15.8291 235.355 35.3553L364.645 164.645C384.171 184.171 384.171 215.829 364.645 235.355L235.355 364.645C215.829 384.171 184.171 384.171 164.645 364.645L35.3553 235.355C15.8291 215.829 15.8291 184.171 35.3553 164.645L164.645 35.3553Z"
          fill="#666666"
        />
        <path
          d="M108.787 389.213C120.503 377.497 139.497 377.497 151.213 389.213L208.787 446.787C220.503 458.503 220.503 477.497 208.787 489.213L151.213 546.787C139.497 558.503 120.503 558.503 108.787 546.787L51.2132 489.213C39.4975 477.497 39.4975 458.503 51.2132 446.787L108.787 389.213Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const SmallLeftBg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="355"
        height="349"
        viewBox="0 0 355 349"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M180.145 35.3554C199.671 15.8291 231.329 15.8291 250.855 35.3553L319.645 104.145C339.171 123.671 339.171 155.329 319.645 174.855L250.855 243.645C231.329 263.171 199.671 263.171 180.145 243.645L111.355 174.855C91.8291 155.329 91.8291 123.671 111.355 104.145L180.145 35.3554Z"
          fill="#666666"
        />
        <path
          d="M60.2868 207.213C72.0025 195.497 90.9975 195.497 102.713 207.213L141.787 246.287C153.503 258.003 153.503 276.997 141.787 288.713L102.713 327.787C90.9975 339.503 72.0025 339.503 60.2868 327.787L21.2132 288.713C9.4975 276.997 9.49747 258.003 21.2132 246.287L60.2868 207.213Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const Image7Bg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="166"
        height="200"
        viewBox="0 0 166 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M63.4036 13.6151C70.923 6.09569 83.1144 6.09569 90.6338 13.6151L140.422 63.4036C147.942 70.923 147.942 83.1144 140.422 90.6338L90.6338 140.422C83.1144 147.942 70.923 147.942 63.4036 140.422L13.6151 90.6338C6.09569 83.1144 6.09569 70.923 13.6151 63.4036L63.4036 13.6151Z"
          fill="#666666"
        />
        <path
          d="M119.325 130.526C123.836 126.015 131.151 126.015 135.663 130.526L157.834 152.698C162.346 157.209 162.346 164.524 157.834 169.036L135.663 191.207C131.151 195.719 123.836 195.719 119.325 191.207L97.1534 169.036C92.6418 164.524 92.6418 157.209 97.1534 152.698L119.325 130.526Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const Image6Bg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="166"
        height="200"
        viewBox="0 0 166 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M63.4036 13.6151C70.923 6.09569 83.1144 6.09569 90.6338 13.6151L140.422 63.4036C147.942 70.923 147.942 83.1144 140.422 90.6338L90.6338 140.422C83.1144 147.942 70.923 147.942 63.4036 140.422L13.6151 90.6338C6.09569 83.1144 6.09569 70.923 13.6151 63.4036L63.4036 13.6151Z"
          fill="#666666"
        />
        <path
          d="M119.325 130.526C123.836 126.015 131.151 126.015 135.663 130.526L157.834 152.698C162.346 157.209 162.346 164.524 157.834 169.036L135.663 191.207C131.151 195.719 123.836 195.719 119.325 191.207L97.1534 169.036C92.6418 164.524 92.6418 157.209 97.1534 152.698L119.325 130.526Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const Image5Bg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="338"
        height="306"
        viewBox="0 0 338 306"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M108.665 23.8946C121.553 11.0073 142.447 11.0073 155.335 23.8946L240.665 109.226C253.553 122.113 253.553 143.007 240.665 155.895L155.335 241.226C142.447 254.113 121.553 254.113 108.665 241.226L23.3345 155.895C10.4472 143.007 10.4472 122.113 23.3345 109.226L108.665 23.8946Z"
          fill="#666666"
        />
        <path
          d="M257.601 187.441C265.333 179.708 277.87 179.708 285.602 187.441L323.601 225.439C331.333 233.172 331.333 245.708 323.601 253.441L285.602 291.439C277.87 299.172 265.333 299.172 257.601 291.439L219.602 253.441C211.87 245.708 211.87 233.172 219.602 225.439L257.601 187.441Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const Image4Bg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="258"
        height="309"
        viewBox="0 0 258 309"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M98.265 21.1012C109.919 9.44734 128.814 9.44731 140.467 21.1012L217.631 98.265C229.285 109.919 229.285 128.814 217.631 140.467L140.467 217.631C128.814 229.285 109.919 229.285 98.265 217.631L21.1012 140.467C9.44734 128.814 9.44731 109.919 21.1012 98.265L98.265 21.1012Z"
          fill="#666666"
        />
        <path
          d="M184.929 202.295C191.921 195.302 203.258 195.302 210.25 202.294L244.612 236.656C251.604 243.648 251.604 254.985 244.612 261.978L210.25 296.339C203.258 303.332 191.921 303.332 184.929 296.339L150.567 261.978C143.575 254.985 143.575 243.648 150.567 236.656L184.929 202.295Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
export const Image3Bg = (props) => {
  return (
    <>
      <svg
        {...props}
        width="161"
        height="242"
        viewBox="0 0 161 242"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M66.0898 14.1919C73.9277 6.35396 86.6356 6.35394 94.4736 14.1919L146.371 66.0898C154.209 73.9277 154.209 86.6356 146.371 94.4736L94.4736 146.371C86.6357 154.209 73.9277 154.209 66.0898 146.371L14.1919 94.4736C6.35396 86.6357 6.35394 73.9277 14.1919 66.0898L66.0898 14.1919Z"
          fill="#666666"
        />
        <path
          d="M95.8522 170.233C100.555 165.531 108.18 165.531 112.883 170.233L135.993 193.344C140.696 198.047 140.696 205.671 135.993 210.374L112.883 233.485C108.18 238.188 100.555 238.188 95.8522 233.485L72.7417 210.374C68.0389 205.671 68.0389 198.047 72.7417 193.344L95.8522 170.233Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};
