import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';
import CookiesConsent from '../Components/Cookies/CookiesContent';
import ModalCustom from '../Components/Common/ModalCustom';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import ManageCookies from '../Components/Cookies/ManageCookies';
import { CSSTransition } from 'react-transition-group';
import MobileMenu from './MobileMenu';

const Layout = ({ children, isImageLoaded }) => {
  const location = useLocation();
  const nodeRef = useRef(null);
  const outsideRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manageClicked, setManageClicked] = useState(false);
  const [cookies, setCookie] = useCookies([
    'cookie_consent',
    'Analytics_Cookies',
    'Functional_Cookies',
    'Targeting_Cookies',
  ]);

  // useEffect(() => {
  //   const getCookies = Cookies.get('DC_COOKIE_CONSENT');
  //   if (!getCookies) {
  //     setIsModalOpen(true);
  //   }
  // }, []);
  useEffect(() => {
    if (cookies.cookie_consent === undefined) {
      setIsModalOpen(true);
    }
    // setCookie('cookie_consent',true);
  }, []);

  const handleSelection = (id, data) => {
    if (id === 'accept') {
      setCookie('cookie_consent', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'reject') {
      setCookie('cookie_consent', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'allow-all') {
      setCookie('cookie_consent', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Analytics_Cookies', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Functional_Cookies', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Targeting_Cookies', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'reject-all') {
      setCookie('cookie_consent', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Analytics_Cookies', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Functional_Cookies', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('Targeting_Cookies', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else {
      setCookie('cookie_consent', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      data.forEach((cookie) => {
        if (cookie.checked) {
          setCookie(cookie.id, true, {
            expires: moment().add(1, 'months').toDate(),
          });
        } else {
          setCookie(cookie.id, false, {
            expires: moment().add(1, 'months').toDate(),
          });
        }
      });
      setIsModalOpen(false);
    }
  };

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', changeBackground);
      // cleanup function
      return () => {
        window.removeEventListener('scroll', changeBackground);
      };
    }
  }, []);
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = React.useState(null);

    React.useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? 'down' : 'up';
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener('scroll', updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener('scroll', updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden !important';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isModalOpen && location.pathname !== '/legal/cookie-policy') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen, location.pathname]);
  return (
    <>
      <div className="relative w-full">
        <div
          className={`sticky z-40 ${
            scrollDirection === 'down' ? '-top-24' : 'top-0'
          } transition-all duration-500`}
        >
          <div
            className={`relative m-auto w-full ${
              navbarChangeColor
                ? 'bg-white shadow-sm transition'
                : location.pathname === '/' || location.pathname === '/features'
                ? 'bg-PrimaryT bg-transparent'
                : 'bg-transparent transition bg-WebRootColor'
            }`}
          >
            <Navbar
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              navbarChangeColor={navbarChangeColor}
              isImageLoaded={isImageLoaded}
              // activeLangauge={activeLangauge}
              // setActiveLangauge={setActiveLangauge}
              // isModalOpen={isModalOpen}
            />

            <CSSTransition
              in={isMobileMenuOpen}
              nodeRef={nodeRef}
              timeout={300}
              classNames="menu"
              type={'out-in' | 'in-out'}
              unmountOnExit
              onEnter={() => setIsMobileMenuOpen(true)}
              onExited={() => setIsMobileMenuOpen(false)}
            >
              <MobileMenu
                nodeRef={nodeRef}
                outsideRef={outsideRef}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                // activeLangauge={activeLangauge}
              />
            </CSSTransition>
          </div>
        </div>
        <div>{children}</div>
        <div>
          <Footer />
        </div>
      </div>
      <ModalCustom
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        manageClicked={manageClicked}
      >
        {manageClicked ? (
          <>
            <ManageCookies
              setManageClicked={setManageClicked}
              handleSelection={handleSelection}
            />
          </>
        ) : (
          <>
            <CookiesConsent
              handleReject={() => setIsModalOpen(false)}
              handleManage={() => setManageClicked(true)}
              handleSelection={handleSelection}
            />
          </>
        )}
      </ModalCustom>
    </>
  );
};

export default Layout;
