import React from 'react'

const CommonSizeContainer = ({ children,isTopMargin,isBottomMargin,isTopPadding,isBottomPadding,id }) => {
  return (
    <div
      id={id}
      className={`max-w-[1240px] max_xl:max-w-[980px] m-auto max_md:px-10 max_sm:px-5 ${
        isTopMargin ? 'mt-[150px] max_md:mt-[100px] max_sm:mt-[60px]' : ''
      } ${
        isBottomMargin ? 'mb-[150px] max_md:mb-[100px] max_sm:mb-[60px]' : ''
      } ${
        isTopPadding ? 'pt-[150px] max_md:pt-[100px] max_sm:pt-[60px]' : ''
      } ${
        isBottomPadding ? 'pb-[150px] max_md:pb-[100px] max_sm:pb-[60px]' : ''
      }`}
    >
      {children}
    </div>
  );
};

export default CommonSizeContainer