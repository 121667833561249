import React, { useEffect, useState } from 'react';
import AVLogo from '../assets/images/Navbar/Avnovo logo.svg';
import CloseIcon from '../assets/images/Navbar/CloseIcon.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonFilled } from './Button';

const MobileMenu = ({
  setIsMobileMenuOpen,
  outsideRef,
  nodeRef,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [MenuList, setMenuList] = useState([]);
  useEffect(() => {
    setMenuList(getTranslatedList(t));
  }, [t]);

  return (
    <div
      ref={nodeRef}
      className="z-[999] fixed top-0 flex h-screen w-[100vw] !overflow-hidden"
    >
      <div ref={outsideRef} className="w-full bg-white">
        <div className="mobilemenu-shadow FLEX_CENTER h-[66px] justify-between px-[40px] max_sm:pl-[20px] max_sm:pr-[15px] w-[100%]">
          <div className="w-[140px] max_sm:w-[100px]">
            <img draggable="false" src={AVLogo} alt="Logo" />
          </div>
          <div className="flex items-center gap-2">
            <div className="FLEX_CENTER text-textExtraSmall font-OutfitFont font-medium text-PrimaryT cursor-pointer">
              <a
                href="https://www.avnovo.com/sign-in/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('SignIn')}
              </a>
            </div>

            <div className="z-[60]">
              <ButtonFilled
                title={t('GetStarted')}
                fontSize="14px"
                fontWeight="500"
                onClick={() => navigate('/contact/get-in-touch')}
              />
            </div>

            <div
              className="cursor-pointer "
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <img draggable="false" src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
        </div>

        <div className="w-full bg-white h-[calc(100vh-66px)] z-[100] overflow-y-scroll mt-5">
          {MenuList.map((menu, index) => (
            <React.Fragment key={index}>
              <NavLink
                className={`flex w-full items-center border-[#00000033] no-underline`}
                to={menu.link}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {({ isActive }) => (
                  <div className="flex w-full flex-col">
                    <div
                      className={`flex gap-2 items-center w-full px-[30px] py-5`}
                    >
                      <div
                        className={`cursor-pointer text-TertiaryT text-textMedium font-medium font-OutfitFont hover:text-PrimaryT `}
                      >
                        {menu.name}
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      name: t('Products'),
      link: '/products',
      langauge: false,
    },
    {
      name: t('Solutions'),
      link: '/solutions',
      langauge: false,
    },
    {
      name: t('Pricing'),
      link: '/pricing',
      langauge: false,
    },
  ];

  return lists;
};

export default MobileMenu;
