import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonFilled, ButtonOutLine } from '../../utils/Button';

const CookiesConsent = ({ handleReject, handleManage, handleSelection }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center gap-5 max_md:flex-col max_md:items-start">
      <div className="max-w-[70%] max_md:max-w-full">
        <p className="Text16Normal mt-4 text-white">
          {t('CookieText')}{' '}
          <span
            className="cursor-pointer smallText16 text-ButtonPrimary underline"
            onClick={() => navigate('/legal/cookie-policy')}
          >
            {t('CookiePolicy')}
          </span>
        </p>
      </div>

      <div className="flex gap-3 items-center max_md:justify-between max_md:w-full max_sm:flex-col-reverse max_sm:gap-5">
        <p
          className="cursor-pointer underline Text18Price font-medium text-white w-max h-max"
          onClick={handleManage}
        >
          {t('ManageCookies')}
        </p>
        <div className="flex gap-3 items-center max_sm:gap-[6px]">
          <ButtonOutLine
            width="98px"
            fontSize="16px"
            title={t('Reject')}
            onClick={() => handleSelection('reject')}
            rounded="60px"
            fontColor="#ffffff"
          />
          <ButtonFilled
            title={t('AcceptAll')}
            rounded="60px"
            fontSize="16px"
            isFilled={true}
            background="#ffffff"
            width="116px"
            fontColor="#484540"
            onClick={() => handleSelection('accept')}
          />
        </div>
      </div>
    </div>
  );
};

export default CookiesConsent;
