import React, { useEffect } from 'react';
import SecurityBanner from '../Components/Security/SecurityBanner';
import SecurityStandard from '../Components/Security/SecurityStandard';
import OnlinePaymentSecurity from '../Components/Security/OnlinePaymentSecurity';
import HandleCustomerData from '../Components/Security/HandleCustomerData';
import DataHandling from '../Components/Security/DataHandling';
import ProtectingData from '../Components/Security/ProtectingData';
import ContinueWithAV from '../Components/Common/ContinueWithAv';
import { useTranslation } from 'react-i18next';
import TitleAndDesc from '../Components/HelmetComp/TitleAndDesc';

const SecurityPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleAndDesc
        title="Security"
        description="Explore Avnovo's commitment to security and learn about our advanced measures to protect your data, including encryption, compliance, and continuous monitoring."
      />
      <div>
        <SecurityBanner />
        <SecurityStandard />
        <OnlinePaymentSecurity />
        <HandleCustomerData />
        <DataHandling />
        <ProtectingData />
        <ContinueWithAV
          title={t('SecurityQues')}
          subTitle1={t('SecurityQuesT')}
          buttonText={t('ContactUsL')}
          link={'/contact/get-in-touch'}
        />
      </div>
    </>
  );
};

export default SecurityPage;
