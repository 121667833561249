import React from 'react'
import { SeeMoreArrow } from '../../assets/Icons/icon';

const LearnMoreComp = ({fontWeight,fontSize}) => {
  return (
    <div className="flex gap-2 items-center">
      <p
        className="text-textSmall font-OutfitFont font-medium text-ButtonP"
        style={{
          fontWeight: fontWeight ? fontWeight : '',
          fontSize: fontSize ? fontSize : '',
        }}
      >
        Learn more
      </p>
      <div className="max_sm_380:mt-[2px] flex items-center">
        <SeeMoreArrow />
      </div>
    </div>
  );
}

export default LearnMoreComp