import React from 'react';
import { TextField } from '../../Components/Common/InputFields';
import { ButtonFilled } from '../../utils/Button';
import { useNavigate } from 'react-router-dom';
import { ErrorIcon } from '../../assets/Icons/icon';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const EmailPassword = ({
  userInput,
  handleChange,
  error,
  isContinueDisable,
  onSignIn,
  showErr,
}) => {
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <>
      <div>
        <TextField
          type="text"
          placeholder="Enter your email"
          name="email"
          label="Email"
          value={userInput.email}
          handleOnChange={handleChange}
          error={error?.email}
          errorMsg="Please enter a valid email address"
        />

      </div>
      <div>
        <TextField
          type="password"
          placeholder="Enter your password"
          name="password"
          label="Password"
          value={userInput.password}
          handleOnChange={handleChange}
          error={error.password}
        />
        {showErr && (
          <div className="flex gap-1 items-center mt-[1px]">
            <ErrorIcon />
            <p className="font-OutfitFont text-[#ff0000] text-textExtraSmall font-normal ml-[2px] max_sm:ml-0 mt-[1px]">
              The email address or password you entered is incorrect.
            </p>
          </div>
        )}
        <div className="mt-1 flex justify-end">
          <p className="w-max text-textExtraSmall font-OutfitFont font-semibold text-PrimaryT cursor-pointer max_sm:text-[12px]">
            Forgot your password?
          </p>
        </div>
      </div>

      <div className="mt-6">
        <ButtonFilled
          title="Sign in"
          fontSize={isMobileView? "16px":"18px"}
          fontWeight="500"
          height={isMobileView ?'40px':'48px'}
          disabled={isContinueDisable}
          onClick={onSignIn}
          // isLoading={isLoading}
        />
      </div>
      <div className="mt-4 font-OutfitFont text-textExtraSmall flex gap-[5px] items-center justify-center">
        <span className="text-TertiaryT font-medium ">
          Don’t have an account?
        </span>

        <button
          className="text-PrimaryT font-semibold"
          onClick={() => {
            navigate('/contact/get-in-touch');
          }}
        >
          Sign up
        </button>
      </div>
    </>
  );
};

export default EmailPassword;
