import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccessIcon,
  ContingencyIcon,
  ControlIcon,
  TransparencyIcon,
} from '../../assets/Icons/securityIcons';
import { useWindowSize } from '../../utils/Hooks/useWindowSize';

const DataHandling = () => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [dataHandlingSer, setDataHandlingSer] = useState([]);
  useEffect(() => {
    setDataHandlingSer(getTranslatedList(t));
  }, [t]);

  return (
    <div className="max-w-[1240px] max_xl:max-w-[980px] m-auto pt-[100px] max_md:pt-10 max_sm:pt-[0px] max_md:px-10 max_sm:px-5">
      <div className="flex justify-between mb-[50px] max_md:mb-10 max_md:flex-col max_md:gap-[60px] max_sm:gap-10">
        <div className="max-w-[440px] max_xl:max-w-[392px] max_md:max-w-full">
          <h2 className="Text50">{t('AVDataHandling')}</h2>
          <p className="mt-4 paragraphText text-TertiaryT">
            {t('AVDataHandlingText')}
          </p>
        </div>
        <div className="flex flex-wrap gap-[50px] max-w-[550px] max_xl:gap-[30px] max_sm:gap-10 max_xl:max-w-[530px]">
          {dataHandlingSer.map((service, index) => (
            <div key={index} className="max-w-[250px] ">
              <div>
                {React.cloneElement(service.icon, {
                  style: {
                    width: isMobileView ? '36' : '40',
                    height: isMobileView ? '36' : '40',
                  },
                })}
              </div>
              <div className="mt-6 flex gap-[6px] items-center">
                <div className="w-[3px] h-5 bg-PrimaryT rounded"></div>
                <h3 className="paragraphText font-semibold text-PrimaryT tracking-[-1px] ">
                  {service.title}
                </h3>
              </div>
              <p className="mt-4 Text20Normal text-TertiaryT">{service.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  const services = [
    {
      title: t('Transparency'),
      text: t('TransparencyT'),
      icon: <TransparencyIcon />,
    },
    {
      title: t('Control'),
      text: t('ControlT'),
      icon: <ControlIcon />,
    },
    {
      title: t('AccessAwareness'),
      text: t('AccessAwarenessT'),
      icon: <AccessIcon />,
    },
    {
      title: t('ContingencyPlanning'),
      text: t('ContingencyPlanningT'),
      icon: <ContingencyIcon />,
    },
  ];

  return services;
};

export default DataHandling;
