import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BulletPoints from '../BulletPoints';

const Annex2 = ({ title, id }) => {
  const { t } = useTranslation();

  const [annex2Data, setAnnex2Data] = useState([]);
  useEffect(() => {
    setAnnex2Data(getTranslatedList(t));
  }, [t]);
  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">{title}</p>
      <p className="smallText16Normal mt-4">{t('Annex2T')}</p>
      <div className="flex flex-col gap-4 mt-4">
        {annex2Data.map((data, index) => (
          <React.Fragment key={index}>
            <p className="paragraphText text-[18px] font-semibold">{data.title}</p>
            {data.text && (
              <>
                {data.text.map((innerText, index1) => (
                  <React.Fragment key={index1}>
                    <div className="flex gap-1 pl-5">
                      <p className="smallText16Normal text-PrimaryT font-semibold">
                        {innerText.id}
                        {')'}.{' '}
                      </p>
                      <p className="smallText16Normal font-semibold text-PrimaryT">
                        {innerText.subTitle}
                      </p>
                    </div>
                    {innerText.subText.map((innerSubText, index2) => (
                      <BulletPoints
                        data={innerSubText}
                        pl="40px"
                        key={index2}
                        bgColor="#353535"
                      />
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
            {data.innerText && (
              <>
                {data.innerText.map((points, index3) => (
                  <BulletPoints
                    data={points}
                    pl="20px"
                    key={index3}
                    bgColor="#353535"
                  />
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  const lists = [
    {
      title: t('AccessControl_A'),
      text: [
        {
          id: 'i',
          subTitle: t('A_subT_1'),
          subText: [
            t('A_subT_1_1'),
            t('A_subT_1_2'),
            t('A_subT_1_3'),
            t('A_subT_1_4'),
            t('A_subT_1_5'),
          ],
        },
        {
          id: 'ii',
          subTitle: t('A_subT_2'),
          subText: [
            t('A_subT_2_1'),
            t('A_subT_2_2'),
            t('A_subT_2_3'),
            t('A_subT_2_4'),
            t('A_subT_2_5'),
          ],
        },
        {
          id: 'iii',
          subTitle: t('A_subT_3'),
          subText: [t('A_subT_3_1'), t('A_subT_3_2')],
        },
      ],
    },
    {
      title: t('AccessControl_B'),
      innerText: [t('B_Text_1'), t('B_Text_2')],
    },
    {
      title: t('AccessControl_C'),
      innerText: [t('C_Text_1'), t('C_Text_2')],
    },
    {
      title: t('AccessControl_D'),
      innerText: [t('D_Text_1'), t('D_Text_2'), t('D_Text_3')],
    },
  ];

  return lists;
};

export default Annex2;
