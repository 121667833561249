import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import { ForCustomer, ForDevelopers, ForEveryone, InfoIcon } from '../../assets/Icons/legalIcons';

const LegalGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [legalGroups, setLegalGroups] = useState([]);
  const [handleIndex, setHandleIndex] = useState({
    mainIndex: null,
    subIndex: null,
  });
  useEffect(() => {
    setLegalGroups(getTranslatedList(t));
  }, [t]);

  const handleNavigate = (link) => {
    navigate(link);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  };
  return (
    <CommonSizeContainer isBottomMargin>
      <div className="mt-[50px] max_md:mt-5 max_sm:mt-[10px] w-full grid grid-cols-3 gap-[30px] max_xl:gap-5 max_md:grid-cols-2 max_md:gap-[30px] max_720:grid-cols-1">
        {legalGroups.map((groups, index) => (
          <div
            className="rounded-[20px] p-[30px] max_xl:p-[20px] max_md:p-[30px] bg-white flex flex-col gap-6 w-[360px] max_xl:w-[320px] max_md:w-full max_sm:p-4"
            key={index}
          >
            <div>{groups.icon}</div>
            <p className="Text24 text-PrimaryT font-semibold max_md:text-text2Xl max_sm:text-textMedium">
              {groups.title}
            </p>
            <p className="Text20Normal max_xl:min-h-[180px] leading-[28px] max_sm:leading-6 max_md:min-h-max">
              {groups.text}
            </p>
            <div className="w-full h-[1px] bg-StrokePrimary"></div>
            {groups.list.map((list, index1) => (
              <div className="flex gap-1" key={index1}>
                <div
                  className="cursor-pointer Text20Normal text-ButtonP tracking-[-1px]"
                  onClick={() => handleNavigate(list.link)}
                >
                  {list.title} <span></span>
                </div>
                <div
                  className="cursor-pointer mt-[6px] max_sm:mt-[3px] relative h-max"
                  onMouseEnter={() =>
                    setHandleIndex({ mainIndex: index, subIndex: index1 })
                  }
                  onMouseLeave={() =>
                    setHandleIndex({ mainIndex: null, subIndex: null })
                  }
                >
                  <InfoIcon
                    color={
                      handleIndex.mainIndex === index &&
                      handleIndex.subIndex === index1
                        ? '#33302D'
                        : '#484540'
                    }
                  />
                  {handleIndex.mainIndex === index &&
                    handleIndex.subIndex === index1 && (
                      <>
                        <div className="absolute right-[-20px] bottom-[30px] bg-BgSecondary w-[220px] p-[10px] text-textExtraSmall font-OutfitFont font-normal text-PrimaryT rounded-[10px]">
                          {list.text}
                        </div>
                        <div
                          className="absolute right-0 bottom-[21px] w-[20px] h-[10px] bg-BgSecondary"
                          style={{ clipPath: 'polygon(51% 100%, 0 0, 100% 0)' }}
                        ></div>
                      </>
                    )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </CommonSizeContainer>
  );
};
const getTranslatedList = (t) => {
  const services = [
    {
      id: 1,
      title: t('ForCustomer'),
      text: t('ForCustomerT'),
      icon: <ForCustomer />,
      list: [
        {
          id: 1,
          title: t('CustomerTerms'),
          text: t('CustomerTermsT'),
          link: '/legal/terms-of-service',
        },
        {
          id: 2,
          title: t('ProductTerms'),
          text: t('ProductTermsT'),
          link: '/legal/product-specific-terms',
        },
        {
          id: 3,
          title: t('DataProcessing'),
          text: t('DataProcessingT'),
          link: '/legal/dpa',
        },
        {
          id: 4,
          title: t('AcceptablePolicy'),
          text: t('AcceptablePolicyT'),
          link: '/legal/acceptable-use',
        },
      ],
    },
    {
      id: 2,
      title: t('ForDevelopers'),
      text: t('ForDevelopersT'),
      icon: <ForDevelopers />,
      list: [
        {
          id: 1,
          title: t('DeveloperTerms'),
          text: t('DeveloperTermsT'),
          link: '/legal/developer-terms',
        },
        {
          id: 2,
          title: t('DeveloperPolicy'),
          text: t('DeveloperPolicyT'),
          link: '/legal/developer-policy',
        },
        {
          id: 3,
          title: t('AcceptablePolicy'),
          text: t('AcceptablePolicyTN'),
          link: '/legal/acceptable-use',
        },
      ],
    },
    {
      id: 3,
      title: t('ForEveryone'),
      text: t('ForEveryoneT'),
      icon: <ForEveryone />,
      list: [
        {
          id: 1,
          title: t('WebsiteTerms'),
          text: t('WebsiteTermsT'),
          link: '/legal/website-terms-of-use',
        },
        {
          id: 2,
          title: t('WebsiteAccess'),
          text: t('WebsiteAccessT'),
          link: '/legal/website-accessibility',
        },
        {
          id: 3,
          title: t('PrivacyPolicy'),
          text: t('PrivacyPolicyT'),
          link: '/legal/privacy-policy',
        },
        {
          id: 4,
          title: t('CookiePolicy'),
          text: t('CookiePolicyT'),
          link: '/legal/cookie-policy',
        },
        {
          id: 5,
          title: t('EuropeanServices'),
          text: t('EuropeanServicesT'),
          link: '/legal/digital-services-act',
        },
      ],
    },
  ];

  return services;
};

export default LegalGroups;



// const [cookies, setCookie] = useCookies(['acceptCookies', 'acceptLegalForm']);

// const handleAccept = () => {
//   setCookie('acceptLegalForm', true, {
//     expires: moment().add(3, 'months').toDate(),
//   });
//   setIsAccept(true);
// };
