import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberWithHeadingComp from './NumberWithHeadingComp';

const UseOfServiceComp = ({ data, id, handleOnClick }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-[30px] flex flex-col" id={id}>
      <p className="text22">2. {t('UseOfServices')}</p>
      {data.map((uses, index3) => (
        <React.Fragment key={index3}>
          {index3 === 8 ? (
            <>
              {' '}
              <div className="mt-5 flex flex-col">
                <p className="smallText18L text-SecondaryT font-semibold leading-5">
                  {t('2.9Prohibited')}
                </p>
                <div className="mt-4 flex flex-col">
                  <p className="smallText16Normal">{t('YouWillNot')}</p>
                  <div className="mt-4 flex flex-col gap-4">
                    {uses.text.map((list, index4) => (
                      <div className="flex gap-2" key={index4}>
                        <p className="w-[6px] h-[6px] bg-SecondaryT rounded-full mt-2"></p>
                        <p
                          className="smallText16Normal w-[calc(100%-14px)]"
                          dangerouslySetInnerHTML={{
                            __html: list,
                          }}
                          onClick={handleOnClick}
                        ></p>
                      </div>
                    ))}
                    <p className="smallText16Normal">{t('Prohibited5')}</p>
                    <p className="smallText16Normal">{t('Prohibited6')}</p>
                    <p className="smallText16Normal">
                      {t('Prohibited7')}
                      <span className="underline text-ButtonP">
                        <a
                          href="https://support.dcdial.com/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {t('Pro7Span')}
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <NumberWithHeadingComp
                data={uses}
                handleOnClick={handleOnClick}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default UseOfServiceComp;
