import React, { useEffect, useRef, useState } from 'react';
import CommonSizeContainer from '../Common/CommonSizeContainer';
import useVisibility from '../../utils/Hooks/useVisibility';
import CountUp from 'react-countup';
import BannerAnimation from '../../animation/AboutUs/AvnovoAboutUs.json'
import Lottie from 'react-lottie';

const AboutAnimation = () => {
  const counterRef = useRef();
  const isInView = useVisibility(counterRef, 0.5);
  const [startAnim, setStartAnim] = useState(false);
  useEffect(() => {
    if (!startAnim && isInView) {
      setStartAnim(true);
    }
  }, [isInView]);
  const [infoList] = useState([
    {
      value: '95',
      title: '%',
      text: 'customer satisfaction rate.',
      width: true,
    },
    {
      value: '1.3',
      title: 'M+',
      text: 'in transactions recovered.',
      dollar :true
    },
    {
      value: '611',
      title: 'M+',
      text: 'in transactions processed successfully.',
      dollar:true
    },
    {
      value: '99.9',
      title: '%',
      text: 'system uptime guarantee.',
      width: true,
    },
  ]);
  const aboutBannerAnimation = {
    loop: false,
    autoplay: true,
    animationData: BannerAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <CommonSizeContainer>
      <div className="">
        <Lottie options={aboutBannerAnimation} />
      </div>
      <div
        className="mt-[100px] max_md:mt-[60px] max_sm:mt-10 flex justify-between max_md:flex-wrap max_md:gap-y-[100px] max_sm:gap-y-[30px]"
        ref={counterRef}
      >
        {infoList.map((info, index) => {
          return (
            <React.Fragment key={index + 1}>
              <div className="w-[230px] flex gap-4 max_md:w-[48%] max_sm:w-[100%]">
                <div className="w-[4px] bg-ButtonP h-full rounded"></div>
                <div className="w-[calc(100%-20px)]">
                  <p className="Text30 font-semibold text-PrimaryT">
                    {info?.dollar && '$'}
                    <CountUp
                      end={info.value}
                      startOnMount={false}
                      start={startAnim}
                      decimals={info.value % 1 !== 0 ? 1 : 0}
                    />
                    {info.title}
                  </p>
                  <p className={`Text20Normal mt-[6px] max_xl:mt-1 `}>
                    {info.text}
                  </p>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </CommonSizeContainer>
  );
};

export default AboutAnimation;
