import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  CallsIcon,
  ContactManagedIcon,
  EmailDeliveredIcon,
  ProcessPaymentIcon,
  SMSIcon,
} from '../../assets/Icons/homePageIcons';

const CompanyInfo = () => {
  const { t } = useTranslation();
  const [infoList, setInfoList] = useState([]);
  useEffect(() => {
    setInfoList(getTranslatedList(t));
  }, [t]);
  return (
    <div className='bg-white py-[50px]'>
      <div className="m-auto max-w-[1140px] max_xl:max-w-[980px] max_md:px-10 max_sm:px-5">
        <div className="flex items-center gap-[56px] max_xl:gap-[38px] max_md:flex-wrap max_md:gap-[100px] max_800:gap-[50px] max_720:gap-9 max_md:justify-center max_sm:gap-[30px]">
          {infoList?.map((listInfo, index) => {
            return (
              <React.Fragment key={index + 1}>
                <div className="max_sm:w-full max_sm:flex max_sm:flex-col max_sm:items-center">
                  <p className="text-subHeadingW font-OutfitFont font-semibold text-PrimaryT">
                    {listInfo.value}
                  </p>
                  <div className="flex items-center gap-[6px]">
                    {listInfo.icon}
                    <p className="text-textSmall text-TertiaryT font-OutfitFont font-medium">
                      {' '}
                      {listInfo.name}
                    </p>
                  </div>
                </div>
                {index !== infoList.length - 1 && (
                  <div
                    className={`h-[72px] w-[1px] max_sm:w-[70%] max_sm:h-[1px] bg-StrokeOutline max ${
                      index === 2 && 'max_md:hidden max_sm:block'
                    }`}
                  ></div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const list = [
    {
      name: t('CallsMade'),
      value: t('CallsMadeValue'),
      icon: <CallsIcon />,
    },
    {
      name: t('SMSSent'),
      value: t('SMSSentValue'),
      icon: <SMSIcon />,
    },
    {
      name: t('EmailsDelivered'),
      value: t('EmailsDeliveredValue'),
      icon: <EmailDeliveredIcon />,
    },
    {
      name: t('ProcessedPayments'),
      value: t('ProcessedPaymentsValue'),
      icon: <ProcessPaymentIcon />,
    },
    {
      name: t('ContactsManaged'),
      value: t('ContactsManagedValue'),
      icon: <ContactManagedIcon />,
    },
  ];

  return list;
};
export default CompanyInfo;
